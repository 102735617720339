import request2 from '@/utils/request2'

export function queryreason(query) {
  return request2({
    url: '/award-event/page',
    method: 'post',
    data: query
  })
}

export function queryAllReason(query) {
  return request2({
    url: '/award-event/option-list',
    method: 'post',
    data: query
  })
}

export function getreason(data) {
  return request2({
    url: '/award-event/detail',
    method: 'post',
    data
  })
}

export function changereasonstat(data) {
  return request2({
    url: '/award-event/update-status',
    method: 'post',
    data
  })
}

export function addreason(data) {
  return request2({
    url: '/award-event/save',
    method: 'post',
    data
  })
}

export function updatereason(data) {
  return request2({
    url: '/award-event/update',
    method: 'post',
    data
  })
}

export function deletereason(data) {
  return request2({
    url: '/award-event/delete',
    method: 'post',
    data
  })
}
