<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="query.awardEventName"
        placeholder="事件名称"
        style="width: 200px; margin-right: 10px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <el-select
        v-model="query.awardEventStatus"
        placeholder="事件状态"
        clearable
        style="width: 120px; margin-right: 10px"
        class="filter-item"
      >
        <el-option label="启用" value="1" />
        <el-option label="禁用" value="0" />
      </el-select>
      <el-button
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        style="margin-left: 20px"
      >
        查询
      </el-button>
      <el-button
        v-waves
        class="filter-item"
        icon="el-icon-refresh-right"
        @click="resetFilter"
      >
        重置
      </el-button>
    </div>
    <AuthCheck permission="gtmsh-point-server.award.event.save">
      <router-link to="/integral/reasonadd">
        <el-button type="primary" style="margin-bottom: 20px"
          >创建事件</el-button
        >
      </router-link>
    </AuthCheck>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="事件图标" align="center" min-width="80px">
        <template slot-scope="{ row }">
          <img
            :src="getUrl(row.eventImageUrl)"
            style="width: 60px; height: 60px"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="事件名称"
        align="center"
        prop="eventName"
        min-width="80px"
      />
      <el-table-column
        label="事件描述"
        align="center"
        prop="eventDesc"
        min-width="150px"
      />
      <el-table-column label="赞币范围" align="center" min-width="150px">
        <template slot-scope="{ row }">
          {{ row.eventMinScore }}~{{ row.eventMaxScore }}
        </template>
      </el-table-column>
      <el-table-column
        label="更新人"
        align="center"
        prop="updatedBy"
        width="80px"
      />
      <el-table-column label="更新时间" align="center" width="140px">
        <template slot-scope="{ row }">
          <span>{{ row.updatedAt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" class-name="status-col" width="80px">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.eventStatus"
            :active-value="1"
            :inactive-value="0"
            :disabled="getUpdateAuth()"
            @change="handleChangeStat($event, scope.$index, scope.row.id)"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="200px"
      >
        <template slot-scope="{ row, $index }">
          <router-link
            :to="{ path: '/integral/reasonedit', query: { id: row.id } }"
          >
            <AuthCheck permission="gtmsh-point-server.award.event.update">
              <el-button type="primary" size="mini"> 编辑 </el-button>
            </AuthCheck>
          </router-link>
          <AuthCheck permission="gtmsh-point-server.award.event.delete">
            <el-popconfirm
              v-if="false"
              style="margin-left: 10px"
              title="删除后事件将无法恢复，请谨慎操作，确定要删除吗？"
              @onConfirm="handleDelete(row, $index)"
            >
              <el-button slot="reference" size="mini" type="danger">
                删除
              </el-button>
            </el-popconfirm>
          </AuthCheck>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.pageNum"
      :limit.sync="query.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { getUrl } from "@/utils";
import { queryreason, changereasonstat, deletereason } from "@/api/reason";
import waves from "@/directive/waves"; // waves directive
import Pagination from "@/components/Pagination";
import AuthCheck from "@/components/AuthCheck";
import { checkAuth } from "@/utils/auth";

export default {
  name: "ReasonList",
  components: { Pagination, AuthCheck },
  filters: {},
  directives: { waves },
  data() {
    return {
      list: null,
      listLoading: true,
      query: {
        awardEventName: "",
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    getUrl, // 确保 getUrl 方法可以在组件中使用
    getList() {
      this.listLoading = true;
      queryreason(this.query)
        .then((response) => {
          this.list = response.data.list;
          this.total = response.data.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleFilter() {
      this.query.pageNum = 1;
      this.getList();
    },
    resetFilter() {
      this.query.awardEventName = null;
      this.query.awardEventStatus = null;
      this.getList();
    },
    getUpdateAuth() {
      return !checkAuth("gtmsh-point-server.award.event.updateStatus");
    },
    handleChangeStat(data, index, awardEventId) {
      changereasonstat({ awardEventId })
        .then((response) => {
          this.$message({
            message: "状态修改成功",
            type: "success",
          });
        })
        .catch((e) => {
          this.list[index].awardEventStatus = data == 1 ? 0 : 1;
        });
    },
    handleDelete(reason, index) {
      deletereason({ awardEventId: reason.id }).then((response) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.list.splice(index, 1);
      });
    },
  },
};
</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
