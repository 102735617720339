<template>
  <div :class="classObj" class="app-wrapper">
    <!--
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    -->
    <sidebar class="sidebar-container" />
    <div :class="{ hasTagsView: needTagsView }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <!--
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
      -->
      <div class="div-bottom">
        <div class="div-contact-us">
          <a class="span-bottom-tip" href="https://www.gtmsh.com/">官网首页</a>
          <a
            class="span-bottom-tip"
            href="https://www.gtmsh.com/index.php?c=article&a=type&tid=64"
            >关于我们</a
          >
        </div>
        <div class="span-bottom-tip2">
          深圳棠人科技有限公司 版权所有CopyRight © 2023 ALL RIGHTS RESERVED
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, /*Settings,*/ Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    //RightPanel,
    //Settings,
    Sidebar,
    TagsView,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
.div-bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    .span-bottom-tip {
      color: #666a77;
      font-size: 12px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .span-bottom-tip2 {
      color: #c0c3cc;
      font-size: 12px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .div-contact-us {
      display: flex;
      flex-direction: row;
      a:hover {
        color: #3a72ff;
      }
    }
  }
</style>
