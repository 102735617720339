var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "100px" },
            attrs: { placeholder: "被点赞人", clearable: "" },
            model: {
              value: _vm.query.toEmployeeName,
              callback: function ($$v) {
                _vm.$set(_vm.query, "toEmployeeName", $$v)
              },
              expression: "query.toEmployeeName",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: { placeholder: "事件", clearable: "" },
              model: {
                value: _vm.query.eventId,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "eventId", $$v)
                },
                expression: "query.eventId",
              },
            },
            _vm._l(_vm.reasons, function (reason) {
              return _c("el-option", {
                key: reason.id,
                staticClass: "filter-item",
                attrs: { label: reason.eventName, value: reason.id },
              })
            }),
            1
          ),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { type: "date", placeholder: "点赞开始日期" },
            model: {
              value: _vm.query.startTime,
              callback: function ($$v) {
                _vm.$set(_vm.query, "startTime", $$v)
              },
              expression: "query.startTime",
            },
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "140px" },
            attrs: { type: "date", placeholder: "点赞结束日期" },
            model: {
              value: _vm.query.endTime,
              callback: function ($$v) {
                _vm.$set(_vm.query, "endTime", $$v)
              },
              expression: "query.endTime",
            },
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v(" 查找 ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { icon: "el-icon-refresh-right" },
              on: { click: _vm.resetFilter },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "AuthCheck",
            { attrs: { permission: "gtmsh-point-server.award.detail.export" } },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "0px" },
                  attrs: { loading: _vm.exportRun },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v(" 导出 ")]
              ),
            ],
            1
          ),
          _c(
            "AuthCheck",
            { attrs: { permission: "gtmsh-point-server.revoke.batch" } },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  on: { click: _vm.showBatchRevokeDlg },
                },
                [_vm._v(" 批量撤销 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "被点赞人", align: "center", "min-width": "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.toEmployeeName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "账号", align: "center", "min-width": "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.toEmployeeAccount) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "部门", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.toDeptName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "职务", align: "center", "min-width": "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.toPositionDictName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "事件名称", align: "center", "min-width": "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.eventName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "获得赞币", align: "center", "min-width": "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.awardNum) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "点赞事由", align: "center", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.awardContent && row.awardContent.length > 26
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item-ellipsis",
                            attrs: {
                              "popper-class": "item-ellipsis",
                              effect: "dark",
                              content: row.awardContent,
                              placement: "top",
                            },
                          },
                          [
                            _c("div", { staticClass: "span-ellipsis" }, [
                              _vm._v(_vm._s(row.awardContent)),
                            ]),
                          ]
                        )
                      : _c("div", { staticClass: "span-ellipsis" }, [
                          _vm._v(_vm._s(row.awardContent)),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "附件", align: "center", "min-width": "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.awardFileUrlList, function (media) {
                    return _c(
                      "div",
                      {
                        key: "i_" + media.id,
                        staticStyle: {
                          width: "64px",
                          height: "64px",
                          "margin-right": "10px",
                          display: "inline-block",
                        },
                      },
                      [
                        media.attachmentType == 1
                          ? _c("el-image", {
                              staticStyle: { width: "64px", height: "64px" },
                              attrs: {
                                src: _vm.getUrl(media.attachmentId),
                                "preview-src-list": [
                                  _vm.getUrl(media.attachmentId),
                                ],
                                fit: "cover",
                              },
                            })
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  display: "inline-block",
                                  width: "64px",
                                  height: "64px",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.openVideoDlg(
                                      _vm.getUrl(media.attachmentId)
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticStyle: {
                                    width: "64px",
                                    height: "64px",
                                    "background-color": "#999",
                                  },
                                  attrs: { fit: "cover" },
                                }),
                                _c("i", {
                                  staticClass:
                                    "el-icon-caret-right play_btn_inline2",
                                }),
                              ]
                            ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "点赞人", align: "center", "min-width": "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.fromEmployeeName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "时间", align: "center", width: "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            row.createdAt,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.query.pageNum,
          limit: _vm.query.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm.infoDlg.award
        ? _c(
            "el-dialog",
            {
              staticClass: "dlg_items",
              attrs: {
                title: "点赞信息详情",
                visible: _vm.infoDlg.visible,
                width: "60%",
                top: "calc( ( 30vh - 84px ) / 2 )",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.infoDlg, "visible", $event)
                },
              },
            },
            [
              _c("div", { staticClass: "dlg_content" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "top_item" }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("姓名：")]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.userName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("部门：")]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.deptName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v("事件名称："),
                      ]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.reasonName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("点赞人：")]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.userName2)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "top_item" }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("账号：")]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.userLoginNo)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [_vm._v("岗位：")]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.title)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v("获得赞币："),
                      ]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(_vm._s(_vm.infoDlg.award.integral)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v("操作时间："),
                      ]),
                      _c("div", { staticClass: "val" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("parseTime")(
                                _vm.infoDlg.award.createdAt,
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "middle" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("点赞事由：")]),
                  _c("div", { staticClass: "val" }, [
                    _vm._v(_vm._s(_vm.infoDlg.award.content)),
                  ]),
                ]),
                _c("div", { staticClass: "bottom" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("附件：")]),
                  _c(
                    "div",
                    { staticClass: "val" },
                    [
                      _vm.infoDlg.contentmedialist.length > 0
                        ? _vm._l(
                            _vm.infoDlg.contentmedialist,
                            function (media) {
                              return _c(
                                "div",
                                {
                                  key: "i_" + media.id,
                                  staticStyle: {
                                    width: "128px",
                                    height: "128px",
                                    "margin-right": "10px",
                                    display: "inline-block",
                                  },
                                },
                                [
                                  media.mediatype == 1
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "128px",
                                          height: "128px",
                                        },
                                        attrs: {
                                          src: _vm.getUrl(media.attachmentId),
                                          "preview-src-list": [
                                            _vm.getUrl(media.attachmentId),
                                          ],
                                          fit: "cover",
                                        },
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            position: "relative",
                                            display: "inline-block",
                                            width: "128px",
                                            height: "128px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.openVideoDlg(
                                                _vm.getUrl(media.attachmentId)
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "128px",
                                              height: "128px",
                                            },
                                            attrs: {
                                              src: media.thumburl,
                                              fit: "cover",
                                            },
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-caret-right play_btn_inline",
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            }
                          )
                        : [_vm._v(" 无 ")],
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.videoDlg.visible,
            width: "60%",
            top: "20vh",
            "custom-class": "dlg_video",
            "destroy-on-close": true,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.videoDlg, "visible", $event)
            },
            close: function ($event) {
              return _vm.onVideoDlgClose()
            },
          },
        },
        [
          _c(
            "video",
            {
              staticStyle: {
                width: "100%",
                height: "60vh",
                "background-color": "#000000",
              },
              attrs: {
                src: _vm.videoDlg.url,
                controls: "controls",
                autoplay: "autoplay",
              },
            },
            [_vm._v(" 您的浏览器不支持 video 标签。 ")]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.revokeLoading,
              expression: "revokeLoading",
            },
          ],
          staticClass: "dlg_items",
          attrs: {
            "element-loading-text": "正在撤销中",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.3)",
            title: "请输入撤销理由",
            visible: _vm.batchRevokeDlg.visible,
            width: "40%",
            "destroy-on-close": true,
            "show-close": true,
            top: "calc( ( 30vh - 84px ) / 2 )",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.batchRevokeDlg, "visible", $event)
            },
            close: function ($event) {
              return _vm.onBatchRevokeDlgClose()
            },
          },
        },
        [
          _c("el-input", {
            staticClass: "filter-item",
            attrs: {
              placeholder: "输入15个字内的撤销理由",
              clearable: "",
              maxlength: "15",
            },
            model: {
              value: _vm.batchRevokeDlg.reason,
              callback: function ($$v) {
                _vm.$set(_vm.batchRevokeDlg, "reason", $$v)
              },
              expression: "batchRevokeDlg.reason",
            },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  on: { click: _vm.onBatchRevokeDlgClose },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  attrs: { type: "primary" },
                  on: { click: _vm.batchRevoke },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }