var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-wrap",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: _vm.width,
        "append-to-body": true,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("el-divider", { staticClass: "divider" }),
      _c("div", { staticClass: "dialog-body" }, [_vm._t("body")], 2),
      _c("el-divider", { staticClass: "divider" }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_vm._t("footer")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }