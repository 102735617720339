import request2 from '@/utils/request2'

export function allquota() {
  return request2({
    url: '/quota/all',
    method: 'post'
  })
}

export function quotaRepealList(params) {
  return request2({
    url: '/point/revoke/page',
    method: 'post',
    data:params
  })
}

export function exportRepealList(params) {
  return request2({
    url: '/point/revoke/export',
    method: 'post',
    data:params
  })
}

export function quotaRepealDetail(params) {
  return request2({
    url: '/point/award/detail',
    method: 'post',
    data:params
  })
}

export function getquota(quotaid) {
  return request2({
    url: '/quota/get',
    method: 'post',
    data: {quotaid}
  })
}

export function allquotalist() {
  return request2({
    url: '/quota/alllist',
    method: 'post'
  })
}

export function changequotastat(quotaid,stat) {
  return request2({
    url: '/quota/changestat',
    method: 'post',
    data: {
      quotaid,
      stat
    }
  })
}

export function checkquotapow(pow,quotaid=0) {
  return request2({
    url: '/quota/checkpow',
    method: 'post',
    data: {
      quotaid,
      pow
    }
  })
}

export function addquota(data) {
  return request2({
    url: '/quota/add',
    method: 'post',
    data
  })
}

export function updatequota(data) {
  return request2({
    url: '/quota/update',
    method: 'post',
    data
  })
}

export function deletequota(quotaid) {
  return request2({
    url: '/quota/delete',
    method: 'post',
    data:{quotaid}
  })
}


export function queryintegralbydept(deptid,month,page,limit) {
  return request2({
    url: '/quota/queryintegralbydept',
    method: 'post',
    data:{deptid,month,page,limit}
  })
}

export function queryintegral(keyword,month,page,limit) {
  return request2({
    url: '/quota/queryintegral',
    method: 'post',
    data:{keyword,month,page,limit}
  })
}

export function queryusermonthaward(userid,month){
  return request2({
    url: '/quota/queryusermonthaward',
    method: 'post',
    data:{userid,month}
  })
}
