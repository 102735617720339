import request2 from '@/utils/request2'

export function queryaward(query) {
  return request2({
    url: '/point/award/page',
    method: 'post',
    data: query
  })
}

export function queryDetail(query) {
  return request2({
    url: '/award/detail',
    method: 'post',
    data: query
  })
}

export function requestRevoke(query){
  return request2({
    url: '/point/revoke/batch',
    method: 'post',
    data: query
  })
}

export function exportAward(query){
  return request2({
    url: '/award/exportAll',
    method: 'post',
    data: query
  })
}