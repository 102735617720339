import { render, staticRenderFns } from "./ReasonDetail.vue?vue&type=template&id=295fb27d&scoped=true"
import script from "./ReasonDetail.vue?vue&type=script&lang=js"
export * from "./ReasonDetail.vue?vue&type=script&lang=js"
import style0 from "./ReasonDetail.vue?vue&type=style&index=0&id=295fb27d&lang=scss&scoped=true"
import style1 from "./ReasonDetail.vue?vue&type=style&index=1&id=295fb27d&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295fb27d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/gtmsh/server/jenkins/home/workspace/预发布环境/frontend/saas-point-bk/saas-point-bk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('295fb27d')) {
      api.createRecord('295fb27d', component.options)
    } else {
      api.reload('295fb27d', component.options)
    }
    module.hot.accept("./ReasonDetail.vue?vue&type=template&id=295fb27d&scoped=true", function () {
      api.rerender('295fb27d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/reason/components/ReasonDetail.vue"
export default component.exports