<template>
  <el-container style="height: calc(100vh - 84px)">
    <el-aside
      :style="{
        width: treeWidth,
        'border-right': '1px solid #eee',
        height: '100%',
        padding: 0,
        'background-color': '#ffffff',
      }"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
      >
        <div>
          <el-input
            style="width: 330px"
            slot="reference"
            placeholder="输入部门"
            clearable
            v-model="branchName"
            @input="branchSearchChange"
            @keydown.enter.native="searchBranch"
          >
            <el-button
              slot="append"
              type="primary"
              class="inner-btn"
              icon="el-icon-search"
              style="background: #409eff; color: #fff; border-radius: 0"
              @click="searchBranch"
            ></el-button>
          </el-input>
        </div>
      </el-header>
      <el-main>
        <el-tree
          :data="deptData.tree"
          node-key="id"
          :props="{ children: 'children', label: 'deptName' }"
          :expand-on-click-node="false"
          :default-expanded-keys="['1']"
          highlight-current
          @node-click="loadByDept"
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </el-main>
    </el-aside>
    <el-aside
      :style="{
        width: `calc(100% - ${treeWidth})`,
        height: '100%',
        padding: '5px 10px',
        'background-color': '#ffffff',
      }"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
        class="header"
      >
        <div class="filter-item-div2">
          <span>员工姓名：</span>
          <el-popover
            placement="top"
            trigger="manual"
            content="请输入员工姓名"
            v-model="searchkeyTipShow"
          >
            <el-input
              style="width: 150px"
              slot="reference"
              placeholder="请输入员工姓名"
              clearable
              v-model="searchkey"
              @keydown.enter.native="query2"
            >
            </el-input>
          </el-popover>
        </div>

        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="query2"
        >
          查询
        </el-button>
        <el-button
          v-waves
          class="filter-item btn"
          style="margin-left: 0px"
          icon="el-icon-refresh-right"
          @click="handleReset"
        >
          重置
        </el-button>
        <AuthCheck permission="gtmsh-point-server.point.export">
          <el-button
            :loading="exportRun"
            v-waves
            class="filter-item btn"
            style="margin-left: 0px"
            @click="exportExcel"
          >
            导出
          </el-button>
        </AuthCheck>
      </el-header>
      <el-table
        :data="searchList"
        v-loading="loading"
        style="width: 100%"
        @cell-click="handleOperation"
      >
        <el-table-column prop="employeeName" label="员工姓名" width="100px">
        </el-table-column>
        <el-table-column prop="employeeAccount" label="账号" width="100px">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="150px">
        </el-table-column>
        <el-table-column prop="position" label="职务" width="100px">
        </el-table-column>
        <el-table-column prop="levelValue" label="等级" width="100px">
        </el-table-column>
        <el-table-column prop="divideNum" label="分配额度" width="100px">
        </el-table-column>
        <el-table-column
          prop="divideRemainNum"
          label="剩余额度"
          width="100px"
          class-name="click-text"
        >
        </el-table-column>
        <el-table-column prop="employeeTotalNum" label="累计赞币" width="100px">
        </el-table-column>
        <el-table-column
          prop="employeeExchangeNum"
          label="已兑换赞币"
          width="100px"
          class-name="click-text"
        >
        </el-table-column>
        <el-table-column
          prop="employeeRemainNum"
          label="可兑赞币"
          width="100px"
        >
        </el-table-column>
        <el-table-column prop="freezeNum" label="冻结赞币" width="100px">
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="query.pageNum"
        :limit.sync="query.pageSize"
        @pagination="getList"
      />
      <i
        style="
          position: absolute;
          top: 50%;
          cursor: pointer;
          margin-left: -24px;
          font-size: 25px;
        "
        :class="openTree ? 'el-icon-caret-left' : 'el-icon-caret-right'"
        @click="updateOpenTree"
      ></i>
    </el-aside>
    <el-dialog
      title="点赞详情"
      :visible.sync="residuaLvisible"
      width="56%"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
        class="header"
      >
        <div class="filter-item-div2">
          <span>被点赞人：</span>
          <el-popover
            placement="top"
            trigger="manual"
            content="请输入被点赞人"
            v-model="searchkeyTipShow"
          >
            <el-input
              style="width: 150px"
              slot="reference"
              placeholder="请输入被点赞人"
              clearable
              v-model="residualRuery.toEmployeeName"
              @keydown.enter="loadResidual"
            >
            </el-input>
          </el-popover>
        </div>
        <div class="filter-item-div2">
          <span>点赞类别：</span>
          <el-select v-model="residualRuery.eventId" placeholder="请选择">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in optionList"
              :key="item.value"
              :label="item.eventName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item-div2">
          <span>月份：</span>
          <el-date-picker
            type="month"
            placeholder="选择月"
            v-model="residualRuery.searchMonth"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="loadResidual"
        >
          查询
        </el-button>
        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          icon="el-icon-refresh-right"
          @click="resetFilter1"
        >
          重置
        </el-button>
      </el-header>
      <el-table
        :data="residualSearchList"
        v-loading="residualLoading"
        style="width: 100%"
        max-height="55vh"
        height="55vh"
      >
        <el-table-column
          label="被点赞人"
          prop="toEmployeeName"
        ></el-table-column>
        <el-table-column label="点赞类别" prop="eventName"></el-table-column>
        <el-table-column label="赞币" prop="awardNum"></el-table-column>
        <el-table-column label="操作时间" prop="createdAt"></el-table-column>
      </el-table>
      <pagination
        v-show="residualSTotal > 0"
        :total="residualSTotal"
        :page.sync="residualRuery.pageNum"
        :limit.sync="residualRuery.pageSize"
        @pagination="loadResidual"
      />
    </el-dialog>

    <el-dialog
      title="兑换详情"
      :visible.sync="exchangevisible"
      width="50%"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
        class="header"
      >
        <div class="filter-item-div2">
          <span>月份：</span>
          <el-date-picker
            type="month"
            placeholder="选择月"
            v-model="exchangeRuery.searchMonth"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="filter-item-div2">
          <span>兑换类型：</span>
          <el-select
            v-model="exchangeRuery.employeeChangeType"
            placeholder="请选择"
          >
            <el-option label="全部" value="" />
            <el-option
              v-for="item in exchangeOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          type="primary"
          @click="loadPageChange"
        >
          查询
        </el-button>
        <el-button
          v-waves
          class="filter-item btn"
          style="margin-left: 0px"
          icon="el-icon-refresh-right"
          @click="resetFilter2"
        >
          重置
        </el-button>
      </el-header>

      <el-table
        :data="exchangeSearchList"
        v-loading="changeLoading"
        style="width: 100%"
        max-height="55vh"
        height="55vh"
      >
        <el-table-column label="兑换类型">
          <template slot-scope="{ row }">
            <span>{{
              row.employeeChangeType == 4 ? "赞币换礼" : "现金提取"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="兑换赞币" prop="toChangeNum"></el-table-column>
        <el-table-column label="兑换时间" prop="operateTime"></el-table-column>
      </el-table>
      <pagination
        v-show="changeTotal > 0"
        :total="changeTotal"
        :page.sync="exchangeRuery.pageNum"
        :limit.sync="exchangeRuery.pageSize"
        @pagination="loadPageChange"
      />
    </el-dialog>
  </el-container>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import { queryintegralbydept, queryintegral } from "@/api/quota";
import {
  getPage,
  getOptionList,
  getPageAward,
  getPageChange,
} from "@/api/integral";
import { allDept } from "@/api/user";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import { checkAuth } from "@/utils/auth";
import AuthCheck from "@/components/AuthCheck";
import download from "@/utils/download";

export default {
  name: "QuotaQuery",
  components: { Pagination,AuthCheck },
  filters: {},
  directives: { waves },
  data() {
    return {
      searchList: [],
      query: {
        realName: "",
        deptId: 1,
        pageNum: 1,
        pageSize: 20,
      },
      optionList: [],
      residualSearchList: [],
      residualSTotal: 0,
      residuaLvisible: false,
      residualLoading: false,
      residualRuery: {
        fromEmployeeId: "",
        toEmployeeName: "",
        pageNum: 1,
        pageSize: 20,
        eventId: "",
        startTime: "",
        endTime: "",
      },
      branchName: "", //部门名字
      deptData: {
        tree: [],
        map: new Map(),
      },

      infoDlg: {
        visible: false,
        month: null,
        userid: null,
        loading: false,
        list: [],
      },
      exportRun: false,
      openTree: true,
      treeWidth: "380px",
      searchkey: "",
      searchkeyTipShow: false,
      loading: false,
      total: 0,

      changeTotal: 0,
      exchangeOptions: [
        { value: 4, name: "赞币换礼" },
        { value: 6, name: "现金提取" },
      ],
      exchangeRuery: {
        employeeId: "",
        pageNum: 1,
        pageSize: 20,
        startTime: "",
        endTime: "",
        employeeChangeTypeList: [4, 6],
        searchMonth: "",
      },
      changeLoading: false,
      exchangevisible: false,
      exchangeSearchList: [],
    };
  },
  created() {
    this.loadDeptData();
    this.loadOptionList();
  },
  computed: {},
  methods: {
    // 加载组织加购
    loadDeptData() {
      allDept({})
        .then((response) => {
          let deptArray = response.data;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.id, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (
              item.parentDeptId &&
              item.parentDeptId != "" &&
              item.parentDeptId != "0"
            ) {
              parent = deptMap.get(item.parentDeptId);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
          this.getList();
        })
        .catch((err) => {});
    },
    //请求表格
    getList() {
      this.loading = true;
      getPage(this.query)
        .then((response) => {
          this.searchList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //查询部门
    searchBranch() {
      const { branchName } = this._data;
      this.$refs.tree.filter(branchName);
    },
    // 组织架构搜索
    loadByDept(data) {
      this.query = {
        deptId: data.id || 1,
        realName: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },
    handleReset() {
      this.searchkey = "";
      this.query = {
        realName: "",
        pageNum: 1,
        pageSize: 20,
        deptId: this.query.deptId || 1,
      };
      this.getList();
    },
    // 搜索姓名
    query2() {
      this.query = {
        realName: this.searchkey || "",
        pageNum: 1,
        pageSize: 20,
        deptId: this.query.deptId || 1,
      };
      this.getList();
    },
    // 导出
    exportExcel() {
      download({
        method: "post",
        url: "/backend/point/record/point-employee/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "赞币明细-导出.xlsx",
        data: this.query,
      });
    },
    // 打开弹窗
    handleOperation(row, column, cell, event) {
      if (checkAuth("gtmsh-point-server.point.list")) {
        if (column.label == "剩余额度") {
          this.residualLoading = true;
          this.residualRuery.fromEmployeeId = row.employeeId;
          this.resetFilter1();
        } else if (column.label == "已兑换赞币") {
          this.changeLoading = true;
          this.exchangeRuery.employeeId = row.employeeId;
          this.resetFilter2();
        }
      }
    },
    //奖分类别
    loadOptionList() {
      return getOptionList({})
        .then((response) => {
          this.optionList = response.data;
        })
        .catch((err) => {});
    },
    resetFilter1() {
      this.residualRuery = {
        fromEmployeeId: this.residualRuery.fromEmployeeId,
        toEmployeeName: "",
        pageNum: 1,
        pageSize: 20,
        eventId: "",
        startTime: "",
        endTime: "",
      };
      this.loadResidual();
    },
    resetFilter2() {
      this.exchangeRuery = {
        employeeId: this.exchangeRuery.employeeId,
        pageNum: 1,
        pageSize: 20,
        startTime: "",
        endTime: "",
        employeeChangeTypeList: [4, 6],
        searchMonth: "",
        employeeChangeType: "",
      };
      this.loadPageChange();
    },
    // 剩余额度弹窗
    loadResidual() {
      this.residualSearchList = [];
      getPageAward(this.residualRuery)
        .then((response) => {
          this.residuaLvisible = true;
          this.residualSearchList = response.data.list;
          this.residualSTotal = response.data.total;
          this.residualLoading = false;
        })
        .catch((err) => {
          this.residualLoading = false;
        });
    },
    // 剩余兑换详情
    loadPageChange() {
      let { employeeChangeType } = this.exchangeRuery;
      if (employeeChangeType) {
        this.exchangeRuery.employeeChangeTypeList = [employeeChangeType];
      } else {
        this.exchangeRuery.employeeChangeTypeList = [4, 6];
      }
      this.exchangeSearchList = [];
      this.changeLoading = true;
      getPageChange(this.exchangeRuery)
        .then((response) => {
          this.exchangevisible = true;
          this.exchangeSearchList = response.data.list;
          this.changeTotal = response.data.total;
          this.changeLoading = false;
        })
        .catch((err) => {
          this.changeLoading = false;
        });
    },

    deptpath(id) {
      let path = "";
      while (true) {
        if (!id) break;
        if (id == "1" && path != "") break;
        let dept = this.deptData.map.get(id);
        if (!dept) break;
        if (path != "") path = "\\" + path;
        path = dept.deptName + path;
        id = dept.parentDeptId;
      }
      return path;
    },
    updateOpenTree() {
      if (this.openTree) {
        this.openTree = false;
        this.treeWidth = "10px";
      } else {
        this.openTree = true;
        this.treeWidth = "380px";
      }
    },
    branchSearchChange(value) {
      if (!value) {
        this.$refs.tree.filter(value);
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.deptName.indexOf(value) !== -1;
    },
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.dlg_items .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}

.dlg_items .el-dialog {
  min-width: 660px;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto !important;
}

.btn {
  height: 32px;
}

.filter-item-div {
  margin-right: 20px;
  min-width: 260px;
}

.filter-item-div2 {
  margin-right: 20px;
  min-width: 160px;
}

.filter-item {
  margin-right: 20px;
}

/**点击样式 */
.click-text {
  color: #1890ff;
}
.click-text :hover {
  cursor: pointer;
}
</style>
