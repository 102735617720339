import request2 from '@/utils/request2'

export function login(data) {
  return request2({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function ssologin(data) {
  return request2({
    url: '/user/ssologin',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request2({
    url: '/auth/query',
    method: 'post'
  })
}

export function logout() {
  return request2({
    url: '/auth/login-out',
    method: 'post'
  })
}

export function allDept(data) {
  return request2({
    url: '/dept/list/all',
    method: 'post',
    data
  })
}

export function queryUserByDept(deptid) {
  return request2({
    url: '/user/querybydept',
    method: 'post',
    data: { deptid }
  })
}

export function queryUser(keyword) {
  return request2({
    url: '/user/query',
    method: 'post',
    data: { keyword }
  })
}


export function pageQueryUserByDept(deptid, page, limit) {
  return request2({
    url: '/user/pagequerybydept',
    method: 'post',
    data: { deptid, page, limit }
  })
}

export function pageQueryUser(keyword, page, limit) {
  return request2({
    url: '/user/pagequery',
    method: 'post',
    data: { keyword, page, limit }
  })
}

export function queryPageUser(data) {
  return request2({
    url: '/user/querypage',
    method: 'post',
    data
  })
}

export function queryDept(keyword) {
  return request2({
    url: '/user/querydept',
    method: 'post',
    data: { keyword }
  })
}

export function pagescoreorder(query) {
  return request2({
    url: '/point-rank/page',
    method: 'post',
    data: query
  })
}

export function searchBranch(query) {
  return request2({
    url: '/user/allbasedept',
    method: 'post',
    data: query
  })
}


//角色管理 列表
export function roleList(query) {
  return request2({
    url: '/role/page',
    method: 'post',
    data: query
  })
}


export function deleteRole(query) {
  return request2({
    url: '/role/delete',
    method: 'post',
    data: query
  })
}


export function addRole(query) {
  return request2({
    url: '/role/save',
    method: 'post',
    data: query
  })
}


export function editRole(query) {
  return request2({
    url: '/role/update',
    method: 'post',
    data: query
  })
}



export function getRole(query) {
  return request2({
    url: '/role/get',
    method: 'post',
    data: query
  })
}



//获取所有权限点
export function getAuthTree(query) {
  return request2({
    url: '/auth/getAuthTree',
    method: 'post',
    data: query
  })
}


//员工列表
export function getUserList(query) {
  return request2({
    url: '/employee/pageAuth',
    method: 'post',
    data: query
  })
}

//查询单个部门的信息
export function getDeptInfo(query) {
  return request2({
    url: '/dept/get',
    method: 'post',
    data: query
  })
}

//新增员工
export function addEmployee(query) {
  return request2({
    url: '/employee/save',
    method: 'post',
    data: query
  })
}

//编辑员工
export function editEmployee(query) {
  return request2({
    url: '/employee/update',
    method: 'post',
    data: query
  })
}


//获取某个员工信息
export function getEmployee(query) {
  return request2({
    url: '/employee/get/byIdAuth',
    method: 'post',
    data: query
  })
}
//离职某个员工
export function disabledEmployee(query) {
  return request2({
    url: '/employee/disable',
    method: 'post',
    data: query
  })
}


//获取部门列表
export function getDeptList(query) {
  return request2({
    url: '/dept/pageAuth',
    method: 'post',
    data: query
  })
}

//获取全部职位不分页
export function getPosition(query) {
  return request2({
    url: '/employee-position/list',
    method: 'post',
    data: query
  })
}



//新增员工
export function addDept(query) {
  return request2({
    url: '/dept/save',
    method: 'post',
    data: query
  })
}

//编辑员工
export function editDept(query) {
  return request2({
    url: '/dept/update',
    method: 'post',
    data: query
  })
}

//编辑员工
export function deleteDept(query) {
  return request2({
    url: '/dept/deleted',
    method: 'post',
    data: query
  })
}

//获取职位列表
export function getPositionPage(query) {
  return request2({
    url: '/employee-position/page',
    method: 'post',
    data: query
  })
}

//新增职位
export function addPosition(query) {
  return request2({
    url: '/employee-position/save',
    method: 'post',
    data: query
  })
}

//职位详情
export function getPositionDetails(query) {
  return request2({
    url: '/employee-position/detail',
    method: 'post',
    data: query
  })
}




//修改职位
export function editPosition(query) {
  return request2({
    url: '/employee-position/update',
    method: 'post',
    data: query
  })
}

//删除职位
export function deletePosition(query) {
  return request2({
    url: '/employee-position/delete',
    method: 'post',
    data: query
  })
}

//修改职位状态
export function updatePositionStatus(query) {
  return request2({
    url: '/employee-position/update-status',
    method: 'post',
    data: query
  })
}

//通过部门名称查询所有部门
export function getDeptByKw(query) {
  return request2({
    url: '/dept/list',
    method: 'post',
    data: query
  })
}

export function queryUserByKw(keyword, deptId) {
  return request2({
    url: '/employee/page',
    method: 'post',
    data: { realName: keyword, page: 1, pageSize: 10000, deptId }
  })
}

//角色管理 列表
export function roleAllList(query) {
  return request2({
    url: '/role/list',
    method: 'post',
    data: query
  })
}




