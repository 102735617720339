<template>
  <div class="login-container">
    <div class="div-tip">
      <!-- <img class="iv-tip" src="@/assets/icon/icon_defalut_logo.png" /> -->
      <span class="span-tip">SNS圣恩·通行证</span>
    </div>
    <div class="content-main">
      <div class="div-left">
        <img class="iv-left-bg" src="@/assets/icon/icon_login_logo.png" />
        <div class="div-left-tip">
          <span class="span-left-tip span-left-big">SNS圣恩</span>
          <span class="span-left-tip">提升企业内部互动</span>
          <span class="span-left-tip">激发内部正能量</span>
          <span class="span-left-tip">共创企业文化的精彩篇章</span>
        </div>
      </div>
      <div class="div-content-main" v-loading="loading">
        <div class="div-content">
          <span class="span-title">欢迎登录</span>
          <el-input
            class="input-content"
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入您的账号"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
          />
          <el-input
            class="input-content"
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入您的密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter="handleLogin"
          />
          <div class="btn-login" @click="handleLogin">
            <div class="span-login">登录</div>
            <i class="el-icon-right"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="div-bottom">
      <div class="div-contact-us">
        <a class="span-bottom-tip" href="https://www.gtmsh.com/">官网首页</a>
        <a
          class="span-bottom-tip"
          href="https://www.gtmsh.com/index.php?c=article&a=type&tid=64"
          >关于我们</a
        >
      </div>
      <div class="span-bottom-tip2">
        深圳棠人科技有限公司 版权所有CopyRight © 2023 ALL RIGHTS RESERVED
      </div>
    </div>
  </div>
</template>

<script>
import { validUsername } from "@/utils/validate";
import SocialSign from "./components/SocialSignin";

export default {
  name: "Login",
  components: { SocialSign },
  data() {
    const validateUsername = (rule, value, callback) => {
      callback();
      return;
      if (!validUsername(value)) {
        callback(new Error("请输入正确的用户名"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于6个字符"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    if (this.loginForm.username === "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      const { username, password } = this.loginForm;
      if (!username) {
        this.$message({
          type: "error",
          message: "请输入您的账号",
        });
        return;
      }
      if (!password) {
        this.$message({
          type: "error",
          message: "请输入您的密码",
        });
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          this.$router.push({
            path: this.redirect || "/",
            query: this.otherQuery,
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #ffffff;
$light_gray: #333333;
$cursor: #333333;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #323232;
      height: 45px;
      caret-color: #323232;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .div-bottom {
    // position: fixed;
    // bottom: 0;
    display: flex;
    align-items: center;
    margin-top: 100px;
    flex-direction: column;
    .span-bottom-tip {
      color: #666a77;
      font-size: 12px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .span-bottom-tip2 {
      color: #c0c3cc;
      font-size: 12px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .div-contact-us {
      display: flex;
      flex-direction: row;
      a:hover {
        color: #3a72ff;
      }
    }
  }

  .div-tip {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
    margin-top: 20px;
    height: 108px;
    .iv-tip {
      width: 108px;
      height: 108px;
    }
    .span-tip {
      font-size: 28px;
      font-weight: 500;
      color: #b99b5b;
      margin-left: 10px;
    }
  }
  .content-main {
    background: #fff;
    box-shadow: 0 18px 30px 0 rgba(192, 195, 204, 0.4);
    display: flex;
    height: 600px;
    max-width: 95%;
    width: 1000px;
    .div-left {
      width: 360px;
      height: 600px;
      display: flex;
      flex-direction: column;
      .iv-left-bg {
        width: 360px;
        height: 600px;
      }
      .div-left-tip {
        position: absolute;
        z-index: 100;
        top: 100px;
        display: flex;
        flex-direction: column;
        top: 200px;
        align-items: center;
        width: 360px;
        justify-content: center;
        .span-left-tip {
          color: #b99b5b;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .span-left-big {
          font-size: 28px;
          margin-bottom: 20px;
          font-weight: 600;
        }
      }
    }

    .div-content-main {
      height: 600px;
      width: 640px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .div-content {
        width: 372px;
        display: flex;
        flex-direction: column;

        .span-title {
          color: #252a3d;
          font-size: 28px;
          margin-bottom: 30px;
          line-height: 40px;
        }
        .input-content {
          width: 372px;
          border: 1px solid #dfe1e5;
          margin-bottom: 30px;
          color: #323232;
          background: white !important;
        }
        .btn-login {
          width: 372px;
          background: #b99b5b;
          color: #fff;
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 14px;
          justify-content: center;
          padding-left: 20px;
          padding-right: 20px;
          .span-login {
            flex: 1;
          }
        }
        .btn-login:hover {
          cursor: pointer;
          background: #beaa7f;
        }
        // .btn-login :hover {
        // }
      }
    }
  }

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
