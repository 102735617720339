<template>
  <table-base-page
    ref="basePage"
    :columns="columns"
    :searchFileds="searchFileds"
    :setTableBtn="setTableBtn"
    :loadTableData="loadTableData"
    :exportTableData="exportTableData"
    :buttons="buttons"
  ></table-base-page>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import TableBasePage from "@/components/TableBasePage";
import { Button } from "element-ui";
import { getQuotaList } from "@/api/quotaallot";
import moment from "moment";
import AuthCheck from "@/components/AuthCheck";
import { checkAuth } from "@/utils/auth";
import download from "@/utils/download";

export default {
  name: "QuotaAllotList",
  filters: {},
  directives: { waves },
  components: {
    TableBasePage,
    AuthCheck,
  },
  data() {
    return {
      list: null,
      listLoading: true,
      buttons:["search", "reset"],
      columns: [
        {
          label: "接收人",
          prop: "toEmployeeName",
          align: "center",
        },
        {
          label: "账号",
          prop: "toEmployeeAccount",
          align: "center",
        },
        {
          label: "部门",
          prop: "toDeptName",
          align: "center",
        },
        {
          label: "职务",
          prop: "toPositionDictName",
          align: "center",
        },
        {
          label: "分配额度",
          prop: "toChangeNum",
          align: "center",
        },
        {
          label: "分配人",
          prop: "createdBy",
          align: "center",
        },
        {
          label: "所属中心",
          prop: "toCenterName",
          align: "center",
        },
        {
          label: "分配时间",
          prop: "operateTime",
          align: "center",
          // render: (createdAt, scope, h) => {
          //   return moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
          // }
        },
      ],
      searchFileds: [
        {
          prop: "fromEmployeeName",
          placeholder: "分配人姓名",
          type: "input",
        },
        {
          prop: "toEmployeeName",
          placeholder: "接收人姓名",
          type: "input",
        },
        {
          prop: ["startTime", "endTime"],
          initialValue: [
            moment(moment().add(-1, "months").format("YYYY-MM-DD")),
            moment(moment().format("YYYY-MM-DD"))
              .add(23, "hours")
              .add(59, "minutes")
              .add(59, "seconds"),
          ],
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间",
          rangeSeparator: "至",
          type: "daterange",
        },
      ],
    };
  },
  computed: {
    quotatypename() {
      return (quotatype) => {
        if (quotatype == "1") return "全员";
        if (quotatype == "2") return "特定用户";
        if (quotatype == "3") return "特定部门";
        if (quotatype == "4") return "特定职级";
        return "";
      };
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    exportTableData(params) {
      download({
        method: "post",
        url: "/backend/pool/page-pool/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "额度分配-导出.xlsx",
        data: params,
      });
    },
    handleKeyPress(event) {
      if (event) {
        const { keyCode } = event;
        if (keyCode && keyCode == 13) {
          this.$refs.basePage.initTableData();
        }
      }
    },
    setTableBtn() {
      const props = {
        on: {
          click: this.toDetail,
        },
      };
      return checkAuth("gtmsh-point-server.quotaChange.allocation") ? (
        <Button type="primary" {...props}>
          额度分配
        </Button>
      ) : (
        <span></span>
      );
    },
    toDetail() {
      this.$router.push({ name: "quotaAllotDetail" });
    },
    loadTableData(params) {
      let tempParams = { ...params };
      const { startTime, endTime } = tempParams;
      if (startTime) {
        tempParams.startTime = moment(startTime).format("YYYY-MM-DD 00:00:00");
      }
      if (endTime) {
        tempParams.endTime = moment(endTime).format("YYYY-MM-DD 23:59:59");
      }
      delete tempParams.startTime_endTime;
      return new Promise((resolve, reject) => {
        getQuotaList(tempParams).then((res) => {
          resolve(res.data);
        });
      });
    },
  },
};
</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
