<template>
  <div class="app-container">
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="dataForm"
      label-position="right"
      label-width="120px"
      style="min-width: 560px; margin-left: 30px; margin-top: 30px; width: 90%"
    >
    <form-input
        laeblName="角色名称"
        keyStr="name"
        :required="true"
        @updateInputData="updateInputData"
        :inputData="dataForm.name"
      ></form-input>
      <form-input
        laeblName="角色描述"
        keyStr="description"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.description"
        :maxlength="100"
      ></form-input>
      <el-form-item label="权限管理" prop="chooseAuth">
        <el-tree
          :data="authData"
          :disabled="type == 'view'"
          show-checkbox
          node-key="authKey"
          :props="defaultProps"
          :default-checked-keys="defaultChooseAuthKey"
          ref="tree"
        >
        </el-tree>
      </el-form-item>

      <el-form-item style="margin-top: 50px" v-if="type != 'view'">
        <el-button
          type="primary"
          @click="save()"
          size="medium"
          style="margin-right: 50px"
        >
          保&nbsp;存
        </el-button>
        <el-button size="medium" @click="cancel()" v-if="type != 'view'">
          取&nbsp;消
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addRole, editRole, getRole, getAuthTree } from "@/api/user";
import FormInput from "@/components/FormInput";

const defaultForm = {
  roleId: null,
  name: "",
  description: "",
  chooseAuth: [],
};

export default {
  name: "RoleDetail",
  components: {FormInput},
  data() {
    return {
      dataForm: Object.assign({}, defaultForm),
      loading: false,
      rules: {
        name: [
          { required: true, message: "角色名称为必填项", trigger: "blur" },
        ],
      },
      tempRoute: {},
      type: "",
      authData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      defaultChooseAuthKey: [],
    };
  },
  computed: {},
  created() {
    const { roleId } = this.$route.query;
    const { path } = this.$route;
    let title = "";
    this.getAllAuth(path.endsWith("view"));
    if (path.endsWith("view")) {
      title = "查看角色";
      this.type = "view";
      this.dataForm.roleId = roleId;
      this.loadRoleData(roleId);
    } else if (path.endsWith("edit")) {
      title = "编辑角色";
      this.type = "edit";
      this.dataForm.roleId = roleId;
      this.loadRoleData(roleId);
    } else {
      title = "新增角色";
      this.type = "add";
    }
    this.tempRoute = Object.assign({}, this.$route);
    this.setTagsViewTitle(title);
    this.setPageTitle(title + " - 圣恩赞币系统后台");
  },
  methods: {
    updateInputData({ keyStr, value }) {
      this.dataForm[keyStr] = value || "";
    },
    //手动处理查看的时候数据
    addDisabledFieldToTreeNodes(tree) {
      if (!Array.isArray(tree)) {
        return;
      }
      function dfs(node) {
        node.disabled = true;
        if (node.children) {
          for (let child of node.children) {
            dfs(child);
          }
        }
      }
      for (let node of tree) {
        dfs(node);
      }
    },

    getAllAuth(isView) {
      getAuthTree({})
        .then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            let tempData = data[0].children;
            if (tempData && tempData.length > 0) {
              if (isView) {
                this.addDisabledFieldToTreeNodes(tempData);
              }
              this.authData = tempData;
            }
          }
        })
        .catch((err) => {});
    },
    loadRoleData(id) {
      getRole({ id })
        .then((response) => {
          this.dataForm.name = response.data.name;
          this.dataForm.description = response.data.description;
          this.defaultChooseAuthKey = response.data.authKeys || [];
        })
        .catch((err) => {});
    },
    setTagsViewTitle(title) {
      const route = Object.assign({}, this.tempRoute, {
        title,
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    },
    setPageTitle(title) {
      document.title = title;
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$route).then(() => {
        this.$router.replace({ path: "/integral/role-management" });
      });
    },
    save() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          const { name, roleId, description } = this.dataForm;
          let params = { name, description };
          let authKey = this.$refs.tree.getCheckedNodes();
          let tempKey = authKey.map((item) => {
            return item.authKey;
          });
          params.attributeType = 2;
          params.authKeys = tempKey;
          if (this.type == "edit") {
            params.roleId = roleId;
            editRole(params).then((response) => {
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/role-management" });
              });
            });
          } else {
            addRole(params).then((response) => {
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/role-management" });
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.input_error {
  border-color: #ff4949;
}
</style>
<style lang="scss">
.dlg_managers .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.dlg_visibles .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.el-tree {
  display: inline-block;
  min-width: 100%;
}
.dlg_managers .el-dialog {
  min-width: 660px;
}
.dlg_visibles .el-dialog {
  min-width: 660px;
}
.el-tabs__nav-scroll {
  padding-left: 50px;
}
.el-tabs__header {
  margin-bottom: 0px;
}
.addBtn .el-icon-circle-plus-outline {
  font-size: 16px;
}
</style>
