<template>
  <el-container style="height: calc(100vh - 84px)">
    <el-aside
      :style="{
        width: treeWidth,
        'border-right': '1px solid #eee',
        height: '100%',
        padding: 0,
        'background-color': '#ffffff',
      }"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
      >
        <div>
          <el-input
            style="width: 250px"
            slot="reference"
            placeholder="输入部门"
            clearable
            v-model="branchName"
            @input="branchSearchChange"
            @keydown.enter.native="searchBranch"
          >
            <el-button
              slot="append"
              type="primary"
              class="inner-btn"
              icon="el-icon-search"
              style="background: #409eff; color: #fff; border-radius: 0"
              @click="searchBranch"
            ></el-button>
          </el-input>
        </div>
      </el-header>
      <el-main>
        <el-tree
          :data="deptData.tree"
          node-key="id"
          :props="{ children: 'children', label: 'deptName' }"
          :expand-on-click-node="false"
          :default-expanded-keys="['1']"
          highlight-current
          @node-click="loadByDept"
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </el-main>
    </el-aside>
    <el-aside
      :style="{
        width: `calc(100% - ${treeWidth})`,
        height: '100%',
        padding: '5px 10px',
        'background-color': '#ffffff',
      }"
    >
      <el-header
        style="
          font-size: 12px;
          min-height: 50px;
          height: auto !important;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        "
        class="header"
      >
        <div class="filter-item-div2">
          <span>核销月：</span>
          <el-date-picker
            v-model="selectMonth"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </div>
        <div class="filter-item-div2">
          <span>员工姓名：</span>
          <el-popover
            placement="top"
            trigger="manual"
            content="请输入员工姓名"
            v-model="searchkeyTipShow"
          >
            <el-input
              style="width: 150px"
              slot="reference"
              placeholder="请输入员工姓名"
              clearable
              v-model="searchkey"
              @keydown.enter.native="query2"
            >
            </el-input>
          </el-popover>
        </div>
        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          type="primary"
          @click="query2"
          icon="el-icon-search"
        >
          查询
        </el-button>
        <el-button
          v-waves
          class="filter-item btn"
          style="margin-left: 0px"
          icon="el-icon-refresh-right"
          @click="handleReset"
        >
          重置
        </el-button>
        <AuthCheck permission="gtmsh-point-server.verification.export">
          <el-button
            :loading="exportRun"
            v-waves
            class="filter-item btn"
            style="margin-left: 0px"
            @click="exportExcel"
          >
            导出
          </el-button>
        </AuthCheck>
        <AuthCheck permission="gtmsh-point-server.verification.freeze">
          <el-button
            :loading="exportRun"
            v-waves
            class="filter-item btn"
            style="margin-left: 0px"
            type="primary"
            @click="handleFreeze"
          >
            冻结提现
          </el-button>
        </AuthCheck>
        <AuthCheck permission="gtmsh-point-server.verification.verification">
          <el-button
            :loading="exportRun"
            v-waves
            class="filter-item btn"
            type="primary"
            style="margin-left: 0px"
            @click="handleVerification"
          >
            核销提现
          </el-button>
        </AuthCheck>
      </el-header>
      <el-table
        :data="searchList"
        v-loading="loading"
        style="width: 100%"
        @cell-click="handleOperation"
      >
        <el-table-column prop="employeeAccount" label="账号"> </el-table-column>
        <el-table-column prop="employeeName" label="员工姓名">
        </el-table-column>
        <el-table-column prop="employeeNo" label="员工工号"> </el-table-column>
        <el-table-column prop="position" label="职务"> </el-table-column>
        <el-table-column prop="deptName" label="部门"> </el-table-column>
        <el-table-column prop="deptCode" label="所属中心"> </el-table-column>
        <el-table-column prop="employeeRemainNum" label="可提现赞币">
        </el-table-column>
        <el-table-column prop="freezeNum" label="冻结赞币"> </el-table-column>
        <el-table-column
          prop="employeeExchangeNum"
          label="已提现赞币"
          class-name="click-text"
        >
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="query.pageNum"
        :limit.sync="query.pageSize"
        @pagination="getList"
      />
      <i
        style="
          position: absolute;
          top: 50%;
          cursor: pointer;
          margin-left: -24px;
          font-size: 25px;
        "
        :class="openTree ? 'el-icon-caret-left' : 'el-icon-caret-right'"
        @click="updateOpenTree"
      ></i>
    </el-aside>

    <el-dialog
      title="已提现详情"
      :visible.sync="isOpen"
      width="40%"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items"
    >
      <el-table
        :data="verificationDetailList"
        v-loading="verificationDetailLoading"
        style="width: 100%"
        max-height="70vh"
        height="70vh"
      >
        <el-table-column label="已提现赞币" prop="changeNum"> </el-table-column>
        <el-table-column label="冻结时间" prop="freezeTime"></el-table-column>
        <el-table-column label="核销时间" prop="verifiedAt"></el-table-column>
        <el-table-column label="核销人" prop="verifiedBy"></el-table-column>
      </el-table>
      <pagination
        v-show="verificationDetailTotal > 0"
        :total="verificationDetailTotal"
        :page.sync="verificationQuery.pageNum"
        :limit.sync="verificationQuery.pageSize"
        @pagination="getList"
      />
    </el-dialog>
  </el-container>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import {
  queryintegralbydept,
  queryintegral,
  queryusermonthaward,
} from "@/api/quota";
import {
  getPage,
  freezeBatch,
  verificationBatch,
  verificationDetail,
} from "@/api/withdrawal";
import { allDept, getUserList, disabledEmployee } from "@/api/user";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import moment from "moment";
import AuthCheck from "@/components/AuthCheck";
import download from "@/utils/download";

export default {
  name: "QuotaQuery",
  components: { Pagination, AuthCheck },
  filters: {},
  directives: { waves },
  data() {
    return {
      searchkey: "",
      searchkeyTipShow: false,
      loading: false,
      total: 0,
      searchList: [],
      selectMonth: [],
      branchName: "", //部门名字
      deptData: {
        tree: [],
        map: new Map(),
      },
      query: {
        deptName: "",
        deptId: 1,
        pageNum: 1,
        pageSize: 20,
        endYearMonth: "",
        startYearMonth: "2023-11",
      },
      openTree: true,
      treeWidth: "280px",
      infoDlg: {
        visible: false,
        month: null,
        userid: null,
        loading: false,
        list: [],
      },
      value: "",
      exportRun: false,

      verificationQuery: {
        pageNum: 1,
        pageSize: 20,
      },
      isOpen: false,
      verificationDetailList: [],
      verificationDetailTotal: 0,
      verificationID: "",
      verificationDetailLoading: false,
      deptId: "",
    };
  },
  created() {
    let month = moment().format("YYYY-MM");
    this.selectMonth = [month, month];
    this.loadDeptData();
  },
  computed: {},
  methods: {
    updateOpenTree() {
      if (this.openTree) {
        this.openTree = false;
        this.treeWidth = "10px";
      } else {
        this.openTree = true;
        this.treeWidth = "280px";
      }
    },
    branchSearchChange(value) {
      if (!value) {
        this.$refs.tree.filter(value);
      }
    },
    //查询部门
    searchBranch() {
      const { branchName } = this._data;
      this.$refs.tree.filter(branchName);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.deptName.indexOf(value) !== -1;
    },
    deptpath(id) {
      let path = "";
      while (true) {
        if (!id) break;
        if (id == "1" && path != "") break;
        let dept = this.deptData.map.get(id);
        if (!dept) break;
        if (path != "") path = "\\" + path;
        path = dept.deptName + path;
        id = dept.parentDeptId;
      }
      return path;
    },
    getMonthStr(month) {
      let m = month.getMonth() + 1;
      if (m < 10) m = "0" + m;
      else m = "" + m;
      return month.getFullYear() + m;
    },
    loadDeptData() {
      allDept({})
        .then((response) => {
          let deptArray = response.data;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.id, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (
              item.parentDeptId &&
              item.parentDeptId != "" &&
              item.parentDeptId != "0"
            ) {
              parent = deptMap.get(item.parentDeptId);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
          this.getList();
        })
        .catch((err) => {});
    },
    getList() {
      this.loading = true;
      if (!this.query.startYearMonth || !this.query.endYearMonth) {
        const nowDate = new Date();
        this.query.startYearMonth = `${nowDate.getFullYear()}-${
          new Date().getMonth() + 1
        }`;
        this.query.endYearMonth = `${nowDate.getFullYear()}-${
          new Date().getMonth() + 1
        }`;
      }
      getPage(this.query)
        .then((response) => {
          this.searchList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loadByDept(data) {
      this.deptId = data.id || 1;
      this.query = {
        deptId: data.id || 1,
        employeeName: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },
    handleReset() {
      this.searchkey = "";
      let month = moment().format("YYYY-MM");
      this.selectMonth = [month, month];
      const selectMonthChange = JSON.parse(JSON.stringify(this.selectMonth));
      this.query = {
        employeeName: "",
        deptName: "",
        deptId: this.deptId || 1,
        pageNum: 1,
        pageSize: 20,
        startYearMonth:
          selectMonthChange.length > 0 ? selectMonthChange[0] : "",
        endYearMonth: selectMonthChange.length > 0 ? selectMonthChange[1] : "",
      };
      this.getList();
    },
    query2() {
      const selectMonthChange = JSON.parse(JSON.stringify(this.selectMonth));
      this.query = {
        employeeName: this.searchkey || "",
        deptName: "",
        deptId: this.deptId || 1,
        pageNum: 1,
        pageSize: 20,
        startYearMonth:
          selectMonthChange.length > 0 ? selectMonthChange[0] : "",
        endYearMonth: selectMonthChange.length > 0 ? selectMonthChange[1] : "",
      };
      this.getList();
    },
    showInfo(userMonthIntegral) {
      this.infoDlg = {
        visible: true,
        month: this.query.month,
        userid: userMonthIntegral.userid,
        loading: false,
        list: [],
      };
      this.infoDlg.loading = false;
      // queryusermonthaward(this.infoDlg.userid, this.infoDlg.month)
      //   .then((response) => {
      //     this.infoDlg.list = response.data.list;
      //     this.infoDlg.loading = false;
      //   })
      //   .catch((err) => {
      //     this.infoDlg.loading = false;
      //   });
    },

    exportExcel() {
      const selectMonthChange = JSON.parse(JSON.stringify(this.selectMonth));
      this.query = {
        employeeName: this.searchkey || "",
        deptName: "",
        deptId: this.deptId || 1,
        pageNum: 1,
        pageSize: 20,
        startYearMonth:
          selectMonthChange.length > 0 ? selectMonthChange[0] : "",
        endYearMonth: selectMonthChange.length > 0 ? selectMonthChange[1] : "",
      };
      download({
        method: "post",
        url: "/backend/point/withdrawal/verification/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "提现管理-导出.xlsx",
        data: this.query,
      });
    },
    doExportExcel(datalist) {
    
    },
    handleOperation(row, column, cell, event) {
      if (column.label == "已提现赞币") {
        this.verificationID = row.employeeId;
        this.handleVerificationDetail(row.employeeId);
        this.isOpen = true;
      }
    },
    async handleVerificationDetail(id) {
      this.verificationDetailLoading = true;
      verificationDetail({ ...this.query, employeeId: this.verificationID })
        .then((response) => {
          this.verificationDetailList = response.data.list;
          this.verificationDetailTotal = response.data.total;
          this.verificationDetailLoading = false;
        })
        .catch((err) => {
          this.verificationDetailLoading = false;
        });
    },
    handleFreeze() {
      this.$confirm("本操作确定冻结提现吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        freezeBatch({})
          .then((response) => {
            this.$message({
              message: "冻结成功!",
              type: "success",
            });
            this.query2();
          })
          .catch((err) => {});
      });
    },
    handleVerification() {
      this.$confirm("确定核销提现赞币？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        verificationBatch({})
          .then((response) => {
            this.$message({
              message: "完成核销赞币!",
              type: "success",
            });
            this.query2();
          })
          .catch((err) => {});
      });
    },
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.dlg_items .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}

.dlg_items .el-dialog {
  min-width: 660px;
}

.header {
  display: flex;
  align-items: center;
}

.btn {
  height: 32px;
}

.filter-item-div {
  margin-right: 20px;
  min-width: 260px;
}

.filter-item-div2 {
  margin-right: 20px;
  min-width: 160px;
}

.filter-item {
  margin-right: 20px;
}

/**点击样式 */
.click-text {
  color: #1890ff;
}
.click-text :hover {
  cursor: pointer;
}
</style>
