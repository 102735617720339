<template>
  <div class="login-container">
      <div class="title-container">
        <h3 class="title">您已退出登录</h3>
      </div>
      <el-button class="btn_login" type="primary" style="margin-bottom: 20px;" @click="relogin">
        重新登录
      </el-button>
  </div>
</template>

<script>
import waves from '@/directive/waves' // waves directive
export default {
  name: 'PageLogouted',
  data() {
    return {
    }
  },
  methods: {
    relogin(){
      this.$router.replace({ path: "/login" });
    }
  }
}
</script>

<style scoped>
  .btn_login{
     height:50px;
     line-height: 50rpx;
     font-size: 20px;
     font-weight: 500;
     padding: 0 60px;
  }
 </style>
<style lang="scss" scoped>
$bg:#2d3a4b;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title-container {
    position: relative;

    .title {
      font-size: 38px;
      color: $light_gray;
      margin: 100px auto 80px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}


</style>
