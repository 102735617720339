import Cookies from 'js-cookie'

const TokenKey = 'X-Token'

const AuthKey = 'X-Auth'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


export function getAuth() {
  return Cookies.get(AuthKey)
}

export function setAuth(auth) {
  return Cookies.set(AuthKey, auth)
}

export function removeAuth() {
  return Cookies.remove(AuthKey)
}

export function checkAuth(authKeyStr) {
  // console.log('authKeyStr', authKeyStr)
  let hasAuth = false
  let temp = Cookies.get(AuthKey)
  if (temp && temp.length > 0) {
    let tempArr = JSON.parse(temp)
    if (tempArr) {
      hasAuth = tempArr.includes(authKeyStr)
    }
  }
  return hasAuth
}

