var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "10px" },
            attrs: { placeholder: "事件名称" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              },
            },
            model: {
              value: _vm.query.awardEventName,
              callback: function ($$v) {
                _vm.$set(_vm.query, "awardEventName", $$v)
              },
              expression: "query.awardEventName",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px", "margin-right": "10px" },
              attrs: { placeholder: "事件状态", clearable: "" },
              model: {
                value: _vm.query.awardEventStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "awardEventStatus", $$v)
                },
                expression: "query.awardEventStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "启用", value: "1" } }),
              _c("el-option", { attrs: { label: "禁用", value: "0" } }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v(" 查询 ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { icon: "el-icon-refresh-right" },
              on: { click: _vm.resetFilter },
            },
            [_vm._v(" 重置 ")]
          ),
        ],
        1
      ),
      _c(
        "AuthCheck",
        { attrs: { permission: "gtmsh-point-server.award.event.save" } },
        [
          _c(
            "router-link",
            { attrs: { to: "/integral/reasonadd" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "primary" },
                },
                [_vm._v("创建事件")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "事件图标", align: "center", "min-width": "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: { src: _vm.getUrl(row.eventImageUrl) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "事件名称",
              align: "center",
              prop: "eventName",
              "min-width": "80px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "事件描述",
              align: "center",
              prop: "eventDesc",
              "min-width": "150px",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "赞币范围", align: "center", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.eventMinScore) +
                        "~" +
                        _vm._s(row.eventMaxScore) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "更新人",
              align: "center",
              prop: "updatedBy",
              width: "80px",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "更新时间", align: "center", width: "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.updatedAt))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", "class-name": "status-col", width: "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        disabled: _vm.getUpdateAuth(),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleChangeStat(
                            $event,
                            scope.$index,
                            scope.row.id
                          )
                        },
                      },
                      model: {
                        value: scope.row.eventStatus,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "eventStatus", $$v)
                        },
                        expression: "scope.row.eventStatus",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
              width: "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/integral/reasonedit",
                            query: { id: row.id },
                          },
                        },
                      },
                      [
                        _c(
                          "AuthCheck",
                          {
                            attrs: {
                              permission:
                                "gtmsh-point-server.award.event.update",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [_vm._v(" 编辑 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "AuthCheck",
                      {
                        attrs: {
                          permission: "gtmsh-point-server.award.event.delete",
                        },
                      },
                      [
                        false
                          ? _c(
                              "el-popconfirm",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  title:
                                    "删除后事件将无法恢复，请谨慎操作，确定要删除吗？",
                                },
                                on: {
                                  onConfirm: function ($event) {
                                    return _vm.handleDelete(row, $index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      size: "mini",
                                      type: "danger",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.query.pageNum,
          limit: _vm.query.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }