var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "content-main" }, [
      _vm._m(1),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "div-content-main",
        },
        [
          _c(
            "div",
            { staticClass: "div-content" },
            [
              _c("span", { staticClass: "span-title" }, [_vm._v("欢迎登录")]),
              _c("el-input", {
                ref: "username",
                staticClass: "input-content",
                attrs: {
                  placeholder: "请输入您的账号",
                  name: "username",
                  type: "text",
                  tabindex: "1",
                  autocomplete: "on",
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username",
                },
              }),
              _c("el-input", {
                key: _vm.passwordType,
                ref: "password",
                staticClass: "input-content",
                attrs: {
                  type: _vm.passwordType,
                  placeholder: "请输入您的密码",
                  name: "password",
                  tabindex: "2",
                  autocomplete: "on",
                },
                on: {
                  blur: function ($event) {
                    _vm.capsTooltip = false
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    return _vm.checkCapslock($event)
                  },
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              }),
              _c(
                "div",
                { staticClass: "btn-login", on: { click: _vm.handleLogin } },
                [
                  _c("div", { staticClass: "span-login" }, [_vm._v("登录")]),
                  _c("i", { staticClass: "el-icon-right" }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div-tip" }, [
      _c("span", { staticClass: "span-tip" }, [_vm._v("SNS圣恩·通行证")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div-left" }, [
      _c("img", {
        staticClass: "iv-left-bg",
        attrs: { src: require("@/assets/icon/icon_login_logo.png") },
      }),
      _c("div", { staticClass: "div-left-tip" }, [
        _c("span", { staticClass: "span-left-tip span-left-big" }, [
          _vm._v("SNS圣恩"),
        ]),
        _c("span", { staticClass: "span-left-tip" }, [
          _vm._v("提升企业内部互动"),
        ]),
        _c("span", { staticClass: "span-left-tip" }, [
          _vm._v("激发内部正能量"),
        ]),
        _c("span", { staticClass: "span-left-tip" }, [
          _vm._v("共创企业文化的精彩篇章"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div-bottom" }, [
      _c("div", { staticClass: "div-contact-us" }, [
        _c(
          "a",
          {
            staticClass: "span-bottom-tip",
            attrs: { href: "https://www.gtmsh.com/" },
          },
          [_vm._v("官网首页")]
        ),
        _c(
          "a",
          {
            staticClass: "span-bottom-tip",
            attrs: {
              href: "https://www.gtmsh.com/index.php?c=article&a=type&tid=64",
            },
          },
          [_vm._v("关于我们")]
        ),
      ]),
      _c("div", { staticClass: "span-bottom-tip2" }, [
        _vm._v(
          " 深圳棠人科技有限公司 版权所有CopyRight © 2023 ALL RIGHTS RESERVED "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }