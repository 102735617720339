var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-comp-wrap el-input__inner" },
    [
      _vm.type == "user"
        ? _c(
            "div",
            {
              staticClass: "preview-btn",
              on: {
                click: function ($event) {
                  return _vm.openItemDlg_user()
                },
                mouseenter: function ($event) {
                  return _vm.focusFun()
                },
                mouseleave: function ($event) {
                  return _vm.focusFun()
                },
              },
            },
            [
              _c("div", { staticClass: "preview-content-wrap" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.placeholderShow,
                        expression: "placeholderShow",
                      },
                    ],
                    staticClass: "preview-placeholder",
                  },
                  [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "preview-content" },
                  _vm._l(_vm.selectValues, function (item) {
                    return _c(
                      "el-tag",
                      {
                        key: item.id,
                        staticClass: "preview-tag",
                        attrs: { size: "mini" },
                      },
                      [_vm._v(_vm._s(item.realName))]
                    )
                  }),
                  1
                ),
                _c("span", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clear()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-close icon close-icon",
                      }),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-user icon" }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.type == "dept"
        ? _c(
            "div",
            {
              staticClass: "preview-btn",
              on: {
                click: function ($event) {
                  return _vm.openItemDlg_dept()
                },
                mouseenter: function ($event) {
                  return _vm.focusFun()
                },
                mouseleave: function ($event) {
                  return _vm.focusFun()
                },
              },
            },
            [
              _c("div", { staticClass: "preview-content-wrap" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.placeholderShow,
                        expression: "placeholderShow",
                      },
                    ],
                    staticClass: "preview-placeholder",
                  },
                  [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "preview-content" },
                  _vm._l(_vm.selectValues, function (item) {
                    return _c(
                      "el-tag",
                      {
                        key: item.id,
                        staticClass: "preview-tag",
                        attrs: { size: "mini" },
                      },
                      [_vm._v(_vm._s(item.deptName))]
                    )
                  }),
                  1
                ),
                _c("span", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showClear,
                          expression: "showClear",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clear()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-close icon close-icon",
                      }),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-collection-tag icon" }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dlg_items",
          attrs: {
            title: "设置接收人",
            visible: _vm.itemDlg_user.visible,
            width: "80%",
            top: "calc( ( 100vh - 550px ) / 2 )",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.itemDlg_user, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-container",
            { staticClass: "dialog-container" },
            [
              _c(
                "el-aside",
                { staticClass: "input-aside", attrs: { width: "30%" } },
                [
                  _c(
                    "el-header",
                    { staticClass: "input-header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            width: "200px",
                            trigger: "manual",
                            content: "请输入关键词",
                          },
                          model: {
                            value: _vm.itemDlg_user.searchkeyTipShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.itemDlg_user,
                                "searchkeyTipShow",
                                $$v
                              )
                            },
                            expression: "itemDlg_user.searchkeyTipShow",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-border-color",
                              attrs: {
                                slot: "reference",
                                placeholder: "请输入关键字",
                                clearable: "",
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.itemDlg_user_query($event)
                                },
                              },
                              slot: "reference",
                              model: {
                                value: _vm.itemDlg_user.searchkey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.itemDlg_user, "searchkey", $$v)
                                },
                                expression: "itemDlg_user.searchkey",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.itemDlg_user_query },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { height: "346px" } },
                    [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.deptData.tree,
                          "node-key": "id",
                          props: { children: "children", label: "deptName" },
                          "expand-on-click-node": false,
                          "default-expanded-keys": ["1"],
                        },
                        on: { "node-click": _vm.itemDlg_user_loadByDept },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                { staticClass: "table-aside", attrs: { width: "50%" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.itemDlg_user.loading,
                          expression: "itemDlg_user.loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.itemDlg_user.searchList,
                        "max-height": "386px",
                        height: "386px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "realName",
                          fixed: "",
                          label: "员工姓名",
                          width: "80px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "deptName",
                          label: "部门",
                          width: "200px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "employeeAccount",
                          label: "账号",
                          width: "100px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "position",
                          label: "岗位",
                          width: "120px",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          fixed: "right",
                          label: "操作",
                          width: "80px",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      disabled: _vm.itemDlg_user_added(row.id),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.itemDlg_user_add(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                { staticClass: "btn-aside", attrs: { width: "20%" } },
                [
                  _c("el-header", { staticClass: "choose-header" }, [
                    _vm._v(" 已选择 "),
                  ]),
                  _c(
                    "el-main",
                    { staticStyle: { height: "346px", padding: "10px 0" } },
                    _vm._l(_vm.itemDlg_user.items, function (user) {
                      return _c(
                        "el-tag",
                        {
                          key: user.id,
                          staticStyle: {
                            margin: "10px 0 0 10px",
                            height: "auto",
                            "white-space": "normal",
                          },
                          attrs: { closable: "", size: "small" },
                          on: {
                            close: function ($event) {
                              return _vm.itemDlg_user_remove(user)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(user.realName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeItemDlg_user(false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeItemDlg_user(true)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dlg_items",
          attrs: {
            title: "设置专属部门",
            visible: _vm.itemDlg_dept.visible,
            width: "80%",
            top: "calc( ( 100vh - 550px ) / 2 )",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.itemDlg_dept, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-container",
            { staticClass: "dialog-container" },
            [
              _c(
                "el-aside",
                { staticClass: "input-aside", attrs: { width: "40%" } },
                [
                  _c(
                    "el-header",
                    { staticClass: "input-header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            width: "200px",
                            trigger: "manual",
                            content: "请输入关键词",
                          },
                          model: {
                            value: _vm.itemDlg_dept.searchkeyTipShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.itemDlg_dept,
                                "searchkeyTipShow",
                                $$v
                              )
                            },
                            expression: "itemDlg_dept.searchkeyTipShow",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-border-color",
                              attrs: {
                                slot: "reference",
                                placeholder: "请输入关键字",
                                clearable: "",
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.itemDlg_dept_query($event)
                                },
                              },
                              slot: "reference",
                              model: {
                                value: _vm.itemDlg_dept.searchkey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.itemDlg_dept, "searchkey", $$v)
                                },
                                expression: "itemDlg_dept.searchkey",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.itemDlg_dept_query },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { height: "309px" } },
                    [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.deptData.tree,
                          "node-key": "id",
                          props: { children: "children", label: "name" },
                          "expand-on-click-node": false,
                          "default-expanded-keys": ["1"],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c("span", [_vm._v(_vm._s(node.label))]),
                                  _c(
                                    "span",
                                    [
                                      data.id != "1"
                                        ? _c("el-button", {
                                            staticClass: "addBtn",
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                              circle: "",
                                              icon: "el-icon-circle-plus-outline",
                                              disabled: _vm.itemDlg_dept_added(
                                                data.id
                                              ),
                                            },
                                            on: {
                                              click: function () {
                                                return _vm.itemDlg_dept_add(
                                                  data
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                { staticClass: "table-aside", attrs: { width: "40%" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.itemDlg_dept.loading,
                          expression: "itemDlg_dept.loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.itemDlg_dept.searchList,
                        "max-height": "346px",
                        height: "346px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "title", label: "部门" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.deptpath(row.id))),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "title", label: "操作", width: "80px" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.id != "1"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                          disabled: _vm.itemDlg_dept_added(
                                            row.id
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.itemDlg_dept_add(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 添加 ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                { staticClass: "btn-aside", attrs: { width: "20%" } },
                [
                  _c("el-header", { staticClass: "choose-header" }, [
                    _vm._v(" 已选择 "),
                  ]),
                  _c(
                    "el-main",
                    { staticStyle: { height: "356px", padding: "10px 0" } },
                    _vm._l(_vm.itemDlg_dept.items, function (dept) {
                      return _c(
                        "el-tag",
                        {
                          key: dept.id,
                          staticStyle: {
                            margin: "10px 0 0 10px",
                            height: "auto",
                            "white-space": "normal",
                          },
                          attrs: { closable: "", size: "small" },
                          on: {
                            close: function ($event) {
                              return _vm.itemDlg_dept_remove(dept)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(dept.deptName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeItemDlg_dept(false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeItemDlg_dept(true)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }