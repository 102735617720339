var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-prew-wrap" },
    [
      _c(
        "div",
        {
          staticClass: "video-prew-wrap",
          on: {
            click: function ($event) {
              return _vm.openVideoDlg(_vm.videoUrl)
            },
          },
        },
        [
          _c("div", {
            staticClass: "preview-image",
            attrs: { src: _vm.thumburl, fit: "cover" },
          }),
          _c("i", { staticClass: "el-icon-caret-right play_btn_inline" }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.videoDlg.visible,
            width: "60%",
            top: "20vh",
            "custom-class": "dlg_video",
            "destroy-on-close": true,
            "show-close": false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.videoDlg, "visible", $event)
            },
            close: function ($event) {
              return _vm.onVideoDlgClose()
            },
          },
        },
        [
          _c(
            "video",
            {
              staticStyle: {
                width: "100%",
                height: "60vh",
                "background-color": "#000000",
              },
              attrs: {
                src: _vm.videoDlg.url,
                controls: "controls",
                autoplay: "autoplay",
              },
            },
            [_vm._v(" 您的浏览器不支持 video 标签。 ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }