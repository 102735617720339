import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

const service = axios.create({
  baseURL: "/api/v1/gtmsh-point", // url = base url + request url
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      config.headers["x-http-token"] = getToken();
    }
    config.headers["x-http-app-code"] = "BACKEND";
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    const {
      config: { fileName },
    } = response;
    const { type } = res;
    //如果返回的是application/json，说明数据有问题
    if (type == "application/json") {
      let reader = new FileReader();
      reader.onload = function (event) {
        let textData = event.target.result; // 这里得到的是Blob对象中的文本数据
        let jsonData = JSON.parse(textData); // 将文本数据解析为JSON
        const { code, message } = jsonData;
        if (code === 300400) {
          // to re-login
          MessageBox.confirm("您的登录已失效, 请重新登录", "重新登录", {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            store.dispatch("user/resetToken").then(() => {
              location.reload();
            });
          });
        } else {
          Message({
            message: message || "下载错误",
            type: "error",
            duration: 5 * 1000,
          });
        }
        console.log(jsonData); // 输出：{name: "John", age: 30}
      };
      reader.readAsText(res); // 将Blob对象读取为文本
      return;
    }
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName || "失败文件.xlsx"); // 设置下载文件的文件名
    document.body.appendChild(link);
    link.click(); // 模拟点击下载链接
    document.body.removeChild(link);
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
export const BaseURL = process.env.VUE_APP_BASE_API2;
export function GetTokenHeader() {
  return { "X-Token": getToken() };
}
