var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    {
      attrs: { label: _vm.laeblName, prop: _vm.keyStr, rules: _vm.inputRules },
    },
    [
      _c("el-input", {
        staticStyle: { width: "400px" },
        attrs: {
          maxlength: _vm.maxlength,
          "show-word-limit": "",
          disabled: _vm.disabled,
        },
        on: { input: _vm.updateData },
        model: {
          value: _vm.localData,
          callback: function ($$v) {
            _vm.localData = $$v
          },
          expression: "localData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }