<template>
  <div v-loading="listLoading">
    <AuthCheck permission="gtmsh-point-server.point-pool.refresh">
      <el-button
        type="primary"
        style="margin-bottom: 20px"
        @click="initFirstData"
        >生成初始赞币</el-button
      >
    </AuthCheck>
    <table-base-page
      :columns="columns"
      :searchFileds="searchFileds"
      :loadTableData="loadTableData"
      :exportTableData="exportTableData"
      :buttons="buttons"
      ref="basePage"
    ></table-base-page>
    <el-dialog
      title="初始赞币额度明细"
      :visible.sync="modalVisible"
      width="66%"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
        class="header"
      >
        <div class="filter-item-div2">
          <span>账号：</span>
          <el-popover
            placement="top"
            trigger="manual"
            content="请输入账号"
            v-model="searchkeyTipShow"
          >
            <el-input
              style="width: 150px"
              slot="reference"
              placeholder="请输入账号"
              clearable
              v-model="modalQuery.employeeAccount"
              @keydown.enter="loadChildData"
            >
            </el-input>
          </el-popover>
        </div>

        <div class="filter-item-div2">
          <span>姓名：</span>
          <el-popover
            placement="top"
            trigger="manual"
            content="请输入姓名"
            v-model="searchkeyTipShow"
          >
            <el-input
              style="width: 150px"
              slot="reference"
              placeholder="请输入姓名"
              clearable
              v-model="modalQuery.realName"
              @keydown.enter="loadChildData"
            >
            </el-input>
          </el-popover>
        </div>

        <div class="filter-item-div2">
          <span>职位：</span>
          <el-select
            v-model="modalQuery.positionLevelDictCode"
            placeholder="请选择"
          >
            <el-option
              v-for="item in optionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="loadChildData"
        >
          查询
        </el-button>
        <el-button
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          @click="resetFilter"
          icon="el-icon-refresh-right"
        >
          重置
        </el-button>
      </el-header>
      <el-table
        :data="childList"
        v-loading="modalLoading"
        style="width: 100%"
        max-height="55vh"
        height="55vh"
      >
        <el-table-column label="账号" prop="employeeAccount"></el-table-column>
        <el-table-column label="员工姓名" prop="realName"></el-table-column>
        <el-table-column label="员工工号" prop="employeeNo"></el-table-column>
        <el-table-column label="职位" prop="position"></el-table-column>
        <el-table-column label="赞币基数" prop="baseNum"></el-table-column>
        <el-table-column label="赞币总数" prop="baseTotalNum"></el-table-column>
      </el-table>
      <pagination
        v-show="childList.length > 0"
        :total="childTotal"
        :page.sync="modalQuery.pageNum"
        :limit.sync="modalQuery.pageSize"
        @pagination="loadChildData"
      />
    </el-dialog>
  </div>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import TableBasePage from "@/components/TableBasePage";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import {
  getQuotaManagementList,
  getQuotaManagementChildList,
  initQuotaManagement,
  exportQuotaManagementList,
} from "@/api/quotaallot";
import { getPosition } from "@/api/user";
import AuthCheck from "@/components/AuthCheck";
import download from "@/utils/download";
import { checkAuth } from "@/utils/auth";

export default {
  name: "QuotaAllotList",
  filters: {},
  directives: { waves },
  components: {
    TableBasePage,
    Pagination,
    AuthCheck,
  },
  data() {
    return {
      list: null,
      listLoading: false,
      buttons: ["search", "reset", "export"],
      columns: [
        {
          label: "中心名称",
          prop: "centerName",
          align: "center",
        },
        {
          label: "中心人数",
          prop: "employeeTotalNum",
          align: "center",
        },
        {
          label: "初始额度",
          prop: "poolTotalNum",
          align: "center",
          render: (poolTotalNum, scope, h) => {
            return (
              <a
                style="color:#1890ff"
                on={{ click: () => this.showModal(scope.row) }}
              >
                {poolTotalNum}
              </a>
            );
          },
        },
        {
          label: "所剩额度",
          prop: "poolRemainNum",
          align: "center",
        },
        {
          label: "操作人",
          prop: "createdBy",
          align: "center",
        },
        {
          label: "时间",
          prop: "createdAt",
          align: "center",
          // render: (createdAt, scope, h) => {
          //   return moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
          // }
        },
      ],
      searchFileds: [
        {
          prop: "centerName",
          placeholder: "中心名称",
          type: "input",
        },
      ],
      searchkeyTipShow: false,
      modalVisible: false,
      modalQuery: {
        employeeAccount: "",
        realName: "",
        pageNum: 1,
        pageSize: 20,
        eventId: "",
        centerCode: "",
        positionLevelDictCode: "-99",
      },
      childList: [],
      modalLoading: false,
      exportRun: false,
      childTotal: 0,
      optionList: [],
    };
  },
  created() {
    if (checkAuth("gtmsh-point-server.point-pool.export")) {
      this.buttons = ["search", "reset", "export"];
    } else {
      this.buttons = ["search", "reset"];
    }
    this.requestPosition();
  },
  computed: {
    quotatypename() {
      return (quotatype) => {
        if (quotatype == "1") return "全员";
        if (quotatype == "2") return "特定用户";
        if (quotatype == "3") return "特定部门";
        if (quotatype == "4") return "特定职级";
        return "";
      };
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event) {
        const { keyCode } = event;
        if (keyCode && keyCode == 13) {
          this.$refs.basePage.initTableData();
        }
      }
    },
    resetFilter() {
      this.modalQuery = {
        employeeAccount: "",
        realName: "",
        pageNum: 1,
        pageSize: 20,
        eventId: "",
        centerCode: this.modalQuery.centerCode,
        positionLevelDictCode: "-99",
      };
      this.loadChildData();
    },
    requestPosition() {
      getPosition({})
        .then((response) => {
          if (response.data && response.data.length > 0) {
            let tempList = response.data.map((item) => {
              return {
                label: item.positionName,
                value: item.id + "",
              };
            });
            this.optionList = [{ label: "全部", value: "-99" }, ...tempList];
          }
        })
        .catch((err) => {});
    },
    showModal(data) {
      const { centerCode } = data;
      if (centerCode) {
        this.modalQuery = {
          employeeAccount: "",
          realName: "",
          pageNum: 1,
          pageSize: 20,
          eventId: "",
          centerCode: centerCode,
          positionLevelDictCode: "-99",
        };
        this.childList = [];
        this.childTotal = 0;
        this.modalVisible = true;
        this.loadChildData();
      }
    },
    loadChildData() {
      this.modalLoading = true;
      let params = { ...this.modalQuery };
      const { positionLevelDictCode } = this.modalQuery;
      if (positionLevelDictCode == "-99") {
        params.positionLevelDictCode = "";
      }
      getQuotaManagementChildList(params)
        .then((response) => {
          this.childList = response.data.list;
          this.childTotal = response.data.total;
          this.modalLoading = false;
        })
        .catch((err) => {
          this.modalLoading = false;
        });
    },
    // setTableBtn() {
    //   const props = {
    //     on: {
    //       click: this.initFirstData,
    //     },
    //   };
    //   return (

    //   );
    // },
    toDetail() {
      this.$router.push({ name: "quotaAllotDetail" });
    },
    exportTableData(params) {
      download({
        method: "post",
        url: "/backend/pool/page-pool/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "赞币管理-导出.xlsx",
        data: params,
      });
    },
    loadTableData(params) {
      return new Promise((resolve, reject) => {
        getQuotaManagementList(params).then((res) => {
          resolve(res.data);
        });
      });
    },
    initFirstData() {
      this.listLoading = true;
      initQuotaManagement({})
        .then((response) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.listLoading = false;
          this.$refs.basePage.initTableData();
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}

.dlg_items .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
  height: 40vh !important;
}

.dlg_items .el-dialog {
  min-width: 660px;
}
.header {
  display: flex;
  align-items: center;
}

.filter-item-div2 {
  margin-right: 20px;
  min-width: 160px;
}

.filter-item-div {
  margin-right: 20px;
  min-width: 260px;
}

.span-pool-total :hover {
  cursor: pointer;
}
</style>
