var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: {
            "min-width": "560px",
            "margin-left": "30px",
            "margin-top": "30px",
            width: "90%",
          },
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c("form-input", {
            attrs: {
              laeblName: "部门名称",
              keyStr: "deptName",
              required: true,
              inputData: _vm.dataForm.deptName,
              disabled: _vm.type == "view",
            },
            on: { updateInputData: _vm.updateInputData },
          }),
          _c("form-input", {
            attrs: {
              laeblName: "部门简称",
              keyStr: "deptAlias",
              required: false,
              inputData: _vm.dataForm.deptAlias,
              disabled: _vm.type == "view",
            },
            on: { updateInputData: _vm.updateInputData },
          }),
          _c("form-input", {
            attrs: {
              laeblName: "部门编码",
              keyStr: "deptCode",
              required: true,
              inputData: _vm.dataForm.deptCode,
              disabled: _vm.type != "add",
            },
            on: { updateInputData: _vm.updateInputData },
          }),
          _vm.dataForm.id != 1
            ? _c(
                "el-form-item",
                { attrs: { label: "父级部门", prop: "parentDeptName" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        padding: "0px 6px 0px 3px",
                        width: "400px",
                        "min-height": "28px",
                        "vertical-align": "middle",
                      },
                      attrs: { disabled: _vm.type == "view" },
                      on: {
                        click: function ($event) {
                          return _vm.openDeptDlg("senddis")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "white-space": "normal",
                                "text-align": "left",
                              },
                            },
                            [
                              _vm.dataForm.parentDeptName
                                ? _c(
                                    "el-tag",
                                    {
                                      staticStyle: {
                                        margin: "3px",
                                        "white-space": "normal",
                                        height: "auto",
                                      },
                                      attrs: { size: "mini" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.dataForm.parentDeptName)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("i", {
                            staticClass: "el-icon-collection-tag",
                            staticStyle: {
                              "font-size": "16px",
                              color: "#dcdfe6",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    model: {
                      value: _vm.dataForm.parentDeptName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "parentDeptName", $$v)
                      },
                      expression: "dataForm.parentDeptName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.type != "view"
            ? _c(
                "el-form-item",
                { staticStyle: { "margin-top": "50px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "50px" },
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" 保 存 ")]
                  ),
                  _vm.type != "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v(" 取 消 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dlg_items",
          attrs: {
            title: "设置部门",
            visible: _vm.deptDlg.visible,
            width: "80%",
            top: "calc( ( 100vh - 550px ) / 2 )",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.deptDlg, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-container",
            { staticStyle: { height: "400px", border: "1px solid #eee" } },
            [
              _c(
                "el-aside",
                {
                  staticStyle: {
                    "border-right": "1px solid #eee",
                    height: "100%",
                    padding: "0",
                    "background-color": "#ffffff",
                    "margin-bottom": "0px",
                  },
                  attrs: { width: "40%" },
                },
                [
                  _c(
                    "el-header",
                    {
                      staticStyle: {
                        "text-align": "right",
                        "font-size": "12px",
                        height: "50px",
                        "line-height": "50px",
                        "border-bottom": "1px solid #eee",
                      },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            width: "200px",
                            trigger: "manual",
                            content: "请输入关键词",
                          },
                          model: {
                            value: _vm.deptDlg.searchkeyTipShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.deptDlg, "searchkeyTipShow", $$v)
                            },
                            expression: "deptDlg.searchkeyTipShow",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                slot: "reference",
                                placeholder: "请输入关键字",
                                clearable: "",
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.deptDlg_query($event)
                                },
                              },
                              slot: "reference",
                              model: {
                                value: _vm.deptDlg.searchkey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deptDlg, "searchkey", $$v)
                                },
                                expression: "deptDlg.searchkey",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.deptDlg_query },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { height: "309px" } },
                    [
                      _c("el-tree", {
                        attrs: {
                          data: _vm.deptData.tree,
                          "node-key": "id",
                          props: { children: "children", label: "deptName" },
                          "expand-on-click-node": false,
                          "default-expanded-keys": ["1"],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c("span", [_vm._v(_vm._s(node.label))]),
                                  _c(
                                    "span",
                                    [
                                      data.id != "1"
                                        ? _c("el-button", {
                                            staticClass: "addBtn",
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                              circle: "",
                                              icon: "el-icon-circle-plus-outline",
                                              disabled: _vm.deptDlg_added(
                                                data.id
                                              ),
                                            },
                                            on: {
                                              click: function () {
                                                return _vm.deptDlg_add(data)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                {
                  staticStyle: {
                    height: "100%",
                    padding: "5px 10px",
                    "background-color": "#ffffff",
                    "margin-bottom": "0px",
                  },
                  attrs: { width: "40%" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.deptDlg.loading,
                          expression: "deptDlg.loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.deptDlg.searchList,
                        "max-height": "346px",
                        height: "346px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "title", label: "部门" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.deptpath(row.id))),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "title", label: "操作", width: "80px" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.id != "1"
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                          disabled: _vm.deptDlg_added(row.id),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deptDlg_add(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 添加 ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-aside",
                {
                  staticStyle: {
                    "border-left": "1px solid #eee",
                    height: "100%",
                    padding: "0",
                    "background-color": "#ffffff",
                  },
                  attrs: { width: "20%" },
                },
                [
                  _c(
                    "el-header",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "font-size": "12px",
                        height: "40px",
                        "line-height": "40px",
                        "border-bottom": "1px solid #eee",
                      },
                    },
                    [_vm._v(" 已选择 ")]
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { height: "356px", padding: "10px 0" } },
                    _vm._l(_vm.deptDlg.items, function (dept) {
                      return _c(
                        "el-tag",
                        {
                          key: dept.id,
                          staticStyle: {
                            margin: "10px 0 0 10px",
                            height: "auto",
                            "white-space": "normal",
                          },
                          attrs: { closable: "", size: "small" },
                          on: {
                            close: function ($event) {
                              return _vm.deptDlg_remove(dept)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(dept.deptName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDeptDlg(false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDeptDlg(true)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }