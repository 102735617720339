import request2 from '@/utils/request2'

// 赞币明细分页查询
export function getPage(data) {
  return request2({
    url: '/point/record/page-employee',
    method: 'post',
    data
  })
}


// 下拉列表查询
export function getOptionList(data) {
  return request2({
    url: '/award-event/option-list',
    method: 'post',
    data
  })
}

// 奖分信息明细分页查询
export function getPageAward(data) {
  return request2({
    url: '/point/record/page-award',
    method: 'post',
    data
  })
}

// 赞币明细记录变动分页查询
export function getPageChange(data) {
  return request2({
    url: '/point/record/page-change',
    method: 'post',
    data
  })
}