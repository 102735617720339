<script>
export default {
  name: 'table-base',
  props: {
    columns: {
      type: Array,
      default: ()=>[]
    },
    emptyText: {
      type: String,
      default: '暂无数据哦~'
    },
    tableProps: {
      type: Object,
      default: () => {
        return {

        }
      }
    },
    tableData: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {

    }
  },
  methods: {

  },
  render(createElement) {
    const { tableData, tableProps, columns, emptyText } = this._props
    const tablePropsOption = {
      props: {
        border: true,
        fit: true,
        highlightCurrentRow: true,
        defaultSort: {prop: 'idx', order: 'ascending'},
        ...tableProps
      }
    }
    return (
       <el-table
          class='table-wrap' 
          data={tableData}
          emptyText={emptyText}
          {...tablePropsOption}
        >
        {columns.length>0&&columns.map((column)=> {
          return column.render instanceof Function ? 
            (
              <el-table-column props={{...column}} scopedSlots={{
                default: props => column.render(props.row[column.prop],props,createElement)
              }}>
              </el-table-column>
            ) : 
            <el-table-column props={{...column}} />
        })}
      </el-table>
    )
  }
}
</script>

<style lang="less" scoped>
.table-wrap {
  /deep/ .el-table__empty-block {
    min-height: 300px;
  }
}
</style>