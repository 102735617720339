<template>
  <span>
    <slot v-if="hasPermission()"></slot>
  </span>
</template>  
    
  <script>
import { checkAuth } from "@/utils/auth";
export default {
  name: "AuthCheck",
  props: {
    permission: {
      type: String,
      required: true,
    },
  },
  computed: {
    // hasPermission() {
    //   // 在这里可以根据实际情况判断用户是否具有权限，这里只是一个示例
    //   // 假设有一个全局的`userPermissions`对象，其中包含了用户的权限信息
    //   // 你可以根据需要修改这个逻辑，以适应你的权限管理方式
    //   return false;
    // },
  },
  methods: {
    hasPermission() {
      // 在这里可以根据实际情况判断用户是否具有权限，这里只是一个示例
      // 假设有一个全局的`userPermissions`对象，其中包含了用户的权限信息
      // 你可以根据需要修改这个逻辑，以适应你的权限管理方式
      return checkAuth(this.permission);
    },
  },
};
</script>