<template>
  <gt-dialog
    v-model="dialogVisible"
    :width="width"
    :closeBtnText="closeBtnText"
    :title="title"
  >
    <el-form
      slot="body"
      class="form-wrap"
      ref="form"
      :model="data"
      :label-width="labelWidth"
    >
      <el-form-item
        label-width="120px"
        v-for="(formItem, index) in form"
        :key="index"
        :style="{ width: 100 / (formItem.column || 2) + '%' }"
        :label="formItem.label + '：'"
        :prop="formItem.prop"
      >
        <div class="file-wrap" v-if="formItem.type == 'file'">
          <div v-for="(item, index) in data[formItem.prop]" :key="index">
            <el-image
              v-if="item.attachmentType == 1"
              class="image"
              :src="getUrl(item.attachmentId)"
              :preview-src-list="[getUrl(item.attachmentId)]"
              fit="fill"
            ></el-image>
            <video-preview
              v-else
              class="image"
              :videoUrl="getUrl(item.attachmentId)"
              :thumburl="getUrl(item.attachmentId)"
            >
            </video-preview>
          </div>
        </div>

        <span v-else>{{
          formItem.render
            ? formItem.render(data[formItem.prop])
            : data[formItem.prop]
        }}</span>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button type="primary" @click="dialogVisible = false">{{
        closeBtnText
      }}</el-button>
    </template>
  </gt-dialog>
</template>

<script>
import GtDialog from "@/components/GtDialog";
import VideoPreview from "@/components/VideoPreview";
export default {
  name: "repeal-modal",
  components: {
    GtDialog,
    VideoPreview,
  },
  props: {
    //关闭弹窗按钮文案
    closeBtnText: {
      type: String,
      default: "关 闭",
    },
    //弹窗宽度
    width: {
      type: String,
      default: "56%",
    },
    //表单配置
    form: {
      type: Array,
      default: () => [],
    },
    //表单数据
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //弹窗标题
    title: {
      type: String,
      default: "提示",
    },
    //关闭弹窗前确认提示
    closeConfirm: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
  },
  data() {
    return {
      //弹出是否展示
      dialogVisible: false,
      //表单数据
      data: {},
    };
  },
  watch: {
    formData: {
      handler: function (val, oldVal) {
        this.$set(this, "data", val);
      },
      immediate: false,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    getUrl(attachmentId){
      return `${process.env.VUE_APP_BASE_URL}/api/v1/gtmsh-point/backend/attachment/file/download/nologin?attachmentId=${attachmentId}`;
    },
    //打开弹窗
    show() {
      this.$set(this, "dialogVisible", !this._data.dialogVisible);
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  /deep/ .el-dialog__header {
    padding: 16px 24px;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 650;
    }
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }

  .form-wrap {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-form-item {
      font-weight: normal;
    }
    .image {
      margin-right: 10px;
      width: 140px;
      height: 140px;
    }
  }

  .divider {
    margin: 0;
  }

  .file-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
