import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, menus) {
    return new Promise(resolve => {
      let accessedRoutes = []
      if (menus && menus.length > 0) {
        const { children } = menus[0]
        if (children && children.length > 0) {
          children.sort((a, b) => a.orderNum - b.orderNum);
          let arr = []
          let hidePage = asyncRoutes[0].children.filter((item) => {
            return item.hidden
          })
          children.forEach((item) => {
            let tempMenuKey = item.menuKey
            asyncRoutes[0].children.forEach((item2) => {
              const { menuKey } = item2
              if (menuKey && menuKey == tempMenuKey) {
                arr.push(item2)
              }
            })
          })
          arr.push(...hidePage)
          accessedRoutes = [{ ...asyncRoutes[0], children: arr }, { ...asyncRoutes[1] }]
        }
      } else {
        accessedRoutes = []
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
