/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout'

const integralRouter = {
  path: '/integral',
  component: Layout,
  redirect: 'noRedirect',
  name: 'integral',
  meta: {
    title: '圣恩赞币管理系统',
    icon: 'edit'
  },
  children: [
    {
      path: 'quotaManagement',
      component: () => import('@/views/quota/management'),
      name: 'quotaManagement',
      meta: { title: '赞币管理', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.point-pool'
    },
    {
      path: 'quotaAllot',
      component: () => import('@/views/quota/allot'),
      name: 'quotaAllot',
      meta: { title: '额度分配', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.quotaChange'
    },
    {
      path: 'integral-detail',
      component: () => import('@/views/integral/index'),
      name: 'integralDetail',
      meta: { title: '赞币明细', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.point'
    },
    {
      path: 'reasonlist',
      component: () => import('@/views/reason/list'),
      name: 'reason',
      meta: { title: '事件管理', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.event'
    },
    {
      path: 'awardquery',
      component: () => import('@/views/award/query'),
      name: 'awardquery',
      meta: { title: '点赞明细', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.award'
    },
    {
      path: 'quotaRepeal',
      component: () => import('@/views/quota/repeal'),
      name: 'quotaRepeal',
      meta: { title: '撤销点赞明细', noCache: true, activeMenu: '/integral/quotaRepeal', roles: ['admin'] },
      menuKey: 'point.backend.revoke'
    },
    {
      path: 'orderquery',
      component: () => import('@/views/order/query'),
      name: 'orderquery',
      meta: { title: '赞币排行', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.rankType'
    },
    {
      path: 'withdrawal',
      component: () => import('@/views/withdrawal/index'),
      name: 'withdrawalDetail',
      meta: { title: '提现管理', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.verification'
    },
    {
      path: 'dept-management',
      component: () => import('@/views/dept/management'),
      name: 'deptManagement',
      meta: { title: '组织架构', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.department'
    },
    {
      path: 'position-management',
      component: () => import('@/views/position/management'),
      name: 'positionManagement',
      meta: { title: '职位管理', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.employeePosition'
    },
    {
      path: 'employee-management',
      component: () => import('@/views/employee/management'),
      name: 'employeeManagement',
      meta: { title: '员工管理', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.employee'
    },
    {
      path: 'role-management',
      component: () => import('@/views/role/management'),
      name: 'roleManagement',
      meta: { title: '角色管理', noCache: true, roles: ['admin'] },
      menuKey: 'point.backend.role'
    },
    {
      path: 'reasonadd',
      component: () => import('@/views/reason/add'),
      name: 'reasonadd',
      meta: { title: '添加事件', noCache: true, activeMenu: '/integral/reasonlist', roles: ['admin'] },
      hidden: true
    },
    {
      path: 'reasonedit',
      component: () => import('@/views/reason/edit'),
      name: 'reasonedit',
      meta: { title: '编辑事件', noCache: true, activeMenu: '/integral/reasonlist', roles: ['admin'] },
      hidden: true
    },
    {
      path: 'role-add',
      component: () => import('@/views/role/details'),
      name: 'roleAdd',
      meta: { title: '新增角色', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'role-edit',
      component: () => import('@/views/role/details'),
      name: 'roleEdit',
      meta: { title: '编辑角色', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'role-view',
      component: () => import('@/views/role/details'),
      name: 'roleView',
      meta: { title: '查看角色', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'employee-add',
      component: () => import('@/views/employee/details'),
      name: 'employeeAdd',
      meta: { title: '新增员工', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'employee-edit',
      component: () => import('@/views/employee/details'),
      name: 'employeeEdit',
      meta: { title: '编辑员工', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'employee-view',
      component: () => import('@/views/employee/details'),
      name: 'employeeView',
      meta: { title: '查看员工', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'dept-add',
      component: () => import('@/views/dept/details'),
      name: 'deptAdd',
      meta: { title: '新增部门', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'dept-edit',
      component: () => import('@/views/dept/details'),
      name: 'deptEdit',
      meta: { title: '编辑部门', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'dept-view',
      component: () => import('@/views/dept/details'),
      name: 'deptView',
      meta: { title: '查看部门', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'position-add',
      component: () => import('@/views/position/details'),
      name: 'positionAdd',
      meta: { title: '新增职位', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'position-edit',
      component: () => import('@/views/position/details'),
      name: 'positionEdit',
      meta: { title: '编辑职位', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'position-view',
      component: () => import('@/views/position/details'),
      name: 'positionView',
      meta: { title: '查看职位', noCache: true, roles: ['admin'] },
      hidden: true
    },
    {
      path: 'quotaAllotDetail',
      component: () => import('@/views/quota/allot/detail'),
      name: 'quotaAllotDetail',
      meta: { title: '额度分配', noCache: true, activeMenu: '/integral/quotaAllot', roles: ['admin'] },
      hidden: true
    },
  ]
}

export default integralRouter
