var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "box-card-component",
      staticStyle: { "margin-left": "8px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "box-card-header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("img", {
            attrs: {
              src: "https://wpimg.wallstcn.com/e7d23d71-cf19-4b90-a1cc-f56af8c0903d.png",
            },
          }),
        ]
      ),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("pan-thumb", {
            staticClass: "panThumb",
            attrs: { image: _vm.info.logo.url },
          }),
          _c("mallki", {
            attrs: { "class-name": "mallki-text", text: "vue-element-admin" },
          }),
          _c(
            "div",
            {
              staticClass: "progress-item",
              staticStyle: { "padding-top": "35px" },
            },
            [
              _c("span", [_vm._v("Vue")]),
              _c("el-progress", { attrs: { percentage: 70 } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("JavaScript")]),
              _c("el-progress", { attrs: { percentage: 18 } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("CSS")]),
              _c("el-progress", { attrs: { percentage: 12 } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("ESLint")]),
              _c("el-progress", {
                attrs: { percentage: 100, status: "success" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }