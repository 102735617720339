import { render, staticRenderFns } from "./logouted.vue?vue&type=template&id=5cba080c&scoped=true"
import script from "./logouted.vue?vue&type=script&lang=js"
export * from "./logouted.vue?vue&type=script&lang=js"
import style0 from "./logouted.vue?vue&type=style&index=0&id=5cba080c&scoped=true&lang=css"
import style1 from "./logouted.vue?vue&type=style&index=1&id=5cba080c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cba080c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/gtmsh/server/jenkins/home/workspace/预发布环境/frontend/saas-point-bk/saas-point-bk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5cba080c')) {
      api.createRecord('5cba080c', component.options)
    } else {
      api.reload('5cba080c', component.options)
    }
    module.hot.accept("./logouted.vue?vue&type=template&id=5cba080c&scoped=true", function () {
      api.rerender('5cba080c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/error-page/logouted.vue"
export default component.exports