var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-base-page", {
    ref: "basePage",
    attrs: {
      columns: _vm.columns,
      searchFileds: _vm.searchFileds,
      setTableBtn: _vm.setTableBtn,
      loadTableData: _vm.loadTableData,
      exportTableData: _vm.exportTableData,
      buttons: _vm.buttons,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }