<template>
  <div class="app-container">
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="dataForm"
      label-position="right"
      label-width="120px"
      style="min-width: 560px; margin-left: 30px; margin-top: 30px; width: 90%"
    >
      <form-input
        laeblName="部门名称"
        keyStr="deptName"
        :required="true"
        @updateInputData="updateInputData"
        :inputData="dataForm.deptName"
        :disabled="type == 'view'"
      ></form-input>
      <form-input
        laeblName="部门简称"
        keyStr="deptAlias"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.deptAlias"
        :disabled="type == 'view'"
      ></form-input>
      <form-input
        laeblName="部门编码"
        keyStr="deptCode"
        :required="true"
        @updateInputData="updateInputData"
        :inputData="dataForm.deptCode"
        :disabled="type != 'add'"
      ></form-input>
      <el-form-item
        label="父级部门"
        prop="parentDeptName"
        v-if="dataForm.id != 1"
      >
        <el-button
          :disabled="type == 'view'"
          @click="openDeptDlg('senddis')"
          style="
            padding: 0px 6px 0px 3px;
            width: 400px;
            min-height: 28px;
            vertical-align: middle;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="white-space: normal; text-align: left">
              <el-tag
                v-if="dataForm.parentDeptName"
                size="mini"
                style="margin: 3px; white-space: normal; height: auto"
                >{{ dataForm.parentDeptName }}</el-tag
              >
            </div>
            <i
              class="el-icon-collection-tag"
              style="font-size: 16px; color: #dcdfe6"
            ></i>
          </div>
        </el-button>
        <el-input v-show="false" v-model="dataForm.parentDeptName" />
      </el-form-item>

      <el-form-item style="margin-top: 50px" v-if="type != 'view'">
        <el-button
          type="primary"
          @click="save()"
          size="medium"
          style="margin-right: 50px"
        >
          保&nbsp;存
        </el-button>
        <el-button size="medium" @click="cancel()" v-if="type != 'view'">
          取&nbsp;消
        </el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      title="设置部门"
      :visible.sync="deptDlg.visible"
      width="80%"
      top="calc( ( 100vh - 550px ) / 2 )"
      class="dlg_items"
    >
      <el-container style="height: 400px; border: 1px solid #eee">
        <el-aside
          width="40%"
          style="
            border-right: 1px solid #eee;
            height: 100%;
            padding: 0;
            background-color: #ffffff;
            margin-bottom: 0px;
          "
        >
          <el-header
            style="
              text-align: right;
              font-size: 12px;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #eee;
            "
          >
            <el-popover
              placement="top"
              width="200px"
              trigger="manual"
              content="请输入关键词"
              v-model="deptDlg.searchkeyTipShow"
            >
              <el-input
                slot="reference"
                placeholder="请输入关键字"
                clearable
                v-model="deptDlg.searchkey"
                @keydown.enter.native="deptDlg_query"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="deptDlg_query"
                ></el-button>
              </el-input>
            </el-popover>
          </el-header>
          <el-main style="height: 309px">
            <el-tree
              :data="deptData.tree"
              node-key="id"
              :props="{ children: 'children', label: 'deptName' }"
              :expand-on-click-node="false"
              :default-expanded-keys="['1']"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    v-if="data.id != '1'"
                    class="addBtn"
                    type="text"
                    size="mini"
                    circle
                    icon="el-icon-circle-plus-outline"
                    @click="() => deptDlg_add(data)"
                    :disabled="deptDlg_added(data.id)"
                  >
                  </el-button>
                </span>
              </span>
            </el-tree>
          </el-main>
        </el-aside>
        <el-aside
          width="40%"
          style="
            height: 100%;
            padding: 5px 10px;
            background-color: #ffffff;
            margin-bottom: 0px;
          "
        >
          <el-table
            :data="deptDlg.searchList"
            v-loading="deptDlg.loading"
            style="width: 100%"
            max-height="346px"
            height="346px"
          >
            <el-table-column prop="title" label="部门">
              <template slot-scope="{ row }">
                <span>{{ deptpath(row.id) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="操作" width="80px">
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.id != '1'"
                  type="primary"
                  size="mini"
                  :disabled="deptDlg_added(row.id)"
                  @click="deptDlg_add(row)"
                >
                  添加
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-aside>
        <el-aside
          width="20%"
          style="
            border-left: 1px solid #eee;
            height: 100%;
            padding: 0;
            background-color: #ffffff;
          "
        >
          <el-header
            style="
              text-align: center;
              font-size: 12px;
              height: 40px;
              line-height: 40px;
              border-bottom: 1px solid #eee;
            "
          >
            已选择
          </el-header>
          <el-main style="height: 356px; padding: 10px 0">
            <el-tag
              :key="dept.id"
              v-for="dept in deptDlg.items"
              style="margin: 10px 0 0 10px; height: auto; white-space: normal"
              closable
              size="small"
              @close="deptDlg_remove(dept)"
            >
              {{ dept.deptName }}
            </el-tag>
          </el-main>
        </el-aside>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDeptDlg(false)">取 消</el-button>
        <el-button type="primary" @click="closeDeptDlg(true)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addDept,
  editDept,
  getDeptInfo,
  allDept,
  getDeptByKw,
} from "@/api/user";

import FormInput from "@/components/FormInput";
const defaultForm = {
  deptAlias: "",
  deptCode: "",
  deptName: "",
  parentDeptId: "",
  parentDeptName: "",
  abc: "",
};
const defaultDeptDlgData = {
  visible: false,
  items: [],
  searchkey: "",
  searchkeyTipShow: false,
  loading: false,
  searchList: [],
};

export default {
  name: "RoleDetail",
  components: { FormInput },
  // props: {
  //   type: {
  //     type: String,
  //     default: false
  //   }
  // },
  data() {
    return {
      dataForm: Object.assign({}, defaultForm),
      loading: false,
      rules: {
        deptName: [
          {
            required: true,
            message: "部门名称为必填项",
            trigger: "blur",
            trim: true,
          },
          {
            trim: true,
            min: 1,
            max: 10,
            message: "部门名称最多10个字",
            trigger: "blur",
          },
        ],
        deptCode: [
          { required: true, message: "部门编码为必填项", trigger: "blur" },
        ],
        parentDeptName: [
          { required: true, message: "父级部门为必选项", trigger: "blur" },
        ],
      },
      tempRoute: {},
      type: "",
      deptDlg: Object.assign({}, defaultDeptDlgData),
      depts_senddis: [],
      depts_receivedis: [],
      deptData: {
        tree: [],
        map: new Map(),
      },
      options: [],
    };
  },
  computed: {
    deptpath() {
      return (id) => {
        let path = "";
        while (true) {
          if (!id) break;
          if (id == "1" && path != "") break;
          let dept = this.deptData.map.get(id);
          if (!dept) break;
          if (path != "") path = "\\" + path;
          path = dept.deptName + path;
          id = dept.parentDeptId;
        }
        return path;
      };
    },
    deptDlg_added() {
      return (id) => {
        for (let i = 0; i < this.deptDlg.items.length; ++i) {
          if (this.deptDlg.items[i].id == id) return true;
        }
        return false;
      };
    },
  },
  created() {
    this.loadAllDeptData();
    const { deptId } = this.$route.query;
    const { path } = this.$route;
    let title = "";
    if (path.endsWith("view")) {
      title = "查看部门";
      this.type = "view";
      this.dataForm.deptId = deptId;
      this.dataForm.id = deptId;
      this.loadDeptData(deptId);
    } else if (path.endsWith("edit")) {
      title = "编辑部门";
      this.type = "edit";
      this.dataForm.deptId = deptId;
      this.dataForm.id = deptId;
      this.loadDeptData(deptId);
    } else {
      title = "新增部门";
      this.type = "add";
    }
    this.tempRoute = Object.assign({}, this.$route);
    this.setTagsViewTitle(title);
    this.setPageTitle(title + " - 圣恩赞币系统后台");
  },
  methods: {
    updateInputData(data) {
      const { keyStr, value } = data;
      this.dataForm[keyStr] = value;
    },
    loadAllDeptData() {
      allDept({})
        .then((response) => {
          let deptArray = response.data;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.id, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (
              item.parentDeptId &&
              item.parentDeptId != "" &&
              item.parentDeptId != "0"
            ) {
              parent = deptMap.get(item.parentDeptId);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
          this.getList();
        })
        .catch((err) => {});
    },

    openDeptDlg(type) {
      this.deptDlg = Object.assign({}, defaultDeptDlgData, {
        visible: true,
        items: type == "senddis" ? this.depts_senddis : this.depts_receivedis,
        type,
      });
    },
    closeDeptDlg(save) {
      this.deptDlg.visible = false;
      if (save) {
        let ids = "";
        this.deptDlg.items.forEach((item, i) => {
          if (i != 0) ids += ",";
          ids += item.id;
        });
        if (this.deptDlg.type == "senddis") {
          this.depts_senddis = this.deptDlg.items;
        } else {
          this.depts_receivedis = this.deptDlg.items;
        }
      }
    },
    deptDlg_query() {
      if (!this.deptDlg.searchkey) {
        this.deptDlg.searchkeyTipShow = true;
        setTimeout(() => {
          this.deptDlg.searchkeyTipShow = false;
        }, 2000);
        return;
      }
      this.deptDlg.loading = true;
      getDeptByKw({ deptName: this.deptDlg.searchkey, deptId: 1 })
        .then((response) => {
          this.deptDlg.searchList = response.data;
          this.deptDlg.loading = false;
        })
        .catch((err) => {
          this.deptDlg.loading = false;
        });
    },
    deptDlg_add(dept) {
      if (this.deptDlg.items && this.deptDlg.items.length >= 1) {
        return;
      }
      this.dataForm.parentDeptName = dept.deptName;
      this.dataForm.parentDeptId = dept.id;
      this.deptDlg.items.push(dept);
    },
    deptDlg_remove(dept) {
      for (let i = 0; i < this.deptDlg.items.length; ++i) {
        if (this.deptDlg.items[i].id == dept.id) {
          this.deptDlg.items.splice(i, 1);
          return;
        }
      }
    },

    loadDeptData(id) {
      getDeptInfo({ id })
        .then((response) => {
          this.dataForm.deptName = response.data.deptName;
          this.dataForm.deptAlias = response.data.deptAlias;
          this.dataForm.deptCode = response.data.deptCode;
          this.dataForm.parentDeptId = response.data.parentDeptId;
          this.dataForm.parentDeptName = response.data.parentDeptName;
          this.depts_senddis = [
            {
              id: response.data.parentDeptId,
              deptName: response.data.parentDeptName,
            },
          ];
        })
        .catch((err) => {});
    },
    setTagsViewTitle(title) {
      const route = Object.assign({}, this.tempRoute, {
        title,
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    },
    setPageTitle(title) {
      document.title = title;
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$route).then(() => {
        this.$router.replace({ path: "/integral/dept-management" });
      });
    },
    save() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.type == "edit") {
            editDept(this.dataForm).then((response) => {
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/dept-management" });
              });
            });
          } else {
            addDept(this.dataForm).then((response) => {
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/dept-management" });
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.input_error {
  border-color: #ff4949;
}
</style>
<style lang="scss">
.dlg_managers .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.dlg_visibles .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.el-tree {
  display: inline-block;
  min-width: 100%;
}
.dlg_managers .el-dialog {
  min-width: 660px;
}
.dlg_visibles .el-dialog {
  min-width: 660px;
}
.el-tabs__nav-scroll {
  padding-left: 50px;
}
.el-tabs__header {
  margin-bottom: 0px;
}
.addBtn .el-icon-circle-plus-outline {
  font-size: 16px;
}
</style>
