<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="query.centerCode"
        placeholder="全部"
        style="width: 230px"
        class="filter-item"
      >
        <el-option label="全部" value="" class="filter-item" />
        <el-option
          v-for="dept in deptData"
          :key="dept.deptCode"
          :label="dept.deptName"
          :value="dept.deptCode"
          class="filter-item"
        />
      </el-select>
      <el-select
        v-model="query.statementType"
        placeholder="时间范围"
        style="width: 230px"
        class="filter-item"
      >
        <el-option label="累计" value="3" class="filter-item" />
        <el-option label="本周" value="0" class="filter-item" />
        <el-option label="本月" value="1" class="filter-item" />
        <el-option label="本年" value="2" class="filter-item" />
        <el-option label="特定时间范围" value="4" class="filter-item" />
      </el-select>
      <el-date-picker
        v-show="query.statementType == '4'"
        v-model="query.dayrange[0]"
        type="date"
        style="width: 140px"
        placeholder="赞币开始日期"
        class="filter-item"
      ></el-date-picker>
      <el-date-picker
        v-show="query.statementType == '4'"
        v-model="query.dayrange[1]"
        type="date"
        style="width: 140px"
        placeholder="赞币结束日期"
        class="filter-item"
      ></el-date-picker>
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 20px"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        查询
      </el-button>
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 20px"
        icon="el-icon-refresh-right"
        @click="handleReset"
      >
        重置
      </el-button>
      <AuthCheck permission="gtmsh-point-server.point.rankType.export">
        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item"
          style="margin-left: 20px"
          @click="exportExcel"
        >
          导出
        </el-button>
      </AuthCheck>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        label="排名"
        prop="ranking"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="员工姓名"
        prop="employeeName"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="账号"
        prop="employeeAccount"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="部门"
        prop="deptName"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="职务"
        prop="position"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="等级"
        prop="levelValue"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <!-- <el-table-column label="等级" align="center" min-width="80px">
        <template slot-scope="{row}">
            {{row.level.level}}
        </template>
      </el-table-column> -->
      <el-table-column
        label="时间段内赞币"
        prop="withinTimePointNum"
        align="center"
        min-width="80px"
      >
      </el-table-column>
      <el-table-column
        label="当前总赞币"
        prop="totalPointNum"
        align="center"
        min-width="80px"
      >
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.pageNum"
      :limit.sync="query.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { allDept, pagescoreorder, getDeptByKw } from "@/api/user";
import waves from "@/directive/waves";
import Pagination from "@/components/Pagination";
import moment from "moment";
import download from "@/utils/download";
import AuthCheck from "@/components/AuthCheck";

export default {
  name: "AwardList",
  components: { Pagination, AuthCheck },
  filters: {},
  directives: { waves },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: false,
      reasons: [],
      query: {
        centerCode: "",
        statementType: "3",
        dayrange: [null, null],
        pageNum: 1,
        pageSize: 20,
      },
      deptData: [],
      exportRun: false,
    };
  },
  computed: {},
  created() {
    this.loadDeptData();
    this.getList();
  },
  methods: {
    deptpath(deptid) {},
    getList() {
      // this.lastquery.deptid=this.query.deptid;
      // this.lastquery.range=this.query.range;
      // this.lastquery.dayrange=[this.query.dayrange[0],this.query.dayrange[1]];
      // this.lastquery.pageNum=1;
      // this.lastquery.pageSize=20;
      // this.lastquery.maxid=0;

      let params = { ...this.query };
      const { dayrange, statementType } = this.query;
      if (statementType == 4) {
        if (dayrange && dayrange.length == 2) {
          let isChoose = false;
          if (dayrange[0]) {
            params.startTime = moment(dayrange[0]).format(
              "YYYY-MM-DD 00:00:00"
            );
            isChoose = true;
          }
          if (dayrange[1]) {
            params.endTime = moment(dayrange[1]).format("YYYY-MM-DD 23:59:59");
            isChoose = true;
          }
          if (dayrange[0] && dayrange[1]) {
            if (moment(params.endTime).isBefore(moment(params.startTime))) {
              this.$message({
                type: "error",
                message: "结束时间不能早于开始时间",
              });
              return;
            }
          }
          if (!isChoose) {
            this.$message({
              message: "请选择时间范围",
              type: "error",
            });
            return;
          }
        }
      }
      delete params.dayrange;
      this.listLoading = true;
      // const {pageNum,pageSize}=this.query
      pagescoreorder(params)
        .then((response) => {
          this.list = response.data.list;
          this.total = response.data.total;
          this.listLoading = false;
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
    loadDeptData() {
      getDeptByKw({ deptLevel: 1 })
        .then((response) => {
          this.deptData = response.data;
        })
        .catch((err) => {});
    },
    handleReset() {
      this.query = {
        centerCode: "",
        statementType: "3",
        dayrange: [null, null],
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },
    handleFilter() {
      this.query.pageNum = 1;
      this.getList();
    },
    resetFilter() {
      this.query = {
        pageNum: 1,
        pageSize: this.query.pageSize,
        deptid: null,
        range: null,
        dayrange: [null, null],
      };
      this.getList();
    },
    showInfo() {},
    exportExcel() {
      let params = { ...this.query };
      const { dayrange, statementType } = this.query;
      if (statementType == 4) {
        if (dayrange && dayrange.length == 2) {
          let isChoose = false;
          if (dayrange[0]) {
            params.startTime = moment(dayrange[0]).format(
              "YYYY-MM-DD 00:00:00"
            );
            isChoose = true;
          }
          if (dayrange[1]) {
            params.endTime = moment(dayrange[1]).format("YYYY-MM-DD 23:59:59");
            isChoose = true;
          }
          if (dayrange[0] && dayrange[1]) {
            if (moment(params.endTime).isBefore(moment(params.startTime))) {
              this.$message({
                type: "error",
                message: "结束时间不能早于开始时间",
              });
              return;
            }
          }
          if (!isChoose) {
            this.$message({
              message: "请选择时间范围",
              type: "error",
            });
            return;
          }
        }
      }
      delete params.dayrange;
      download({
        method: "post",
        url: "/backend/point-rank/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "赞币排行-导出.xlsx",
        data: params,
      });
    },
  },
};
</script>

<style scoped>
.filter-item {
  margin-right: 10px;
}
</style>
