<!--
 * @Author: 陈红玲 
 * @Date: 2021-12-24 11:48:56 
 * @Last Modified by:   陈红玲 
 * @Last Modified time: 2021-12-24 11:48:56 
-->
<template>
  <el-form
    :model="formFields"
    :rules="formRules"
    ref="submitForm"
    :label-width="labelWidth"
  >
    <el-form-item label="登录人所属中心:" prop="centerName" class="content">
      <span>{{ centerName }}</span>
    </el-form-item>
    <el-form-item label="登录人所属部门:" prop="deptName" class="content">
      <span>{{ deptName }}</span>
    </el-form-item>
    <el-form-item
      label="登录人所属中心的剩余分:"
      prop="poolRemainNum"
      class="content"
    >
      <span>{{ poolRemainNum }}</span>
    </el-form-item>
    <el-form-item label="额度:" prop="quota" class="content">
      <el-input
        v-model="formFields.quota"
        clearable
        placeholder="请输入赞币数量"
      />
    </el-form-item>
    <el-form-item label="接收人:" prop="userid" class="content">
      <select-user
        clearable
        v-model="formFields.userid"
        placeholder="点按钮添加接收人"
      ></select-user>
    </el-form-item>
    <!-- 按钮组 -->
    <el-form-item class="btn-wrap">
      <el-button v-loading="loading" type="primary" @click="handleSubmit"
        >确认</el-button
      >
      <el-button @click="goBack()">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import { saveQuota, getQuotaInfo } from "@/api/quotaallot";
import SelectUser from "@/components/SelectUser";

export default {
  name: "QuotaAllotList",
  filters: {},
  directives: { waves },
  components: {
    SelectUser,
  },
  data() {
    return {
      formFields: {},
      formRules: {
        quota: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (!value) {
                callback(new Error("额度不能为空"));
              }
              if (/^(0|[1-9]\d*)$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
          },
        ],
        userid: [
          {
            required: true,
            message: "接收人不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
      labelWidth: "200px",
      loading: false,
      centerName: "",
      deptName: "",
      poolRemainNum: "",
    };
  },
  computed: {
    quotatypename() {
      return (quotatype) => {
        if (quotatype == "1") return "全员";
        if (quotatype == "2") return "特定用户";
        if (quotatype == "3") return "特定部门";
        if (quotatype == "4") return "特定职级";
        return "";
      };
    },
  },
  created() {
    this.requestQuota();
  },
  methods: {
    async requestQuota() {
      const data = await getQuotaInfo();
      if (data.code == 200 && data.data) {
        const { centerName, deptName, poolRemainNum } = data.data;
        this.centerName = centerName || "--";
        this.deptName = deptName || "--";
        this.poolRemainNum = poolRemainNum;
      } else {
        this.centerName = "--";
        this.deptName = "--";
        this.poolRemainNum = "--";
      }
    },
    //取消返回上级页面
    goBack() {
      this.$store.dispatch("tagsView/delView", this.$route).then(() => {
        this.$router.replace({ path: "/integral/quotaAllot" });
      });
    },
    //表单提交
    handleSubmit() {
      const { formFields } = this._data;
      this.$set(this, "loading", true);
      this.$refs.submitForm.validate(async (validateResult, errObject) => {
        if (validateResult) {
          this.submitForm(formFields);
        }
        this.$set(this, "loading", false);
      });
    },
    //表单提交
    async submitForm(params) {
      const tempParams = {
        quota: params.quota,
        toEmployeeIdList: params.userid,
      };
      const data = await saveQuota({ ...tempParams });
      if (data.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.goBack();
      }
    },
  },
};
</script>

<style scoped>
.content {
  width: 600px;
}
.btn-wrap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
</style>
