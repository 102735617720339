var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gt-dialog",
    {
      attrs: {
        width: _vm.width,
        closeBtnText: _vm.closeBtnText,
        title: _vm.title,
      },
      model: {
        value: _vm.dialogVisible,
        callback: function ($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-wrap",
          attrs: {
            slot: "body",
            model: _vm.data,
            "label-width": _vm.labelWidth,
          },
          slot: "body",
        },
        _vm._l(_vm.form, function (formItem, index) {
          return _c(
            "el-form-item",
            {
              key: index,
              style: { width: 100 / (formItem.column || 2) + "%" },
              attrs: {
                "label-width": "120px",
                label: formItem.label + "：",
                prop: formItem.prop,
              },
            },
            [
              formItem.type == "file"
                ? _c(
                    "div",
                    { staticClass: "file-wrap" },
                    _vm._l(_vm.data[formItem.prop], function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          item.attachmentType == 1
                            ? _c("el-image", {
                                staticClass: "image",
                                attrs: {
                                  src: _vm.getUrl(item.attachmentId),
                                  "preview-src-list": [
                                    _vm.getUrl(item.attachmentId),
                                  ],
                                  fit: "fill",
                                },
                              })
                            : _c("video-preview", {
                                staticClass: "image",
                                attrs: {
                                  videoUrl: _vm.getUrl(item.attachmentId),
                                  thumburl: _vm.getUrl(item.attachmentId),
                                },
                              }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        formItem.render
                          ? formItem.render(_vm.data[formItem.prop])
                          : _vm.data[formItem.prop]
                      )
                    ),
                  ]),
            ]
          )
        }),
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.closeBtnText))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }