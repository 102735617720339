var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: {
            "min-width": "560px",
            "margin-left": "30px",
            "margin-top": "30px",
            width: "90%",
          },
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c("form-input", {
            attrs: {
              laeblName: "事件名称",
              keyStr: "eventName",
              required: true,
              inputData: _vm.dataForm.eventName,
              maxlength: 4,
            },
            on: { updateInputData: _vm.updateInputData },
          }),
          _c("form-input", {
            attrs: {
              laeblName: "事件描述",
              keyStr: "eventDesc",
              required: false,
              inputData: _vm.dataForm.eventDesc,
            },
            on: { updateInputData: _vm.updateInputData },
          }),
          _c(
            "el-form-item",
            { attrs: { label: "最低点赞额度", prop: "eventMinScore" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                model: {
                  value: _vm.dataForm.eventMinScore,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "eventMinScore", _vm._n($$v))
                  },
                  expression: "dataForm.eventMinScore",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "最高点赞额度", prop: "eventMaxScore" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                model: {
                  value: _vm.dataForm.eventMaxScore,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "eventMaxScore", _vm._n($$v))
                  },
                  expression: "dataForm.eventMaxScore",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "事件状态", prop: "eventStatus" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px" },
                  model: {
                    value: _vm.dataForm.eventStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "eventStatus", $$v)
                    },
                    expression: "dataForm.eventStatus",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "启用", value: 1 } }),
                  _c("el-option", { attrs: { label: "禁用", value: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { "margin-top": "50px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "50px" },
                  attrs: { type: "primary", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" 保 存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }