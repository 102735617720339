<template>
  <div>
    <table-base-page
      :columns="columns"
      :searchFileds="searchFileds"
      :searchBtnText="searchBtnText"
      :exportService="exportService"
      :buttons="buttons"
      :loadTableData="loadTableData"
      :exportTableData="exportTableData"
      ref="basePage"
    ></table-base-page>
    <repeal-modal
      ref="repealModal"
      title="点赞信息详情"
      :buttons="['close']"
      :form="form"
      :formData="detail"
      :dialogVisible="true"
    ></repeal-modal>
  </div>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import TableBasePage from "@/components/TableBasePage";
import {
  quotaRepealList,
  quotaRepealDetail,
  exportRepealList,
} from "@/api/quota";
import RepealModal from "../components/RepealModal";
import moment from "moment";
import { checkAuth } from "@/utils/auth";

export default {
  name: "QuotaAllotList",
  filters: {},
  directives: { waves },
  components: {
    TableBasePage,
    RepealModal,
  },
  data() {
    return {
      exportService: "pool/page-pool/export", //导出接口名
      searchBtnText: "查询", //查询按钮的文案
      buttons: ["search", "reset"], //表格展示按钮组
      columns: [
        {
          label: "被点赞人",
          prop: "toEmployeeName",
          align: "center",
        },
        {
          label: "账号",
          prop: "toEmployeeAccount",
          align: "center",
        },
        {
          label: "部门",
          prop: "toDeptName",
          align: "center",
        },
        {
          label: "职务",
          prop: "toPositionDictName",
          align: "center",
        },
        {
          label: "撤销赞币",
          prop: "toChangeNum",
          align: "center",
        },
        {
          label: "撤销理由",
          prop: "revokeReason",
          align: "center",
        },
        {
          label: "撤销人",
          prop: "fromEmployeeName",
          align: "center",
        },
        {
          label: "日期",
          prop: "operateTime",
          align: "center",
          type: "date",
          render: (createdAt, scope, h) => {
            return createdAt
              ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss")
              : "---";
          },
        },
        {
          label: "操作",
          prop: "operate",
          align: "center",
          render: (createdAt, scope, h) => {
            return (
              <el-button
                type="text"
                on={{ click: () => this.toDetail(scope.row) }}
              >
                详情
              </el-button>
            );
          },
        },
      ],
      searchFileds: [
        {
          label: "被点赞人",
          prop: "toEmployeeName",
          type: "input",
        },
        {
          label: "撤销人：",
          prop: "fromEmployeeName",
          type: "input",
        },
        {
          label: "日期：",
          prop: ["startTime", "endTime"],
          initialValue: [
            moment(moment().add(-1, "months").format("YYYY-MM-DD")),
            moment(moment().format("YYYY-MM-DD"))
              .add(23, "hours")
              .add(59, "minutes")
              .add(59, "seconds"),
          ],
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间",
          rangeSeparator: "至",
          type: "daterange",
        },
      ],
      form: [
        {
          label: "被点赞人",
          prop: "toEmployeeName",
        },
        {
          label: "被点赞人账号",
          prop: "toEmployeeAccount",
        },
        {
          label: "被点赞人部门",
          prop: "toDeptName",
        },
        {
          label: "被点赞人职务",
          prop: "toPositionDictName",
        },
        {
          label: "事件名称",
          prop: "eventName",
        },
        {
          label: "获得赞币",
          prop: "awardNum",
        },
        {
          label: "点赞人",
          prop: "fromEmployeeName",
        },
        {
          label: "操作时间",
          prop: "createdAt",
          render: (createdAt) => {
            return createdAt
              ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss")
              : "---";
          },
        },
        {
          label: "点赞事由",
          prop: "awardContent",
          column: 1,
        },
        {
          label: "附件",
          prop: "awardFileUrlList",
          type: "file",
          column: 1,
        },
      ],
      detail: {},
    };
  },
  computed: {
    quotatypename() {
      return (quotatype) => {
        if (quotatype == "1") return "全员";
        if (quotatype == "2") return "特定用户";
        if (quotatype == "3") return "特定部门";
        if (quotatype == "4") return "特定职级";
        return "";
      };
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event) {
        const { keyCode } = event;
        if (keyCode && keyCode == 13) {
          this.$refs.basePage.initTableData();
        }
      }
    },
    toDetail(row) {
      const { awardId } = row;
      quotaRepealDetail({ awardId }).then((rsp) => {
        // row.files = rsp.data
        this.$set(this, "detail", rsp.data);
        this.$refs.repealModal.show();
      });
    },
    exportTableData(params) {
      const { name, operator, startTime, endTime } = params;
      const query = {
        ...params,
        name: name?.trim(),
        operator: operator?.trim(),
        startTime:
          startTime instanceof moment
            ? startTime.format("YYYY-MM-DD HH:mm:ss")
            : moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
        endTime:
          endTime instanceof moment
            ? endTime.format("YYYY-MM-DD HH:mm:ss")
            : moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
      };
      return new Promise((resolve, reject) => {
        exportRepealList(query).then((res) => {});
      });
    },
    loadTableData(params) {
      const { name, operator, startTime, endTime } = params;
      const query = {
        ...params,
        name: name?.trim(),
        operator: operator?.trim(),
        startTime:
          startTime instanceof moment
            ? startTime.format("YYYY-MM-DD HH:mm:ss")
            : moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
        endTime:
          endTime instanceof moment
            ? endTime.format("YYYY-MM-DD HH:mm:ss")
            : moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
      };
      return new Promise((resolve, reject) => {
        quotaRepealList(query).then((res) => {
          resolve(res.data);
        });
      });
    },
  },
};
</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
