<template>
  <div class="app-container">
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="dataForm"
      label-position="right"
      label-width="120px"
      style="min-width: 560px; margin-left: 30px; margin-top: 30px; width: 90%"
    >
      <form-input
        laeblName="事件名称"
        keyStr="eventName"
        :required="true"
        @updateInputData="updateInputData"
        :inputData="dataForm.eventName"
        :maxlength="4"
      ></form-input>
      <form-input
        laeblName="事件描述"
        keyStr="eventDesc"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.eventDesc"
      ></form-input>
      <el-form-item label="最低点赞额度" prop="eventMinScore">
        <el-input
          v-model.number="dataForm.eventMinScore"
          style="width: 100px"
        />
      </el-form-item>
      <el-form-item label="最高点赞额度" prop="eventMaxScore">
        <el-input
          v-model.number="dataForm.eventMaxScore"
          style="width: 100px"
        />
      </el-form-item>
      <el-form-item label="事件状态" prop="eventStatus">
        <el-select v-model="dataForm.eventStatus" style="width: 100px">
          <el-option label="启用" :value="1" />
          <el-option label="禁用" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-top: 50px">
        <el-button
          type="primary"
          @click="save()"
          size="medium"
          style="margin-right: 50px"
        >
          保&nbsp;存
        </el-button>
        <el-button size="medium" @click="cancel()"> 取&nbsp;消 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getreason, addreason, updatereason } from "@/api/reason";
import FormInput from "@/components/FormInput";

const defaultForm = {
  eventName: "",
  eventDesc: "",
  eventStatus: 1,
  eventMinScore: "",
  eventMaxScore: "",
};

export default {
  name: "ReasonDetail",
  components: { FormInput },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataForm: Object.assign({}, defaultForm),
      loading: false,
      rules: {
        eventName: [
          { required: true, message: "事件名称为必填项", trigger: "blur" },
        ],
        eventMinScore: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (!value) {
                callback(new Error("最低点赞额度不能为空"));
              }
              if (/^[1-9]\d*$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
          },
        ],
        eventMaxScore: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (!value) {
                callback(new Error("最高点赞额度不能为空"));
              }
              if (/^[1-9]\d*$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入正整数"));
              }
            },
          },
        ],
      },
      tempRoute: {},
    };
  },
  computed: {},
  created() {
    if (this.isEdit) {
      const id = this.$route.query.id;
      this.loadReasonData(id);
    }
    this.tempRoute = Object.assign({}, this.$route);
  },
  methods: {
    updateInputData({ keyStr, value }) {
      this.dataForm[keyStr] = value || "";
    },
    loadReasonData(id) {
      getreason({ awardEventId: id })
        .then((response) => {
          this.dataForm.id = id;
          this.dataForm.eventName = response.data.eventName;
          this.dataForm.eventDesc = response.data.eventDesc;
          this.dataForm.eventStatus = response.data.eventStatus;
          this.dataForm.eventMinScore = response.data.eventMinScore;
          this.dataForm.eventMaxScore = response.data.eventMaxScore;
          this.setTagsViewTitle();
          this.setPageTitle();
        })
        .catch((err) => {});
    },
    setTagsViewTitle() {
      const title = "编辑事件";
      const route = Object.assign({}, this.tempRoute, {
        title: `${title}-${this.dataForm.eventName}`,
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    },
    setPageTitle() {
      const title = "编辑事件";
      document.title = `${title} - ${this.dataForm.eventName}`;
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$route).then(() => {
        this.$router.replace({ path: "/integral/reasonlist" });
      });
    },
    save() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          const { eventMaxScore, eventMinScore } = this.dataForm;
          if (eventMaxScore < eventMinScore) {
            this.$message({
              message: "最高点赞额度不能小于最低点赞额度",
              type: "error",
            });
            return
          }
          if (this.isEdit) {
            updatereason(this.dataForm).then((response) => {
              this.$notify({
                title: "成功",
                message: "编辑事件成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/reasonlist" });
              });
            });
          } else {
            addreason(this.dataForm).then((response) => {
              this.$notify({
                title: "成功",
                message: "创建事件成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/reasonlist" });
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.input_error {
  border-color: #ff4949;
}
</style>
<style lang="scss">
.dlg_managers .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.dlg_visibles .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.el-tree {
  display: inline-block;
  min-width: 100%;
}
.dlg_managers .el-dialog {
  min-width: 660px;
}
.dlg_visibles .el-dialog {
  min-width: 660px;
}
.el-tabs__nav-scroll {
  padding-left: 50px;
}
.el-tabs__header {
  margin-bottom: 0px;
}
.addBtn .el-icon-circle-plus-outline {
  font-size: 16px;
}
</style>
