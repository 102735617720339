var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-base-page", {
        ref: "basePage",
        attrs: {
          columns: _vm.columns,
          searchFileds: _vm.searchFileds,
          searchBtnText: _vm.searchBtnText,
          exportService: _vm.exportService,
          buttons: _vm.buttons,
          loadTableData: _vm.loadTableData,
          exportTableData: _vm.exportTableData,
        },
      }),
      _c("repeal-modal", {
        ref: "repealModal",
        attrs: {
          title: "点赞信息详情",
          buttons: ["close"],
          form: _vm.form,
          formData: _vm.detail,
          dialogVisible: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }