var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAuth
    ? _c("reason-detail", { attrs: { "is-edit": false } })
    : _c("NoAuthPage")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }