var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "submitForm",
      attrs: {
        model: _vm.formFields,
        rules: _vm.formRules,
        "label-width": _vm.labelWidth,
      },
    },
    [
      _c(
        "el-form-item",
        {
          staticClass: "content",
          attrs: { label: "登录人所属中心:", prop: "centerName" },
        },
        [_c("span", [_vm._v(_vm._s(_vm.centerName))])]
      ),
      _c(
        "el-form-item",
        {
          staticClass: "content",
          attrs: { label: "登录人所属部门:", prop: "deptName" },
        },
        [_c("span", [_vm._v(_vm._s(_vm.deptName))])]
      ),
      _c(
        "el-form-item",
        {
          staticClass: "content",
          attrs: { label: "登录人所属中心的剩余分:", prop: "poolRemainNum" },
        },
        [_c("span", [_vm._v(_vm._s(_vm.poolRemainNum))])]
      ),
      _c(
        "el-form-item",
        { staticClass: "content", attrs: { label: "额度:", prop: "quota" } },
        [
          _c("el-input", {
            attrs: { clearable: "", placeholder: "请输入赞币数量" },
            model: {
              value: _vm.formFields.quota,
              callback: function ($$v) {
                _vm.$set(_vm.formFields, "quota", $$v)
              },
              expression: "formFields.quota",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "content", attrs: { label: "接收人:", prop: "userid" } },
        [
          _c("select-user", {
            attrs: { clearable: "", placeholder: "点按钮添加接收人" },
            model: {
              value: _vm.formFields.userid,
              callback: function ($$v) {
                _vm.$set(_vm.formFields, "userid", $$v)
              },
              expression: "formFields.userid",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "btn-wrap" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.goBack()
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }