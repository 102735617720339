<template>
  <div class="app-container">
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="dataForm"
      label-position="right"
      label-width="120px"
      style="min-width: 560px; margin-left: 30px; margin-top: 30px; width: 90%"
    >
      <el-form-item label="头像" prop="avatarUrl">
        <el-upload
          class="avatar-uploader"
          action="/api/v1/gtmsh-point/attachment/upload"
          :show-file-list="false"
          :headers="headerObj"
          :on-change="onUploadChange"
          :disabled="type == 'view'"
          accept=".jpg,.jpeg,.png"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="avatarUrl" :src="avatarUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <form-input
        laeblName="账号"
        keyStr="employeeAccount"
        :required="true"
        @updateInputData="updateInputData"
        :inputData="dataForm.employeeAccount"
        :maxlength="30"
        :disabled="type != 'add'"
      ></form-input>
      <form-input
        laeblName="员工姓名"
        keyStr="realName"
        :required="true"
        @updateInputData="updateInputData"
        :inputData="dataForm.realName"
        :disabled="type == 'view'"
      ></form-input>
      <form-input
        laeblName="别名"
        keyStr="nickName"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.nickName"
        :disabled="type == 'view'"
      ></form-input>
      <form-input
        laeblName="员工工号"
        keyStr="employeeNo"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.employeeNo"
        :disabled="type == 'view'"
      ></form-input>
      <el-form-item label="性别" prop="gender">
        <el-select
          v-model="dataForm.gender"
          style="width: 400px"
          :disabled="type == 'view'"
        >
          <el-option
            v-for="item in genderOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <form-input
        laeblName="手机号"
        keyStr="mobile"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.mobile"
        :disabled="type == 'view'"
      ></form-input>
      <form-input
        laeblName="邮箱"
        keyStr="email"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.email"
        :disabled="type == 'view'"
      ></form-input>
      <el-form-item
        label="职位"
        prop="positionLevelDictCode"
        v-if="options && options.length > 0"
      >
        <el-select
          v-model="dataForm.positionLevelDictCode"
          style="width: 400px"
          :disabled="type == 'view'"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <form-input
        laeblName="职务"
        keyStr="position"
        :required="false"
        @updateInputData="updateInputData"
        :inputData="dataForm.position"
        :disabled="type == 'view'"
      ></form-input>
      <el-form-item label="部门" prop="deptName">
        <el-button
          :disabled="type == 'view'"
          @click="openDeptDlg('senddis')"
          style="
            padding: 0px 6px 0px 3px;
            width: 400px;
            min-height: 28px;
            vertical-align: middle;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="white-space: normal; text-align: left">
              <el-tag
                v-if="dataForm.deptName"
                size="mini"
                style="margin: 3px; white-space: normal; height: auto"
                >{{ dataForm.deptName }}</el-tag
              >
            </div>
            <i
              class="el-icon-collection-tag"
              style="font-size: 16px; color: #dcdfe6"
            ></i>
          </div>
        </el-button>
        <el-input v-show="false" v-model="dataForm.deptName" />
      </el-form-item>
      <el-form-item label="所属中心" prop="centerName">
        <el-input
          v-model="dataForm.centerName"
          style="width: 400px"
          :disabled="true"
        />
      </el-form-item>
      <el-form-item label="角色" prop="roleIdList">
        <el-select
          v-model="dataForm.roleIdList"
          multiple
          placeholder="请选择"
          style="width: 400px"
        >
          <el-option
            v-for="item in roleOpitons"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item style="margin-top: 50px" v-if="type != 'view'">
        <el-button
          type="primary"
          @click="save()"
          size="medium"
          style="margin-right: 50px"
        >
          保&nbsp;存
        </el-button>
        <el-button size="medium" @click="cancel()" v-if="type != 'view'">
          取&nbsp;消
        </el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      title="设置部门"
      :visible.sync="deptDlg.visible"
      width="80%"
      top="calc( ( 100vh - 550px ) / 2 )"
      class="dlg_items"
    >
      <el-container style="height: 400px; border: 1px solid #eee">
        <el-aside
          width="40%"
          style="
            border-right: 1px solid #eee;
            height: 100%;
            padding: 0;
            background-color: #ffffff;
            margin-bottom: 0px;
          "
        >
          <el-header
            style="
              text-align: right;
              font-size: 12px;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #eee;
            "
          >
            <el-popover
              placement="top"
              width="200px"
              trigger="manual"
              content="请输入关键词"
              v-model="deptDlg.searchkeyTipShow"
            >
              <el-input
                slot="reference"
                placeholder="请输入关键字"
                clearable
                v-model="deptDlg.searchkey"
                @keydown.enter.native="deptDlg_query"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="deptDlg_query"
                ></el-button>
              </el-input>
            </el-popover>
          </el-header>
          <el-main style="height: 309px">
            <el-tree
              :data="deptData.tree"
              node-key="id"
              :props="{ children: 'children', label: 'deptName' }"
              :expand-on-click-node="false"
              :default-expanded-keys="['1']"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    v-if="data.id != '1'"
                    class="addBtn"
                    type="text"
                    size="mini"
                    circle
                    icon="el-icon-circle-plus-outline"
                    @click="() => deptDlg_add(data)"
                    :disabled="deptDlg_added(data.id)"
                  >
                  </el-button>
                </span>
              </span>
            </el-tree>
          </el-main>
        </el-aside>
        <el-aside
          width="40%"
          style="
            height: 100%;
            padding: 5px 10px;
            background-color: #ffffff;
            margin-bottom: 0px;
          "
        >
          <el-table
            :data="deptDlg.searchList"
            v-loading="deptDlg.loading"
            style="width: 100%"
            max-height="346px"
            height="346px"
          >
            <el-table-column prop="title" label="部门">
              <template slot-scope="{ row }">
                <span>{{ deptpath(row.id) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="操作" width="80px">
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.id != '1'"
                  type="primary"
                  size="mini"
                  :disabled="deptDlg_added(row.id)"
                  @click="deptDlg_add(row)"
                >
                  添加
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-aside>
        <el-aside
          width="20%"
          style="
            border-left: 1px solid #eee;
            height: 100%;
            padding: 0;
            background-color: #ffffff;
          "
        >
          <el-header
            style="
              text-align: center;
              font-size: 12px;
              height: 40px;
              line-height: 40px;
              border-bottom: 1px solid #eee;
            "
          >
            已选择
          </el-header>
          <el-main style="height: 356px; padding: 10px 0">
            <el-tag
              :key="dept.id"
              v-for="dept in deptDlg.items"
              style="margin: 10px 0 0 10px; height: auto; white-space: normal"
              closable
              size="small"
              @close="deptDlg_remove(dept)"
            >
              {{ dept.deptName }}
            </el-tag>
          </el-main>
        </el-aside>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDeptDlg(false)">取 消</el-button>
        <el-button type="primary" @click="closeDeptDlg(true)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  allDept,
  getDeptInfo,
  getPosition,
  getDeptByKw,
  roleAllList,
  addEmployee,
  editEmployee,
  getEmployee,
} from "@/api/user";
import { getToken } from "@/utils/auth";
import FormInput from "@/components/FormInput";

const defaultForm = {
  employeeAccount: "",
  realName: "",
  nickName: "",
  employeeNo: "",
  position: "",
  positionLevelDictCode: "",
  centerName: "",
  deptCode: "",
  deptName: "",
  gender: "",
  id: "",
  roleIdList: [],
  avatar: "",
  email: "",
  mobile: "",
};
const defaultDeptDlgData = {
  visible: false,
  items: [],
  searchkey: "",
  searchkeyTipShow: false,
  loading: false,
  searchList: [],
};
export default {
  name: "EmployeeDetail",
  components: { FormInput },
  data() {
    return {
      dataForm: Object.assign({}, defaultForm),
      loading: false,
      rules: {
        employeeAccount: [
          { required: true, message: "账号为必填项", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "员工姓名为必填项", trigger: "blur" },
        ],
        // nickName: [
        //   { required: true, message: "别名为必填项", trigger: "blur" },
        // ],
        // employeeNo: [
        //   { required: true, message: "员工工号为必填项", trigger: "blur" },
        // ],
        gender: [{ required: true, message: "性别为必选项", trigger: "blur" }],
        // position: [
        //   { required: true, message: "职务为必填项", trigger: "blur" },
        // ],
        positionLevelDictCode: [
          { required: true, message: "职位为必填项", trigger: "blur" },
        ],
        deptName: [
          { required: true, message: "部门为必选项", trigger: "blur" },
        ],
        centerName: [
          { required: true, message: "所属中心为必填项", trigger: "blur" },
        ],
      },
      tempRoute: {},
      type: "",
      deptDlg: Object.assign({}, defaultDeptDlgData),
      depts_senddis: [],
      depts_receivedis: [],
      deptData: {
        tree: [],
        map: new Map(),
      },
      options: [],
      roleOpitons: [],
      genderOptions: [
        {
          label: "保密",
          value: "0",
        },
        {
          label: "男",
          value: "1",
        },
        {
          label: "女",
          value: "2",
        },
      ],
      headerObj: {
        "x-http-token": getToken(),
        "x-http-app-code": "BACKEND",
      },
      avatarUrl: "",
    };
  },
  computed: {
    deptpath() {
      return (id) => {
        let path = "";
        while (true) {
          if (!id) break;
          if (id == "1" && path != "") break;
          let dept = this.deptData.map.get(id);
          if (!dept) break;
          if (path != "") path = "\\" + path;
          path = dept.deptName + path;
          id = dept.parentDeptId;
        }
        return path;
      };
    },
    deptDlg_added() {
      return (id) => {
        for (let i = 0; i < this.deptDlg.items.length; ++i) {
          if (this.deptDlg.items[i].id == id) return true;
        }
        return false;
      };
    },
  },
  created() {
    this.requestPosition();
    this.loadDeptData();
    this.loadAllRole();
    const { employeeId } = this.$route.query;
    const { path } = this.$route;
    let title = "";
    if (path.endsWith("view")) {
      title = "查看员工";
      this.type = "view";
      this.dataForm.id = employeeId;
      this.loadEmployeData(employeeId);
    } else if (path.endsWith("edit")) {
      title = "编辑员工";
      this.type = "edit";
      this.dataForm.id = employeeId;
      this.loadEmployeData(employeeId);
    } else {
      title = "新增员工";
      this.type = "add";
    }
    this.tempRoute = Object.assign({}, this.$route);
    this.setTagsViewTitle(title);
    this.setPageTitle(title + " - 圣恩赞币系统后台");
  },
  methods: {
    beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return  isLt2M;
    },
    updateInputData({ keyStr, value }) {
      this.dataForm[keyStr] = value || "";
    },
    onUploadChange(file, flieList) {
      if (file) {
        const { response } = file;
        if (response) {
          const { code, message, data } = response;
          if (code == 200) {
            const { attachmentId } = data;
            if (attachmentId) {
              this.dataForm.avatar = attachmentId;
              this.avatarUrl = `${process.env.VUE_APP_BASE_URL}/api/v1/gtmsh-point/backend/attachment/file/download/nologin?attachmentId=${attachmentId}`;
            } else {
              this.$message({
                type: "error",
                message: message || "上传失败",
              });
            }
          } else {
            this.$message({
              type: "error",
              message: message || "上传失败",
            });
          }
        }
      }
    },

    loadAllRole() {
      roleAllList()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.roleOpitons = response.data.map((item) => {
              return {
                label: item.name,
                value: item.id + "",
              };
            });
          }
        })
        .catch((err) => {});
    },
    requestPosition() {
      getPosition({ positionStatus: "1" })
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.options = response.data.map((item) => {
              return {
                label: item.positionName,
                value: item.id + "",
              };
            });
          }
        })
        .catch((err) => {});
    },
    loadDeptData() {
      allDept({})
        .then((response) => {
          let deptArray = response.data;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.id, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (
              item.parentDeptId &&
              item.parentDeptId != "" &&
              item.parentDeptId != "0"
            ) {
              parent = deptMap.get(item.parentDeptId);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
          this.getList();
        })
        .catch((err) => {});
    },

    openDeptDlg(type) {
      this.deptDlg = Object.assign({}, defaultDeptDlgData, {
        visible: true,
        items: type == "senddis" ? this.depts_senddis : this.depts_receivedis,
        type,
      });
    },
    closeDeptDlg(save) {
      this.deptDlg.visible = false;
      if (save) {
        let ids = "";
        this.deptDlg.items.forEach((item, i) => {
          if (i != 0) ids += ",";
          ids += item.id;
        });
        if (this.deptDlg.type == "senddis") {
          this.depts_senddis = this.deptDlg.items;
          this.dataForm.deptids_senddis = ids;
        } else {
          this.depts_receivedis = this.deptDlg.items;
          this.dataForm.deptids_receivedis = ids;
        }
      }
    },
    deptDlg_query() {
      if (!this.deptDlg.searchkey) {
        this.deptDlg.searchkeyTipShow = true;
        setTimeout(() => {
          this.deptDlg.searchkeyTipShow = false;
        }, 2000);
        return;
      }
      this.deptDlg.loading = true;
      getDeptByKw({ deptName: this.deptDlg.searchkey, deptId: 1 })
        .then((response) => {
          this.deptDlg.searchList = response.data;
          this.deptDlg.loading = false;
        })
        .catch((err) => {
          this.deptDlg.loading = false;
        });
    },
    deptDlg_add(dept) {
      if (this.deptDlg.items && this.deptDlg.items.length >= 1) {
        return;
      }
      // for (let i = 0; i < this.deptDlg.items.length; ++i) {
      //   if (this.deptDlg.items[i].id == dept.id) return;
      // }
      this.dataForm.deptName = dept.deptName;
      this.dataForm.deptId = dept.id;
      this.deptDlg.items.push(dept);
      //查询所属中心
      this.queryCenter(dept.id);
    },
    queryCenter(id) {
      getDeptInfo({ id })
        .then((response) => {
          const { centerName } = response.data;
          this.dataForm.centerName = centerName || "";
        })
        .catch((err) => {});
    },
    deptDlg_remove(dept) {
      for (let i = 0; i < this.deptDlg.items.length; ++i) {
        if (this.deptDlg.items[i].id == dept.id) {
          this.deptDlg.items.splice(i, 1);
          return;
        }
      }
    },

    loadEmployeData(id) {
      getEmployee({ id })
        .then((response) => {
          this.dataForm.employeeAccount = response.data.employeeAccount;
          this.dataForm.realName = response.data.realName;
          this.dataForm.nickName = response.data.nickName;
          this.dataForm.employeeNo = response.data.employeeNo;
          this.dataForm.gender = response.data.gender + "";
          this.dataForm.position = response.data.position;
          this.dataForm.positionLevelDictCode =
            response.data.positionLevelDictCode;
          this.dataForm.deptName = response.data.deptName;
          this.dataForm.deptId = response.data.deptId;
          this.dataForm.centerName = response.data.centerName;
          this.dataForm.mobile = response.data.mobile;
          this.dataForm.email = response.data.email;

          this.dataForm.roleIdList = response.data.roles.map((item) => {
            return item.id + "";
          });
          this.depts_senddis = [
            {
              id: response.data.deptId,
              deptName: response.data.deptName,
            },
          ];
          const { avatar } = response.data;
          if (avatar) {
            this.dataForm.avatar = avatar;
            this.avatarUrl = `${process.env.VUE_APP_BASE_URL}/api/v1/gtmsh-point/backend/attachment/file/download/nologin?attachmentId=${avatar}`;
          }
        })
        .catch((err) => {});
    },
    setTagsViewTitle(title) {
      const route = Object.assign({}, this.tempRoute, {
        title,
      });
      this.$store.dispatch("tagsView/updateVisitedView", route);
    },
    setPageTitle(title) {
      document.title = title;
    },
    cancel() {
      this.$store.dispatch("tagsView/delView", this.$route).then(() => {
        this.$router.replace({ path: "/integral/employee-management" });
      });
    },
    save() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.type == "edit") {
            editEmployee(this.dataForm).then((response) => {
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/employee-management" });
              });
            });
          } else {
            addEmployee(this.dataForm).then((response) => {
              this.$notify({
                title: "成功",
                message: "保存成功",
                type: "success",
                duration: 2000,
              });
              this.$store.dispatch("tagsView/delView", this.$route).then(() => {
                this.$router.replace({ path: "/integral/employee-management" });
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";

.input_error {
  border-color: #ff4949;
}
</style>
<style lang="scss">
.dlg_managers .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.dlg_visibles .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.el-tree {
  display: inline-block;
  min-width: 100%;
}
.dlg_managers .el-dialog {
  min-width: 660px;
}
.dlg_visibles .el-dialog {
  min-width: 660px;
}
.el-tabs__nav-scroll {
  padding-left: 50px;
}
.el-tabs__header {
  margin-bottom: 0px;
}
.addBtn .el-icon-circle-plus-outline {
  font-size: 16px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
