import request2 from '@/utils/request2'

// 提现管理分页
export function getPage(data) {
  return request2({
    url: '/point/withdrawal/page-verification',
    method: 'post',
    data
  })
}

// 批量冻结
export function freezeBatch(data) {
  return request2({
    url: '/point/withdrawal/freeze/batch',
    method: 'post',
    data
  })
}

// 批量核销
export function verificationBatch(data) {
  return request2({
    url: '/point/withdrawal/verification/batch',
    method: 'post',
    data
  })
}

// 批量核销
export function verificationDetail(data) {
  return request2({
    url: '/point/withdrawal/page-verification-detail',
    method: 'post',
    data
  })
}
