<script>
import TableBase from "./TableBase";
import moment from "moment";
import request2 from "@/utils/request2";
import { debounce } from "@/utils";
export default {
  name: "table-base-page",
  components: {
    TableBase,
  },
  props: {
    //表格头
    columns: {
      type: Array,
      default: () => [],
    },
    //无数据时显示默认值
    emptyText: {
      type: String,
      default: "暂无数据哦~",
    },
    //表格prop配置
    tableProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isNeedSearch: {
      type: Boolean,
      default: true,
    },
    //表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    //支持自定义表格筛选头
    setSearchBar: Function,
    //支持自定义表格按钮
    setTableBtn: Function,
    //支持自定义表格数据导出方法
    exportTableData: Function,
    //导出接口
    exportService: {
      type: String,
      default: "",
    },
    //筛选头部
    searchFileds: {
      type: Array,
      default: () => [],
    },
    //是否展示label
    showLabel: {
      type: Boolean,
      default: true,
    },
    //按钮配置，如['search']则至展示查询按钮
    buttons: {
      type: Array,
      default: () => ["search", "reset", "export"],
    },
    //查询功能按钮文案
    searchBtnText: {
      type: String,
      default: "查询",
    },
    //重置功能按钮文案
    resetBtnText: {
      type: String,
      default: "重置",
    },
    //重置功能按钮文案
    exportBtnText: {
      type: String,
      default: "导出",
    },
    //是否展示分页
    showPagination: {
      type: Boolean,
      default: true,
    },
    //分页功能设置，具体参考elem-ui文档，默认开启全功能
    paginationLayout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    //每页显示个数选择器的选项设置
    paginationSizes: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    //查询数据方法
    loadTableData: {
      type: Function,
      default: () => {
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      },
    },
  },
  data() {
    return {
      totalNum: 0, //表格数据总数
      currentPage: 1, //当前页
      formFields: {}, //筛选表单数据
      dataList: [], //表格数据
      paginationSize: 10, //当前每页数据量
      loading: false,
    };
  },
  methods: {
    //渲染表格主体
    renderTable() {
      const { columns, emptyText, tableProps, tableData } = this._props;
      const { dataList, loading } = this._data;
      const tablePropsOption = {
        props: {
          emptyText,
          tableProps,
        },
      };
      return (
        <table-base
          v-loading={loading}
          columns={columns}
          tableData={dataList}
          {...tablePropsOption}
        />
      );
    },
    //渲染搜索栏
    renderSearchBar(createElement) {
      if (this._props.setSearchBar) {
        return this._props.setSearchBar(createElement);
      } else {
        const {
          searchFileds,
          showLabel,
          searchBtnText,
          resetBtnText,
          exportBtnText,
          buttons,
        } = this._props;
        const { formFields } = this._data;
        const searchBarProps = {
          class: ["gt-table-aside"],
          props: {},
        };
        const resetBtnProp = {
          on: {
            click: () => {
              this.resetForm("tableForm");
            },
          },
        };
        const searchBtnProp = {
          on: {
            click: this.searchData,
          },
        };
        return (
          <el-form
            class="form-container"
            props={{ model: formFields }}
            ref="tableForm"
          >
            {searchFileds.map((formItem) => {
              return (
                <el-form-item
                  props={formItem.props}
                  label={showLabel ? formItem.label : ""}
                  class="form-item"
                  prop={
                    typeof formItem.prop != "string"
                      ? `${formItem.prop[0]}_${formItem.prop[1]}`
                      : formItem.prop
                  }
                >
                  {/**输入框 */}
                  {formItem.type == "input" && (
                    <el-input
                      v-model={formFields[formItem.prop]}
                      placeholder={formItem?.placeholder || ""}
                    ></el-input>
                  )}

                  {/**下拉框 */}
                  {formItem.type == "select" && (
                    <el-select
                      props={formItem.innerProps}
                      v-model={formFields[formItem.prop]}
                      placeholder={formItem?.placeholder || ""}
                    >
                      {formItem.options?.length > 0
                        ? formItem.options.map((optionItem) => (
                            <el-option
                              props={formItem.selectOptionProps}
                              label={optionItem.label}
                              value={optionItem.value}
                            ></el-option>
                          ))
                        : null}
                    </el-select>
                  )}

                  {/**日期选择框 */}
                  {formItem.type == "datePicker" && (
                    <el-date-picker
                      props={formItem.innerProps}
                      type="date"
                      placeholder={formItem?.placeholder || ""}
                      v-model={formFields[formItem.prop]}
                      on={{ change: (e) => this.dateChange(e, formItem) }}
                      style="width: 100%;"
                    />
                  )}

                  {/**时间选择框 */}
                  {formItem.type == "timePicker" && (
                    <el-time-picker
                      props={formItem.innerProps}
                      placeholder={formItem?.placeholder || ""}
                      v-model={formFields[formItem.prop]}
                      style="width: 100%;"
                    />
                  )}

                  {/**日期时间选择框 */}
                  {formItem.type == "dateTimePicker" && (
                    <el-date-picker
                      props={formItem.innerProps}
                      v-model={formFields[formItem.prop]}
                      type="datetime"
                      placeholder={formItem?.placeholder || ""}
                    />
                  )}

                  {/**日期时间选择框-dateTimeRange */}
                  {formItem.type == "datetimerange" && (
                    <el-date-picker
                      props={formItem.innerProps}
                      v-model={formFields[formItem.prop]}
                      type="datetimerange"
                      range-separator={formItem?.rangeSeparator || ""}
                      start-placeholder={formItem?.startPlaceholder || ""}
                      end-placeholder={formItem?.endPlaceholder || ""}
                    ></el-date-picker>
                  )}

                  {/**日期时间选择框-dateTimeRange */}
                  {formItem.type == "daterange" && (
                    <el-date-picker
                      props={formItem.innerProps}
                      v-model={
                        formFields[`${formItem.prop[0]}_${formItem.prop[1]}`]
                      }
                      type="daterange"
                      range-separator={formItem?.rangeSeparator || ""}
                      start-placeholder={formItem?.startPlaceholder || ""}
                      end-placeholder={formItem?.endPlaceholder || ""}
                      on={{
                        change: (e) => this.dateRangeChange(e, formItem.prop),
                      }}
                    />
                  )}
                </el-form-item>
              );
            })}
            {/**按钮组 */}
            <el-form-item class="form-btns">
              {buttons.includes("search") && (
                <el-button
                  type="primary"
                  {...searchBtnProp}
                  icon="el-icon-search"
                >
                  {searchBtnText}
                </el-button>
              )}
              {buttons.includes("reset") && (
                <el-button {...resetBtnProp} icon="el-icon-refresh-right">
                  {resetBtnText}
                </el-button>
              )}
              {buttons.includes("export") && (
                <el-button on={{ click: this.export }}>
                  {exportBtnText}
                </el-button>
              )}
            </el-form-item>
          </el-form>
        );
      }
    },

    //导出功能
    async export() {
      const { exportTableData } = this._props;
      const { searchParams, formFields, currentPage, paginationSize } =
        this._data;
      const params = {
        pageSize: paginationSize,
        pageNum: currentPage,
        ...formFields,
      };
      const tableData =
        exportTableData instanceof Function
          ? await exportTableData(params)
          : await this.exoprtFun(formFields);
    },

    //查询数据
    exoprtFun(params) {
      const that = this;
      const { exportService } = this._props;
      request2({
        url: exportService,
        method: "post",
        data: params,
      })
        .then((response) => {
          if (response.code == 200) {
            {/* that.doExportExcel(response.data); */}
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //导出
    doExportExcel(datalist) {
    },

    //日期选择--当dateType为end时，默认时间串为23:59:59
    dateChange(value, formItem) {
      const { formFields } = this._data;
      const updateData = {
        [formItem.prop]:
          formItem.dateType == "end" && value
            ? moment(value)
                .add(23, "hours")
                .add(59, "minutes")
                .add(59, "seconds")
            : value,
      };
      this.$set(this, "formFields", { ...formFields, ...updateData });
    },

    //时间范围选择器值变化处理
    dateRangeChange(value, prop) {
      const { formFields } = this._data;
      const updateData = {
        [prop[0]]: value ? value[0] : value,
        [prop[1]]: value
          ? moment(value[1])
              .add(23, "hours")
              .add(59, "minutes")
              .add(59, "seconds")
          : value,
      };
      this.$set(this, "formFields", { ...formFields, ...updateData });
    },

    //重置筛选条件
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$nextTick(() => {
          this.$refs[refName].resetFields();
          this.$set(this, "formFields", {});
          this.$set(this, "currentPage", 1);
          this.handleInitialForm()
          this.initTableData();
        });
      }
    },

    //渲染表格项
    renderTableButton(createElement) {
      const { setTableBtn } = this._props;
      return setTableBtn instanceof Function ? (
        <el-row class="table-button-wrap">{setTableBtn(createElement)}</el-row>
      ) : null;
    },

    //渲染分页
    renderPagination() {
      const { showPagination, paginationLayout, paginationSizes } = this._props;
      const { currentPage, totalNum, paginationSize } = this._data;
      const paginationProp = {
        on: {
          "size-change": this.handleSizeChange,
          "current-change": this.handleCurrentChange,
        },
      };
      return showPagination ? (
        <el-row class="table-pagination-wrap">
          <el-pagination
            {...paginationProp}
            current-page={currentPage}
            page-sizes={paginationSizes}
            page-size={paginationSize}
            layout={paginationLayout}
            total={totalNum}
          />
        </el-row>
      ) : null;
    },

    //每页显示数目变化处理
    handleSizeChange(value) {
      this.$emit("size-change");
      this.$set(this, "paginationSize", value);
      this.initTableData();
    },

    //当前页变化处理
    handleCurrentChange(value) {
      this.$emit("current-change");
      this.$set(this, "currentPage", value);
      this.initTableData();
    },

    searchData() {
      this.initTableData(1);
    },

    //初始化表格数据
    async initTableData(needRefresh) {
      if (needRefresh && needRefresh == 1) {
        this.$set(this, "currentPage", 1);
      }
      const { tableData, loadTableData, isNeedSearch } = this._props;
      const { searchParams, formFields, currentPage, paginationSize } =
        this._data;
      this.$set(this, "loading", true);
      if (!isNeedSearch) {
        this.$set(this, "dataList", tableData);
      } else {
        const params = {
          pageSize: paginationSize,
          pageNum: currentPage,
          ...formFields,
        };
        const tableData = await loadTableData(params);
        this.$set(this, "totalNum", tableData.total);
        this.$set(this, "dataList", tableData.list);
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.$set(this, "loading", false);
        }, 200);
      });
    },

    //处理表单生成初始化formFields
    handleInitialForm() {
      const { searchFileds } = this._props;
      const formFields = {};
      searchFileds.map((formItem) => {
        if (formItem.initialValue && formItem.prop instanceof Array) {
          formFields[`${formItem.prop[0]}_${formItem.prop[1]}`] =
            formItem.initialValue;
          formFields[formItem.prop[0]] = formItem.initialValue[0];
          formFields[formItem.prop[1]] = formItem.initialValue[1];
        } else if (formItem.initialValue) {
          formFields[formItem.prop] = formItem.initialValue;
        }
      });
      this.$set(this, "formFields", formFields);
    },
  },
  created() {
    const { paginationSizes } = this._props;
    this.$set(this, "paginationSize", paginationSizes[0]);
    this.handleInitialForm();
    this.initTableData();
  },
  render(createElement) {
    const { searchFileds } = this._props;
    return (
      <div class="table-base-page-container">
        {searchFileds.length > 0 && (
          <el-row>{this.renderSearchBar(createElement)}</el-row>
        )}
        {this.renderTableButton()}
        <el-row>{this.renderTable(createElement)}</el-row>
        {this.renderPagination()}
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.table-base-page-container {
  // padding: 20px;
  .gt-table-search-bar {
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    padding-right: 0;
  }
  .table-button-wrap {
    margin-top: 10px;
    margin-bottom: 14px;
  }
  .form-container {
    display: flex;
    flex-wrap: wrap;
    .form-item {
      margin-right: 10px;
    }
    /deep/ .el-form-item {
      display: flex;
      label {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .form-btns {
      margin-left: 10px;
    }
  }
  .table-pagination-wrap {
    margin-top: 14px;
  }
}
</style>
