<template>
  <div class="video-prew-wrap">
    <div @click="openVideoDlg(videoUrl)" class="video-prew-wrap">
      <div class="preview-image" :src="thumburl" fit="cover">
      </div>
      <i class="el-icon-caret-right play_btn_inline" />
    </div>
    <el-dialog
      title=""
      :visible.sync="videoDlg.visible"
      width="60%"
      top="20vh"
      custom-class="dlg_video"
      :destroy-on-close="true"
      :show-close="false"
      :append-to-body="true"
      @close="onVideoDlgClose()"
    >
      <video
        :src="videoDlg.url"
        controls="controls"
        autoplay="autoplay"
        style="width: 100%; height: 60vh; background-color: #000000"
      >
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "video-preview",
  props: {
    thumburl: String,
    videoUrl: String,
  },
  data() {
    return {
      videoDlg: {
        url: "",
        visible: false,
      },
    };
  },
  methods: {
    openVideoDlg(url) {
      this.videoDlg.url = url;
      this.videoDlg.visible = true;
    },
    onVideoDlgClose() {
      this.videoDlg.url = "";
      this.videoDlg.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.video-prew-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  .preview-image {
    width: 100%;
    height: 100%;
    background-color: #999;
  }
}
.play_btn_inline {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 64px;
  color: white;
  width: 100%;
  height: 100%;
  line-height: 128px;
  text-align: center;
}
.dlg_video {
  background: none;
  border-radius: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dlg_video .el-dialog__header {
  display: none;
}
.dlg_video .el-dialog__body {
  padding: 0;
}
.filter-item {
  margin-right: 10px;
}
/deep/ .el-dialog__body {
  padding: 20px 20px 30px 20px;
}
</style>
