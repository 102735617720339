<template>
  <div class="login-container">
      <div class="title-container">
        <h3 class="title">赞币系统后台登录中，请稍候……</h3>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Entry',
  data() {
    return {
      code: null
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.code = query.code
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('user/ssologin', this.code)
      .then(() => {
        this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
      })
      .catch(() => {
        this.$router.push({ path: '/loginfail'})
      })
  },
  destroyed() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
$bg:#2d3a4b;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .title-container {
    position: relative;

    .title {
      font-size: 50px;
      color: $light_gray;
      margin: 100px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
