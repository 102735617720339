<template>
  <el-dialog
    class="dialog-wrap"
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :append-to-body="true"
    :before-close="handleClose">
    <el-divider class="divider"></el-divider>
    <!-- 详情信息 -->
    <div class="dialog-body">
      <slot name="body"></slot>
    </div>
   

    <el-divider class="divider"></el-divider>
    <!-- 底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <slot name="footer"></slot>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'repeal-modal',
  props: {
    //关闭弹窗按钮文案
    closeBtnText: {
      type: String,
      default: '关 闭'
    },
    //确认按钮文案
    comfirmBtnText: {
      type: String,
      default: '确 认'
    },
    //弹窗宽度
    width: {
      type: String,
      default: '56%'
    },
    //弹窗标题
    title: {
      type: String,
      default: '提示'
    },
    //关闭弹窗前确认提示
    closeConfirm: {
      type: Boolean,
      default: false
    },
    //是否展示弹窗
    value: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      //弹出是否展示
      dialogVisible: false,
    }
  },
  watch: {
    value: {
      handler: function (val,oldVal) {
        this.$set(this,'dialogVisible',val)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {

  },
  methods: {
    //打开弹窗
    show() {
      this.$emit('input',!this._data.dialogVisible)
    },
    //关闭前处理
    handleClose(done) {
      const { closeConfirm } = this._props
      if(closeConfirm) {
        this.$alert('关闭弹窗不会保存数据，是否确认关闭?', '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          callback: action => {
            this.show()
          }
        });
      } else {
        this.show()
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-wrap {
  /deep/ .el-dialog__header {
    padding: 16px 24px;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 650;
    }
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .dialog-body {
    min-height: 100px;
  }

  .form-wrap {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    /deep/ .el-form-item {
      font-weight: normal;
    }
    .image {
      margin-right: 10px;
      width: 140px;
      height: 140px;
    }
  }
  
  .divider {
    margin: 0
  }
}
</style>