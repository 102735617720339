<template>
  <div class="select-comp-wrap el-input__inner">
    <div
      v-if="type == 'user'"
      @click="openItemDlg_user()"
      @mouseenter="focusFun()"
      @mouseleave="focusFun()"
      class="preview-btn"
    >
      <div class="preview-content-wrap">
        <span class="preview-placeholder" v-show="placeholderShow">
          {{ placeholder }}
        </span>
        <div class="preview-content">
          <el-tag
            class="preview-tag"
            v-for="item in selectValues"
            :key="item.id"
            size="mini"
            >{{ item.realName }}</el-tag
          >
        </div>
        <span>
          <span v-show="false" @click.stop="clear()"
            ><i class="el-icon-circle-close icon close-icon"></i
          ></span>
          <i class="el-icon-user icon"></i>
        </span>
      </div>
    </div>
    <div
      v-if="type == 'dept'"
      @click="openItemDlg_dept()"
      @mouseenter="focusFun()"
      @mouseleave="focusFun()"
      class="preview-btn"
    >
      <div class="preview-content-wrap">
        <span class="preview-placeholder" v-show="placeholderShow">
          {{ placeholder }}
        </span>
        <div class="preview-content">
          <el-tag
            class="preview-tag"
            v-for="item in selectValues"
            :key="item.id"
            size="mini"
            >{{ item.deptName }}</el-tag
          >
        </div>
        <span>
          <span v-show="showClear" @click.stop="clear()"
            ><i class="el-icon-circle-close icon close-icon"></i
          ></span>
          <i class="el-icon-collection-tag icon"></i>
        </span>
      </div>
    </div>
    <el-dialog
      title="设置接收人"
      :visible.sync="itemDlg_user.visible"
      width="80%"
      top="calc( ( 100vh - 550px ) / 2 )"
      class="dlg_items"
    >
      <el-container class="dialog-container">
        <el-aside width="30%" class="input-aside">
          <el-header class="input-header">
            <el-popover
              placement="top"
              width="200px"
              trigger="manual"
              content="请输入关键词"
              v-model="itemDlg_user.searchkeyTipShow"
            >
              <el-input
                class="input-border-color"
                slot="reference"
                placeholder="请输入关键字"
                clearable
                v-model="itemDlg_user.searchkey"
                @keydown.enter.native="itemDlg_user_query"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="itemDlg_user_query"
                ></el-button>
              </el-input>
            </el-popover>
          </el-header>
          <el-main style="height: 346px">
            <el-tree
              :data="deptData.tree"
              node-key="id"
              :props="{ children: 'children', label: 'deptName' }"
              :expand-on-click-node="false"
              :default-expanded-keys="['1']"
              @node-click="itemDlg_user_loadByDept"
            >
            </el-tree>
          </el-main>
        </el-aside>
        <el-aside width="50%" class="table-aside">
          <el-table
            :data="itemDlg_user.searchList"
            v-loading="itemDlg_user.loading"
            style="width: 100%"
            max-height="386px"
            height="386px"
          >
            <el-table-column
              prop="realName"
              fixed
              label="员工姓名"
              width="80px"
            >
            </el-table-column>
            <el-table-column prop="deptName" label="部门" width="200px">
            </el-table-column>
            <el-table-column prop="employeeAccount" label="账号" width="100px">
            </el-table-column>
            <el-table-column prop="position" label="岗位" width="120px">
            </el-table-column>
            <el-table-column
              prop="title"
              fixed="right"
              label="操作"
              width="80px"
            >
              <template slot-scope="{ row }">
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="itemDlg_user_added(row.id)"
                  @click="itemDlg_user_add(row)"
                >
                  添加
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-aside>
        <el-aside width="20%" class="btn-aside">
          <el-header class="choose-header"> 已选择 </el-header>
          <el-main style="height: 346px; padding: 10px 0">
            <el-tag
              :key="user.id"
              v-for="user in itemDlg_user.items"
              style="margin: 10px 0 0 10px; height: auto; white-space: normal"
              closable
              size="small"
              @close="itemDlg_user_remove(user)"
            >
              {{ user.realName }}
            </el-tag>
          </el-main>
        </el-aside>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeItemDlg_user(false)">取 消</el-button>
        <el-button type="primary" @click="closeItemDlg_user(true)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="设置专属部门"
      :visible.sync="itemDlg_dept.visible"
      width="80%"
      top="calc( ( 100vh - 550px ) / 2 )"
      class="dlg_items"
    >
      <el-container class="dialog-container">
        <el-aside width="40%" class="input-aside">
          <el-header class="input-header">
            <el-popover
              placement="top"
              width="200px"
              trigger="manual"
              content="请输入关键词"
              v-model="itemDlg_dept.searchkeyTipShow"
            >
              <el-input
                class="input-border-color"
                slot="reference"
                placeholder="请输入关键字"
                clearable
                v-model="itemDlg_dept.searchkey"
                @keydown.enter.native="itemDlg_dept_query"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="itemDlg_dept_query"
                ></el-button>
              </el-input>
            </el-popover>
          </el-header>
          <el-main style="height: 309px">
            <el-tree
              :data="deptData.tree"
              node-key="id"
              :props="{ children: 'children', label: 'name' }"
              :expand-on-click-node="false"
              :default-expanded-keys="['1']"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    v-if="data.id != '1'"
                    class="addBtn"
                    type="text"
                    size="mini"
                    circle
                    icon="el-icon-circle-plus-outline"
                    @click="() => itemDlg_dept_add(data)"
                    :disabled="itemDlg_dept_added(data.id)"
                  >
                  </el-button>
                </span>
              </span>
            </el-tree>
          </el-main>
        </el-aside>
        <el-aside width="40%" class="table-aside">
          <el-table
            :data="itemDlg_dept.searchList"
            v-loading="itemDlg_dept.loading"
            style="width: 100%"
            max-height="346px"
            height="346px"
          >
            <el-table-column prop="title" label="部门">
              <template slot-scope="{ row }">
                <span>{{ deptpath(row.id) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="操作" width="80px">
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.id != '1'"
                  type="primary"
                  size="mini"
                  :disabled="itemDlg_dept_added(row.id)"
                  @click="itemDlg_dept_add(row)"
                >
                  添加
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-aside>
        <el-aside width="20%" class="btn-aside">
          <el-header class="choose-header"> 已选择 </el-header>
          <el-main style="height: 356px; padding: 10px 0">
            <el-tag
              :key="dept.id"
              v-for="dept in itemDlg_dept.items"
              style="margin: 10px 0 0 10px; height: auto; white-space: normal"
              closable
              size="small"
              @close="itemDlg_dept_remove(dept)"
            >
              {{ dept.deptName }}
            </el-tag>
          </el-main>
        </el-aside>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeItemDlg_dept(false)">取 消</el-button>
        <el-button type="primary" @click="closeItemDlg_dept(true)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  allDept,
  queryUserByDept,
  queryUser,
  queryDept,
  queryUserByKw,
} from "@/api/user";
const defaultItemDlgData_user = {
  visible: false,
  items: [],
  searchkey: "",
  searchkeyTipShow: false,
  loading: false,
  searchList: [],
};

const defaultItemDlgData_dept = {
  visible: false,
  items: [],
  searchkey: "",
  searchkeyTipShow: false,
  loading: false,
  searchList: [],
};

export default {
  name: "QuotaDetail",
  components: {},
  props: {
    //判断是否绑定formItem
    validateEvent: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "user",
    },
    value: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  data() {
    return {
      showClearIcon: false, //是否展示清楚icon
      loading: false, //提交按钮loading
      selectValues: [],
      itemserror_user: false,
      itemserror_dept: false,
      tempRoute: {},
      deptData: {
        tree: [],
        map: new Map(),
      },
      itemDlg_user: Object.assign({}, defaultItemDlgData_user),
      itemDlg_dept: Object.assign({}, defaultItemDlgData_dept),
    };
  },
  computed: {
    //是否展示清楚icon
    showClear() {
      const { clearable } = this._props;
      const { showClearIcon, selectValues } = this._data;
      return clearable && showClearIcon && selectValues.length > 0;
    },
    //是否展示placehodler
    placeholderShow() {
      return this._props.placeholder && this._data.selectValues.length == 0;
    },
    deptpath() {
      return (id) => {
        let path = "";
        while (true) {
          if (!id) break;
          if (id == "1" && path != "") break;
          let dept = this.deptData.map.get(id);
          if (!dept) break;
          if (path != "") path = "\\" + path;
          path = dept.deptName + path;
          id = dept.parentDeptId;
        }
        return path;
      };
    },
    itemDlg_user_added() {
      return (id) => {
        for (let i = 0; i < this.itemDlg_user.items.length; ++i) {
          if (this.itemDlg_user.items[i].id == id) return true;
        }
        return false;
      };
    },
    itemDlg_dept_added() {
      return (id) => {
        for (let i = 0; i < this.itemDlg_dept.items.length; ++i) {
          if (this.itemDlg_dept.items[i].id == id) return true;
        }
        return false;
      };
    },
  },
  created() {
    this.loadDeptData();
  },
  methods: {
    //清空数据
    clear() {
      this.$set(this, "selectValues", []);
      this.$emit("input", []);
      if (this.validateEvent) {
        this.$parent.$emit("el.form.change", []);
      }
    },
    //实现类似focus功能
    focusFun() {
      this.$set(this, "showClearIcon", !this._data.showClearIcon);
    },
    loadDeptData() {
      allDept()
        .then((response) => {
          let deptArray = response.data;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.id, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (
              item.parentDeptId &&
              item.parentDeptId != "" &&
              item.parentDeptId != "0"
            ) {
              parent = deptMap.get(item.parentDeptId);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
        })
        .catch((err) => {});
    },
    openItemDlg_user() {
      this.itemDlg_user = Object.assign({}, defaultItemDlgData_user, {
        visible: true,
        items: [...this.selectValues],
      });
    },
    closeItemDlg_user(save) {
      this.itemDlg_user.visible = false;
      if (save) {
        this.selectValues = this.itemDlg_user.items;
        const useridList = this.selectValues.map((item) => item.id);
        //触发input事件,将值绑定到v-model中
        this.$emit("input", useridList);
        //判断是否绑定formItem，有则触发表单项校验
        if (this.validateEvent) {
          this.$parent.$emit("el.form.change", [useridList]);
        }
      }
    },
    itemDlg_user_loadByDept(data) {
      this.itemDlg_user.loading = true;
      queryUserByKw("", data.id)
        .then((response) => {
          this.itemDlg_user.searchList = response.data.list;
          this.itemDlg_user.loading = false;
        })
        .catch((err) => {
          this.itemDlg_user.loading = false;
        });
    },
    itemDlg_user_query() {
      if (!this.itemDlg_user.searchkey) {
        this.itemDlg_user.searchkeyTipShow = true;
        setTimeout(() => {
          this.itemDlg_user.searchkeyTipShow = false;
        }, 2000);
        return;
      }
      this.itemDlg_user.loading = true;
      queryUserByKw(this.itemDlg_user.searchkey)
        .then((response) => {
          this.itemDlg_user.searchList = response.data.list || [];
          this.itemDlg_user.loading = false;
        })
        .catch((err) => {
          this.itemDlg_user.loading = false;
        });
    },
    itemDlg_user_add(user) {
      for (let i = 0; i < this.itemDlg_user.items.length; ++i) {
        if (this.itemDlg_user.items[i].id == user.id) return;
      }
      this.itemDlg_user.items.push(user);
    },
    itemDlg_user_remove(user) {
      for (let i = 0; i < this.itemDlg_user.items.length; ++i) {
        if (this.itemDlg_user.items[i].id == user.id) {
          this.itemDlg_user.items.splice(i, 1);
          return;
        }
      }
    },
    openItemDlg_dept() {
      this.itemDlg_dept = Object.assign({}, defaultItemDlgData_dept, {
        visible: true,
        items: [...this.selectValues],
      });
    },
    closeItemDlg_dept(save) {
      this.itemDlg_dept.visible = false;
      if (save) {
        this.selectValues = this.itemDlg_dept.items;
        const deptidList = this.selectValues.map((item) => item.id);
        this.$emit("input", deptidList);
        //判断是否绑定formItem，有则触发表单项校验
        if (this.validateEvent) {
          this.$parent.$emit("el.form.change", [deptidList]);
        }
      }
    },
    itemDlg_dept_query() {
      if (!this.itemDlg_dept.searchkey) {
        this.itemDlg_dept.searchkeyTipShow = true;
        setTimeout(() => {
          this.itemDlg_dept.searchkeyTipShow = false;
        }, 2000);
        return;
      }
      this.itemDlg_dept.loading = true;
      queryDept(this.itemDlg_dept.searchkey)
        .then((response) => {
          this.itemDlg_dept.searchList = response.data.list;
          this.itemDlg_dept.loading = false;
        })
        .catch((err) => {
          this.itemDlg_dept.loading = false;
        });
    },
    itemDlg_dept_add(dept) {
      for (let i = 0; i < this.itemDlg_dept.items.length; ++i) {
        if (this.itemDlg_dept.items[i].id == dept.id) return;
      }
      this.itemDlg_dept.items.push(dept);
    },
    itemDlg_dept_remove(dept) {
      for (let i = 0; i < this.itemDlg_dept.items.length; ++i) {
        if (this.itemDlg_dept.items[i].id == dept.id) {
          this.itemDlg_dept.items.splice(i, 1);
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
.input_error {
  border-color: #ff4949;
}
</style>
<style lang="less" scoped>
.select-comp-wrap {
  padding: 0;
  height: auto;
}
.close-icon {
  margin-right: 6px;
}
.preview-btn {
  padding: 0px 6px 0px 3px;
  width: 100%;
  height: 100%;
  min-height: 32px;
  vertical-align: middle;
  border: none;
  .preview-content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .preview-placeholder {
      color: #c0c4cc;
      padding-left: 11px;
      font-size: 13px;
    }
    .preview-content {
      white-space: normal;
      text-align: left;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .preview-tag {
      margin: 5px;
      white-space: normal;
      height: auto;
      display: flex;
      align-items: center;
    }
  }
  .icon {
    font-size: 16px;
    color: #dcdfe6;
  }
}

.dialog-container {
  height: 400px;
  border: 1px solid #eee;
  .input-aside {
    border-right: 1px solid #eee;
    height: 100%;
    padding: 0;
    background-color: #ffffff;
  }
  .input-header {
    text-align: right;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
  }
  .table-aside {
    height: 100%;
    padding: 5px 10px;
    background-color: #ffffff;
  }
  .input-border-color {
    margin-top: 8px;
    /deep/ .el-input__inner {
      border-color: #b4bccc;
    }
  }
  .btn-aside {
    border-left: 1px solid #eee;
    height: 100%;
    padding: 0;
    background-color: #ffffff;
  }
  .choose-header {
    text-align: center;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
  }
}

.dlg_items .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
}
.el-tree {
  display: inline-block;
  min-width: 100%;
}
.dlg_items .el-dialog {
  min-width: 660px;
}
.el-tabs__nav-scroll {
  padding-left: 50px;
}
.el-tabs__header {
  margin-bottom: 0px;
}
.addBtn .el-icon-circle-plus-outline {
  font-size: 16px;
}
</style>
