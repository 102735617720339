var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AuthCheck",
        { attrs: { permission: "gtmsh-point-server.role.save" } },
        [
          _c(
            "router-link",
            { attrs: { to: "/integral/role-add" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "primary" },
                },
                [_vm._v("新增角色")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("table-base-page", {
        ref: "basePage",
        attrs: {
          columns: _vm.columns,
          searchFileds: _vm.searchFileds,
          searchBtnText: _vm.searchBtnText,
          exportService: _vm.exportService,
          buttons: _vm.buttons,
          loadTableData: _vm.loadTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }