<template>
  <reason-detail :is-edit="false" v-if="hasAuth" />
  <NoAuthPage v-else></NoAuthPage>
</template>

<script>
import ReasonDetail from "./components/ReasonDetail";
import NoAuthPage from "@/components/AuthCheck/NoAuthPage";
import { checkAuth } from "@/utils/auth";

export default {
  name: "EditReasonForm",
  components: { ReasonDetail, NoAuthPage },
  data() {
    return {
      hasAuth: false,
    };
  },
  created() {
    this.hasAuth = checkAuth("gtmsh-point-server.award.event.save");
  },
};
</script>
