<template>
  <div>
    <AuthCheck permission="gtmsh-point-server.role.save">
      <router-link to="/integral/role-add">
        <el-button type="primary" style="margin-bottom: 20px"
          >新增角色</el-button
        >
      </router-link>
    </AuthCheck>
    <table-base-page
      :columns="columns"
      :searchFileds="searchFileds"
      :searchBtnText="searchBtnText"
      :exportService="exportService"
      :buttons="buttons"
      :loadTableData="loadTableData"
      ref="basePage"
    ></table-base-page>
  </div>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import TableBasePage from "@/components/TableBasePage";
import { quotaRepealDetail } from "@/api/quota";
import { roleList, deleteRole } from "@/api/user";
import AuthCheck from "@/components/AuthCheck";

export default {
  name: "QuotaAllotList",
  filters: {},
  directives: { waves },
  components: {
    TableBasePage,
    AuthCheck,
  },
  data() {
    return {
      exportService: "revoke/award/get", //导出接口名
      searchBtnText: "查询", //查询按钮的文案
      buttons: ["search", "reset"], //表格展示按钮组
      columns: [
        {
          label: "角色名称",
          prop: "name",
        },
        {
          label: "角色描述",
          prop: "description",
        },
        {
          label: "操作",
          prop: "operate",
          align: "center",
          render: (createdAt, scope, h) => {
            return scope.row.id == 1 ? (
              <div>
                {/* <AuthCheck permission="gtmsh-point-server.role.get"> */}
                  <router-link
                    to={{
                      path: "/integral/role-view",
                      query: { roleId: scope.row.id },
                    }}
                  >
                    <el-button type="primary" size="mini">
                      查看
                    </el-button>
                  </router-link>
                {/* </AuthCheck> */}
              </div>
            ) : (
              <div>
                {/* <AuthCheck permission="gtmsh-point-server.role.get"> */}
                  <router-link
                    to={{
                      path: "/integral/role-view",
                      query: { roleId: scope.row.id },
                    }}
                  >
                    <el-button type="primary" size="mini">
                      查看
                    </el-button>
                  </router-link>
                {/* </AuthCheck> */}

                <AuthCheck permission="gtmsh-point-server.role.update">
                  <router-link
                    to={{
                      path: "/integral/role-edit",
                      query: { roleId: scope.row.id },
                    }}
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      style="margin-left:10px;"
                      to={{
                        query: { disabled: true },
                      }}
                    >
                      编辑
                    </el-button>
                  </router-link>
                </AuthCheck>
                <AuthCheck permission="gtmsh-point-server.role.delete">
                  <el-popconfirm
                    style="margin-left:10px;"
                    title="删除后角色将无法恢复，请谨慎操作，确定要删除吗？"
                    on={{ onConfirm: () => this.deleteData(scope.row.id) }}
                  >
                    <el-button slot="reference" size="mini" type="danger">
                      删除
                    </el-button>
                  </el-popconfirm>
                </AuthCheck>
              </div>
            );
          },
        },
      ],
      searchFileds: [
        {
          label: "角色名称：",
          prop: "name",
          type: "input",
        },
      ],
      form: [
        {
          label: "角色名称",
          prop: "userName",
        },
        {
          label: "角色描述",
          prop: "userLoginNo",
        },
        {
          label: "员工数量",
          prop: "deptName",
        },
      ],
      detail: {},
    };
  },
  computed: {},
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event) {
        const { keyCode } = event;
        if (keyCode && keyCode == 13) {
          this.$refs.basePage.initTableData();
        }
      }
    },
    toDetail(row, type) {
      const { awardid } = row;
      quotaRepealDetail({ awardid }).then((rsp) => {
        // row.files = rsp.data
        this.$set(this, "detail", rsp.data[0]);
        //   this.$refs.repealModal.show()
      });
    },
    loadTableData(params) {
      const { name } = params;
      const query = {
        ...params,
        name: name?.trim(),
      };
      return new Promise((resolve, reject) => {
        roleList(query).then((res) => {
          resolve(res.data);
        });
      });
    },
    deleteData(data) {
      deleteRole({ id: data }).then((rsp) => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.$refs.basePage.initTableData();
      });
    },
  },
};
</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
