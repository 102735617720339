var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()],
            1
          ),
          _c("app-main"),
          _vm._m(0),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div-bottom" }, [
      _c("div", { staticClass: "div-contact-us" }, [
        _c(
          "a",
          {
            staticClass: "span-bottom-tip",
            attrs: { href: "https://www.gtmsh.com/" },
          },
          [_vm._v("官网首页")]
        ),
        _c(
          "a",
          {
            staticClass: "span-bottom-tip",
            attrs: {
              href: "https://www.gtmsh.com/index.php?c=article&a=type&tid=64",
            },
          },
          [_vm._v("关于我们")]
        ),
      ]),
      _c("div", { staticClass: "span-bottom-tip2" }, [
        _vm._v(
          " 深圳棠人科技有限公司 版权所有CopyRight © 2023 ALL RIGHTS RESERVED "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }