<template>
  <el-container style="height: calc(100vh - 84px)">
    <el-aside
      :style="{
        width: treeWidth,
        'border-right': '1px solid #eee',
        height: '100%',
        padding: 0,
        'background-color': '#ffffff',
      }"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
      >
        <div>
          <el-input
            style="width: 250px"
            slot="reference"
            placeholder="输入部门"
            clearable
            v-model="branchName"
            @input="branchSearchChange"
            @keydown.enter.native="searchBranch"
          >
            <el-button
              slot="append"
              type="primary"
              class="inner-btn"
              icon="el-icon-search"
              style="background: #409eff; color: #fff; border-radius: 0"
              @click="searchBranch"
            ></el-button>
          </el-input>
        </div>
      </el-header>
      <el-main>
        <el-tree
          :data="deptData.tree"
          node-key="id"
          :props="{ children: 'children', label: 'deptName' }"
          :expand-on-click-node="false"
          :default-expanded-keys="['1']"
          highlight-current
          @node-click="loadByDept"
          :filter-node-method="filterNode"
          ref="tree"
        >
        </el-tree>
      </el-main>
    </el-aside>
    <el-aside
      :style="{
        width: `calc(100% - ${treeWidth})`,
        height: '100%',
        padding: '5px 10px',
        'background-color': '#ffffff',
      }"
    >
      <el-header
        style="
          font-size: 12px;
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #eee;
          padding-right: 0;
        "
        class="header"
      >
        <div class="filter-item-div2">
          <span>员工姓名：</span>
          <el-popover
            placement="top"
            trigger="manual"
            content="请输入员工姓名"
            v-model="searchkeyTipShow"
          >
            <el-input
              style="width: 150px"
              slot="reference"
              placeholder="请输入员工姓名"
              clearable
              v-model="searchkey"
              @keydown.enter.native="query2"
            >
            </el-input>
          </el-popover>
        </div>

        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item btn"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="query2"
        >
          查询
        </el-button>
        <el-button
          v-waves
          class="filter-item btn"
          style="margin-left: 0px"
          icon="el-icon-refresh-right"
          @click="handleReset"
        >
          重置
        </el-button>
        <AuthCheck permission="gtmsh-point-server.employee.import">
          <el-button
            v-waves
            class="filter-item btn"
            @click="showImportDialog"
            style="margin-left: 0px"
          >
            导入员工
          </el-button>
        </AuthCheck>
        <AuthCheck permission="gtmsh-point-server.employee.export">
          <el-button
            :loading="exportRun"
            v-waves
            class="filter-item btn"
            style="margin-left: 0px"
            @click="exportExcel"
          >
            导出
          </el-button>
        </AuthCheck>
        <AuthCheck permission="gtmsh-point-server.employee.save">
          <router-link to="/integral/employee-add">
            <el-button type="primary" class="filter-item btn"
              >新增员工</el-button
            >
          </router-link>
        </AuthCheck>
        <AuthCheck permission="gtmsh-point-server.employee.import">
          <el-button
            v-waves
            class="filter-item btn"
            style="margin-left: 0px"
            type="success"
            @click="downloadTemplate"
          >
            下载导入模板
          </el-button>
        </AuthCheck>
      </el-header>
      <el-table :data="searchList" v-loading="loading" style="width: 100%">
        <el-table-column prop="employeeAccount" label="账号" width="100px">
        </el-table-column>
        <!-- <el-table-column prop="mobile" fixed label="手机号" width="200px">
        </el-table-column> -->
        <el-table-column prop="realName" label="员工姓名" width="100px">
        </el-table-column>
        <el-table-column prop="nickName" label="别名" width="100px">
        </el-table-column>
        <el-table-column prop="employeeNo" label="员工工号" width="100px">
        </el-table-column>
        <el-table-column prop="positionLevelName" label="职位" width="100px">
        </el-table-column>
        <el-table-column prop="position" label="职务" width="100px">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="150px">
        </el-table-column>
        <el-table-column prop="centerName" label="所属中心" width="150px">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80px">
          <template slot-scope="{ row }">
            <span>{{ row.status == 1 ? "有效" : "禁用" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" fixed="right" label="操作" width="260px">
          <template slot-scope="{ row }">
            <!-- <AuthCheck permission="gtmsh-point-server.employee.get"> -->
              <router-link
                :to="{
                  path: '/integral/employee-view',
                  query: { employeeId: row.id },
                }"
              >
                <el-button type="primary" size="mini"> 查看 </el-button>
              </router-link>
            <!-- </AuthCheck> -->
            <AuthCheck permission="gtmsh-point-server.employee.update">
              <router-link
                :to="{
                  path: '/integral/employee-edit',
                  query: { employeeId: row.id },
                }"
              >
                <el-button type="primary" size="mini" style="margin-left: 10px">
                  编辑
                </el-button>
              </router-link>
            </AuthCheck>
            <AuthCheck permission="gtmsh-point-server.employee.disable">
              <el-popconfirm
                style="margin-left: 10px"
                title="离职后额度及内容将无法恢复，请谨慎操作，确定要离职吗？"
                @onConfirm="handleDelete(row.id)"
                v-if="row.status == 1"
              >
                <el-button slot="reference" size="mini" type="danger">
                  离职
                </el-button>
              </el-popconfirm>
            </AuthCheck>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="query.pageNum"
        :limit.sync="query.pageSize"
        @pagination="getList"
      />
      <i
        style="
          position: absolute;
          top: 50%;
          cursor: pointer;
          margin-left: -24px;
          font-size: 25px;
        "
        :class="openTree ? 'el-icon-caret-left' : 'el-icon-caret-right'"
        @click="updateOpenTree"
      ></i>
    </el-aside>
    <el-dialog
      title="导入员工"
      :visible.sync="importDialog.visible"
      width="30%"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items2"
      v-if="importDialog.visible"
    >
      <div
        v-loading="uploadLoading"
        style="display: flex; align-items: center; flex-direction: column"
      >
        <el-upload
          ref="upload"
          class="upload-demo"
          drag
          action="/api/v1/gtmsh-point/backend/employee/import"
          :limit="1"
          accept=".xlsx,.xls"
          :headers="headerObj"
          :auto-upload="false"
          :on-change="onUploadChange"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div class="div-btn">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="uploadExcel">上传</el-button>
          <el-button v-if="errorFileId" type="danger" @click="downloadError"
            >下载失败文件</el-button
          >
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import {
  queryintegralbydept,
  queryintegral,
  queryusermonthaward,
} from "@/api/quota";
import { allDept, getUserList, disabledEmployee } from "@/api/user";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import { getToken } from "@/utils/auth";
import download from "@/utils/download";
import AuthCheck from "@/components/AuthCheck";
export default {
  name: "QuotaQuery",
  components: { Pagination, AuthCheck },
  filters: {},
  directives: { waves },
  data() {
    return {
      items: [],
      searchkey: "",
      searchkeyTipShow: false,
      loading: false,
      total: 0,
      searchList: [],
      month: new Date(),
      branchName: "", //部门名字
      deptData: {
        tree: [],
        map: new Map(),
      },
      query: {
        deptName: "",
        deptId: "",
        pageNum: 1,
        pageSize: 20,
      },
      importDialog: {
        visible: false,
        loading: false,
      },
      exportRun: false,
      openTree: true,
      treeWidth: "280px",
      headerObj: {
        "x-http-token": getToken(),
        "x-http-app-code": "BACKEND",
      },
      errorFileId: "",
      uploadLoading: false,
    };
  },
  created() {
    this.loadDeptData();
  },
  computed: {},
  methods: {
    downloadError() {
      const url = `/excel/import/download-result/${this.errorFileId}`;
      download({
        method: "get",
        url: url,
        responseType: "blob", // 设置响应类型为blob，以便下载文件
      })
        .then((response) => {})
        .catch((error) => {
          console.error("下载文件失败：", error);
        });
    },
    downloadTemplate() {
      download({
        method: "post",
        url: "/excel/import/download-template?code=employee-sync-excel-import",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "员工导入模板.xlsx",
      });
    },
    beforeUpload(file) {
      if (file) {
        this.uploadLoading = true;
      }
      return true;
    },
    onUploadChange(file, flieList) {
      if (file) {
        this.uploadLoading = false;
        const { response } = file;
        if (response) {
          this.$refs.upload.clearFiles();
          const { code, message, data } = response;
          if (code == 200) {
            const { excelImportFiles } = data;
            if (excelImportFiles && excelImportFiles.length > 0) {
              const { hasErrorFile, id } = excelImportFiles[0];
              if (hasErrorFile == 1 && id) {
                this.errorFileId = id;
                this.$message({
                  type: "error",
                  message: "上传失败，请下载失败文件查看详情",
                });
              } else {
                this.$message({
                  type: "success",
                  message: "上传成功",
                });
                this.importDialog.visible = false;
                this.handleReset();
              }
            } else {
              this.$message({
                type: "success",
                message: "上传成功",
              });
              this.importDialog.visible = false;
              this.handleReset();
            }
          } else {
            this.$message({
              type: "error",
              message: message || "上传失败",
            });
          }
        }
      }
    },
    closeDialog() {
      this.uploadLoading = false;
      this.importDialog.visible = false;
    },
    uploadExcel() {
      this.$refs.upload.submit();
    },
    showImportDialog() {
      this.uploadLoading = false;
      this.errorFileId = "";
      this.importDialog.visible = true;
    },
    handleDelete(id) {
      disabledEmployee({ id }).then((rsp) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.getList();
      });
    },

    updateOpenTree() {
      if (this.openTree) {
        this.openTree = false;
        this.treeWidth = "10px";
      } else {
        this.openTree = true;
        this.treeWidth = "280px";
      }
    },
    branchSearchChange(value) {
      if (!value) {
        this.$refs.tree.filter(value);
      }
    },
    //查询部门
    searchBranch() {
      const { branchName } = this._data;
      this.$refs.tree.filter(branchName);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.deptName.indexOf(value) !== -1;
    },
    deptpath(id) {
      let path = "";
      while (true) {
        if (!id) break;
        if (id == "1" && path != "") break;
        let dept = this.deptData.map.get(id);
        if (!dept) break;
        if (path != "") path = "\\" + path;
        path = dept.deptName + path;
        id = dept.parentDeptId;
      }
      return path;
    },
    getMonthStr(month) {
      let m = month.getMonth() + 1;
      if (m < 10) m = "0" + m;
      else m = "" + m;
      return month.getFullYear() + m;
    },
    loadDeptData() {
      allDept({})
        .then((response) => {
          let deptArray = response.data;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.id, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (
              item.parentDeptId &&
              item.parentDeptId != "" &&
              item.parentDeptId != "0"
            ) {
              parent = deptMap.get(item.parentDeptId);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
          this.getList();
        })
        .catch((err) => {});
    },
    getList() {
      this.loading = true;
      getUserList(this.query)
        .then((response) => {
          this.searchList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    loadByDept(data) {
      this.query = {
        deptId: data.id || 1,
        realName: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },
    handleReset() {
      this.searchkey = "";
      this.query = {
        realName: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },

    query2() {
      // if (!this.searchkey) {
      //   this.searchkeyTipShow = true;
      //   setTimeout(() => {
      //     this.searchkeyTipShow = false;
      //   }, 2000);
      //   return;
      // }
      this.query = {
        realName: this.searchkey || "",
        pageNum: 1,
        pageSize: 20,
      };
      this.getList();
    },
    showInfo(userMonthIntegral) {
      this.infoDlg = {
        visible: true,
        month: this.query.month,
        userid: userMonthIntegral.userid,
        loading: false,
        list: [],
      };
      this.infoDlg.loading = true;
      queryusermonthaward(this.infoDlg.userid, this.infoDlg.month)
        .then((response) => {
          this.infoDlg.list = response.data.list;
          this.infoDlg.loading = false;
        })
        .catch((err) => {
          this.infoDlg.loading = false;
        });
    },
    exportExcel() {
      download({
        method: "post",
        url: "/backend/employee/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "员工管理-导出.xlsx",
        data: this.query,
      });
    },
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
.dlg_items2 .el-dialog__body {
  padding: 10px 20px 20px 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dlg_items2 .el-dialog {
  min-width: 660px;
}

.div-btn {
  margin-top: 20px;
}
.header {
  display: flex;
  align-items: center;
}
.btn {
  height: 32px;
}
.filter-item-div {
  margin-right: 20px;
  min-width: 260px;
}
.filter-item-div2 {
  margin-right: 20px;
  min-width: 160px;
}
.filter-item {
  margin-right: 20px;
}
</style>
