<template>
  <div>
    <AuthCheck permission="gtmsh-point-server.employeePosition.save">
      <router-link to="/integral/position-add">
        <el-button type="primary" style="margin-bottom: 20px"
          >新增职位</el-button
        >
      </router-link>
    </AuthCheck>
    <table-base-page
      :columns="columns"
      :searchFileds="searchFileds"
      :searchBtnText="searchBtnText"
      :exportService="exportService"
      :buttons="buttons"
      :loadTableData="loadTableData"
      ref="basePage"
    ></table-base-page>
  </div>
</template>

<script>
import waves from "@/directive/waves"; // waves directive
import TableBasePage from "@/components/TableBasePage";
import {
  getPositionPage,
  deletePosition,
  updatePositionStatus,
} from "@/api/user";
import AuthCheck from "@/components/AuthCheck";
import { checkAuth } from "@/utils/auth";

export default {
  name: "PositionList",
  filters: {},
  directives: { waves },
  components: {
    TableBasePage,
    AuthCheck,
  },
  data() {
    return {
      exportService: "revoke/award/get", //导出接口名
      searchBtnText: "查询", //查询按钮的文案
      buttons: ["search", "reset"], //表格展示按钮组
      columns: [
        // {
        //   label: "职位id",
        //   prop: "id",
        // },
        {
          label: "职位名称",
          prop: "positionName",
        },
        {
          label: "赞币基数",
          prop: "pointBase",
        },
        {
          label: "状态",
          prop: "positionStatus",
          align: "center",
          render: (createdAt, scope, h) => {
            return (
              <el-switch
                on={{
                  change: () => this.changeStatus(scope.row.id),
                  disabled: true,
                }}
                value={scope.row.positionStatus == 1}
                active-color="#13ce66"
                inactive-color="#ff4949"
                disabled={this.getDisabled()}
              ></el-switch>
            );
          },
        },
        {
          label: "操作",
          prop: "operate",
          align: "center",
          render: (createdAt, scope, h) => {
            return (
              <div>
                {/* <AuthCheck permission="gtmsh-point-server.employeePosition.detail"> */}
                  <router-link
                    to={{
                      path: "/integral/position-view",
                      query: { positionId: scope.row.id },
                    }}
                  >
                    <el-button type="primary" size="mini">
                      查看
                    </el-button>
                  </router-link>
                {/* </AuthCheck> */}
                <AuthCheck permission="gtmsh-point-server.employeePosition.update">
                  <router-link
                    to={{
                      path: "/integral/position-edit",
                      query: { positionId: scope.row.id },
                    }}
                  >
                    <el-button
                      type="primary"
                      size="mini"
                      style="margin-left:10px;"
                    >
                      编辑
                    </el-button>
                  </router-link>
                </AuthCheck>
                <AuthCheck permission="gtmsh-point-server.employeePosition.delete">
                  <el-popconfirm
                    style="margin-left:10px;"
                    title="删除后职位将无法恢复，请谨慎操作，确定要删除吗？"
                    on={{ onConfirm: () => this.deleteData(scope.row.id) }}
                  >
                    <el-button slot="reference" size="mini" type="danger">
                      删除
                    </el-button>
                  </el-popconfirm>
                </AuthCheck>
              </div>
            );
          },
        },
      ],
      searchFileds: [
        {
          label: "职位名称：",
          prop: "positionName",
          type: "input",
        },
      ],
      form: [
        {
          label: "职位名称",
          prop: "positionName",
        },
      ],
      detail: {},
    };
  },
  computed: {},
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    getDisabled() {
      return !checkAuth("gtmsh-point-server.employeePosition.update-status");
    },
    handleKeyPress(event) {
      if (event) {
        const { keyCode } = event;
        if (keyCode && keyCode == 13) {
          this.$refs.basePage.initTableData();
        }
      }
    },
    changeStatus(id) {
      updatePositionStatus({ id }).then((rsp) => {
        this.$message({
          type: "success",
          message: "状态修改成功",
        });
        this.$refs.basePage.initTableData();
      });
    },
    loadTableData(params) {
      const { name } = params;
      const query = {
        ...params,
        name: name?.trim(),
      };
      return new Promise((resolve, reject) => {
        getPositionPage(query).then((res) => {
          resolve(res.data);
        });
      });
    },
    deleteData(data) {
      deletePosition({ id: data }).then((rsp) => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.$refs.basePage.initTableData();
      });
    },
  },
};
</script>

<style scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
</style>
