<template>
  <div class="welcome-container">
      <div class="title-container">
        <h3 class="title">欢迎使用圣恩赞币系统后台</h3>
      </div>
  </div>
  <!--
  <div class="dashboard-container">
    <component :is="currentRole" />
  </div>
  -->
</template>

<script>
import { mapGetters } from 'vuex'
import adminDashboard from './admin'
import editorDashboard from './editor'

export default {
  name: 'Dashboard',
  components: { adminDashboard, editorDashboard },
  data() {
    return {
      currentRole: 'adminDashboard'
    }
  },
  computed: {
    ...mapGetters([
      'roles'
    ])
  },
  created() {
    if (!this.roles.includes('admin')) {
      this.currentRole = 'editorDashboard'
    }
  }
}
</script>


<style lang="scss" scoped>
$bg:#2d3a4b;
$light_gray:#eee;

.welcome-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .title-container {
    position: relative;

    .title {
      font-size: 38px;
      color: $bg;
      margin: 100px auto 80px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}


</style>
