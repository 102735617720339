var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: {
            "min-width": "560px",
            "margin-left": "30px",
            "margin-top": "30px",
            width: "90%",
          },
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c("form-input", {
            attrs: {
              laeblName: "职位名称",
              keyStr: "positionName",
              required: true,
              inputData: _vm.dataForm.positionName,
              disabled: _vm.type == "view",
            },
            on: { updateInputData: _vm.updateInputData },
          }),
          _c(
            "el-form-item",
            { attrs: { label: "赞币基数", prop: "pointBase" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { disabled: _vm.type == "view" },
                model: {
                  value: _vm.dataForm.pointBase,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "pointBase", _vm._n($$v))
                  },
                  expression: "dataForm.pointBase",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "positionStatus" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  disabled: _vm.type == "view",
                },
                model: {
                  value: _vm.dataForm.positionStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "positionStatus", $$v)
                  },
                  expression: "dataForm.positionStatus",
                },
              }),
            ],
            1
          ),
          _vm.type != "view"
            ? _c(
                "el-form-item",
                { staticStyle: { "margin-top": "50px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "50px" },
                      attrs: { type: "primary", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" 保 存 ")]
                  ),
                  _vm.type != "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_vm._v(" 取 消 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }