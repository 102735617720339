<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="query.toEmployeeName"
        placeholder="被点赞人"
        clearable
        style="width: 100px"
        class="filter-item"
      />
      <el-select
        v-model="query.eventId"
        placeholder="事件"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="reason in reasons"
          :key="reason.id"
          :label="reason.eventName"
          :value="reason.id"
          class="filter-item"
        />
      </el-select>
      <el-date-picker
        v-model="query.startTime"
        type="date"
        style="width: 140px"
        placeholder="点赞开始日期"
        class="filter-item"
      ></el-date-picker>
      <el-date-picker
        v-model="query.endTime"
        type="date"
        style="width: 140px"
        placeholder="点赞结束日期"
        class="filter-item"
      ></el-date-picker>
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 20px"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        查找
      </el-button>
      <el-button
        v-waves
        class="filter-item"
        icon="el-icon-refresh-right"
        @click="resetFilter"
      >
        重置
      </el-button>
      <AuthCheck permission="gtmsh-point-server.award.detail.export">
        <el-button
          :loading="exportRun"
          v-waves
          class="filter-item"
          style="margin-left: 0px"
          @click="exportExcel"
        >
          导出
        </el-button>
      </AuthCheck>

      <AuthCheck permission="gtmsh-point-server.revoke.batch">
        <el-button v-waves class="filter-item" @click="showBatchRevokeDlg">
          批量撤销
        </el-button>
      </AuthCheck>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="被点赞人" align="center" min-width="50px">
        <template slot-scope="{ row }">
          {{ row.toEmployeeName }}
        </template>
      </el-table-column>
      <el-table-column label="账号" align="center" min-width="50px">
        <template slot-scope="{ row }">
          {{ row.toEmployeeAccount }}
        </template>
      </el-table-column>
      <el-table-column label="部门" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.toDeptName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="职务" align="center" min-width="80px">
        <template slot-scope="{ row }">
          {{ row.toPositionDictName }}
        </template>
      </el-table-column>
      <el-table-column label="事件名称" align="center" min-width="50px">
        <template slot-scope="{ row }">
          {{ row.eventName }}
        </template>
      </el-table-column>
      <el-table-column label="获得赞币" align="center" min-width="80px">
        <template slot-scope="{ row }">
          {{ row.awardNum }}
        </template>
      </el-table-column>
      <el-table-column label="点赞事由" align="center" width="200px">
        <template slot-scope="{ row }">
          <el-tooltip
            v-if="row.awardContent && row.awardContent.length > 26"
            class="item-ellipsis"
            popper-class="item-ellipsis"
            effect="dark"
            :content="row.awardContent"
            placement="top"
          >
            <div class="span-ellipsis">{{ row.awardContent }}</div>
          </el-tooltip>
          <div class="span-ellipsis" v-else>{{ row.awardContent }}</div>
        </template>
      </el-table-column>
      <el-table-column label="附件" align="center" min-width="120px">
        <template slot-scope="{ row }">
          <div
            v-for="media in row.awardFileUrlList"
            :key="'i_' + media.id"
            style="
              width: 64px;
              height: 64px;
              margin-right: 10px;
              display: inline-block;
            "
          >
            <el-image
              v-if="media.attachmentType == 1"
              style="width: 64px; height: 64px"
              :src="getUrl(media.attachmentId)"
              :preview-src-list="[getUrl(media.attachmentId)]"
              fit="cover"
            ></el-image>
            <div
              v-else
              @click="openVideoDlg(getUrl(media.attachmentId))"
              style="
                position: relative;
                display: inline-block;
                width: 64px;
                height: 64px;
              "
            >
              <div
                style="width: 64px; height: 64px; background-color: #999"
                fit="cover"
              ></div>
              <i class="el-icon-caret-right play_btn_inline2" style="" />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="点赞人" align="center" min-width="50px">
        <template slot-scope="{ row }">
          {{ row.fromEmployeeName }}
        </template>
      </el-table-column>
      <el-table-column label="时间" align="center" width="140px">
        <template slot-scope="{ row }">
          <span>{{ row.createdAt | parseTime("{y}-{m}-{d} {h}:{i}") }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="title" fixed="right" label="操作" width="80px">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="showInfo(row)">
            详情
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="query.pageNum"
      :limit.sync="query.pageSize"
      @pagination="getList"
    />
    <el-dialog
      v-if="infoDlg.award"
      title="点赞信息详情"
      :visible.sync="infoDlg.visible"
      width="60%"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items"
    >
      <div class="dlg_content">
        <div class="top">
          <div class="top_item">
            <div class="item">
              <div class="name">姓名：</div>
              <div class="val">{{ infoDlg.award.userName }}</div>
            </div>
            <div class="item">
              <div class="name">部门：</div>
              <div class="val">{{ infoDlg.award.deptName }}</div>
            </div>
            <div class="item">
              <div class="name">事件名称：</div>
              <div class="val">{{ infoDlg.award.reasonName }}</div>
            </div>
            <div class="item">
              <div class="name">点赞人：</div>
              <div class="val">{{ infoDlg.award.userName2 }}</div>
            </div>
          </div>
          <div class="top_item">
            <div class="item">
              <div class="name">账号：</div>
              <div class="val">{{ infoDlg.award.userLoginNo }}</div>
            </div>
            <div class="item">
              <div class="name">岗位：</div>
              <div class="val">{{ infoDlg.award.title }}</div>
            </div>
            <div class="item">
              <div class="name">获得赞币：</div>
              <div class="val">{{ infoDlg.award.integral }}</div>
            </div>
            <div class="item">
              <div class="name">操作时间：</div>
              <div class="val">
                {{ infoDlg.award.createdAt | parseTime("{y}-{m}-{d} {h}:{i}") }}
              </div>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="name">点赞事由：</div>
          <div class="val">{{ infoDlg.award.content }}</div>
        </div>
        <div class="bottom">
          <div class="name">附件：</div>
          <div class="val">
            <template v-if="infoDlg.contentmedialist.length > 0">
              <div
                v-for="media in infoDlg.contentmedialist"
                :key="'i_' + media.id"
                style="
                  width: 128px;
                  height: 128px;
                  margin-right: 10px;
                  display: inline-block;
                "
              >
                <el-image
                  v-if="media.mediatype == 1"
                  style="width: 128px; height: 128px"
                  :src="getUrl(media.attachmentId)"
                  :preview-src-list="[getUrl(media.attachmentId)]"
                  fit="cover"
                ></el-image>
                <div
                  v-else
                  @click="openVideoDlg(getUrl(media.attachmentId))"
                  style="
                    position: relative;
                    display: inline-block;
                    width: 128px;
                    height: 128px;
                  "
                >
                  <el-image
                    style="width: 128px; height: 128px"
                    :src="media.thumburl"
                    fit="cover"
                  >
                  </el-image>
                  <i class="el-icon-caret-right play_btn_inline" style="" />
                </div>
              </div>
            </template>
            <template v-else> 无 </template>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="videoDlg.visible"
      width="60%"
      top="20vh"
      custom-class="dlg_video"
      :destroy-on-close="true"
      :show-close="false"
      @close="onVideoDlgClose()"
    >
      <video
        :src="videoDlg.url"
        controls="controls"
        autoplay="autoplay"
        style="width: 100%; height: 60vh; background-color: #000000"
      >
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
    <el-dialog
      v-loading="revokeLoading"
      element-loading-text="正在撤销中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.3)"
      title="请输入撤销理由"
      :visible.sync="batchRevokeDlg.visible"
      width="40%"
      :destroy-on-close="true"
      :show-close="true"
      @close="onBatchRevokeDlgClose()"
      top="calc( ( 30vh - 84px ) / 2 )"
      class="dlg_items"
    >
      <el-input
        v-model="batchRevokeDlg.reason"
        placeholder="输入15个字内的撤销理由"
        clearable
        maxlength="15"
        class="filter-item"
      />
      <div style="text-align: right; margin-top: 20px">
        <el-button v-waves @click="onBatchRevokeDlgClose"> 取消 </el-button>
        <el-button v-waves type="primary" @click="batchRevoke">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryAllReason } from "@/api/reason";
import {
  queryaward,
  queryDetail,
  requestRevoke,
  exportAward,
} from "@/api/award";
import { allDept } from "@/api/user";
import waves from "@/directive/waves";
import { parseTime } from "@/utils";
import Pagination from "@/components/Pagination";
import moment from "moment";
import AuthCheck from "@/components/AuthCheck";
import download from "@/utils/download";

export default {
  name: "AwardList",
  components: { Pagination, AuthCheck },
  filters: {},
  directives: { waves },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: false,
      reasons: [],
      query: {
        toEmployeeName: null,
        // dayrange: [null, null],
        startTime: null,
        endTime: null,
        eventId: null,
        pageNum: 1,
        pageSize: 20,
      },
      lastquery: {
        toEmployeeName: null,
        // dayrange: [null, null],
        startTime: null,
        endTime: null,
        eventId: null,
      },
      deptData: {
        tree: [],
        map: new Map(),
      },
      infoDlg: {
        visible: false,
        award: null,
      },
      exportRun: false,
      videoDlg: {
        visible: false,
        url: null,
      },
      multipleSelection: [],
      batchRevokeDlg: {
        visible: false,
        reason: "点赞行为描述不合规。",
      },
      revokeLoading: false,
    };
  },
  computed: {},
  created() {
    this.loadDeptData();
    this.getList();
    this.getAllReasons();
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyPress);
  },
  methods: {
    handleKeyPress(event) {
      if (event) {
        const { keyCode } = event;
        if (keyCode && keyCode == 13) {
          this.handleFilter();
        }
      }
    },
    getUrl(attachmentId) {
      return `${process.env.VUE_APP_BASE_URL}/api/v1/gtmsh-point/backend/attachment/file/download/nologin?attachmentId=${attachmentId}`;
    },
    deptpath(deptid) {
      let path = "";
      while (true) {
        if (!deptid) break;
        if (deptid == "1" && path != "") break;
        let dept = this.deptData.map.get(deptid);
        if (!dept) break;
        if (path != "") path = "\\" + path;
        path = dept.name + path;
        deptid = dept.parentdeptid;
      }
      return path;
    },
    getList() {
      const { startTime, endTime } = this.query;
      const params = {
        ...this.query,
        startTime: startTime
          ? startTime instanceof moment
            ? startTime.format("YYYY-MM-DD HH:mm:sss")
            : moment(startTime).format("YYYY-MM-DD HH:mm:ss")
          : null,
        endTime: endTime
          ? endTime instanceof moment
            ? endTime
                .add(23, "hours")
                .add(59, "minutes")
                .add(59, "seconds")
                .format("YYYY-MM-DD HH:mm:ss")
            : moment(endTime)
                .add(23, "hours")
                .add(59, "minutes")
                .add(59, "seconds")
                .format("YYYY-MM-DD HH:mm:ss")
          : null,
      };
      if (params.startTime && params.endTime) {
        if (moment(params.endTime).isBefore(moment(params.startTime))) {
          this.$message({
            type: "error",
            message: "结束时间不能早于开始时间",
          });
          return;
        }
      }
      this.listLoading = true;
      queryaward(params)
        .then((response) => {
          this.list = response.data.list;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    getAllReasons() {
      queryAllReason({}).then((response) => {
        this.reasons = response.data;
      });
    },
    loadDeptData() {
      allDept()
        .then((response) => {
          let deptArray = response.data.list;
          let deptMap = new Map();
          let deptRootArray = [];
          deptArray.forEach((item, i) => {
            item.children = [];
            deptMap.set(item.deptid, item);
          });
          deptArray.forEach((item, i) => {
            let parent = null;
            if (item.parentdeptid != "" && item.parentdeptid != "0") {
              parent = deptMap.get(item.parentdeptid);
              if (parent) parent.children.push(item);
            } else {
              deptRootArray.push(item);
            }
          });
          this.deptData = {
            tree: deptRootArray,
            map: deptMap,
          };
        })
        .catch((err) => {});
    },
    handleFilter() {
      this.getList();
    },
    resetFilter() {
      this.query = {
        pageNum: 1,
        pageSize: this.query.pageSize,
        startTime: null,
        endTime: null,
      };
      this.getList();
    },
    showInfo(award) {
      queryDetail({ awardid: award.awardId }).then((res) => {
        if (res.code == 200) {
          this.infoDlg = {
            visible: true,
            award,
            contentmedialist: res.data,
          };
        }
      });
    },
    exportExcel() {
      const { startTime, endTime } = this.query;
      const params = {
        ...this.query,
        startTime: startTime
          ? startTime instanceof moment
            ? startTime.format("YYYY-MM-DD HH:mm:sss")
            : moment(startTime).format("YYYY-MM-DD HH:mm:ss")
          : null,
        endTime: endTime
          ? endTime instanceof moment
            ? endTime
                .add(23, "hours")
                .add(59, "minutes")
                .add(59, "seconds")
                .format("YYYY-MM-DD HH:mm:ss")
            : moment(endTime)
                .add(23, "hours")
                .add(59, "minutes")
                .add(59, "seconds")
                .format("YYYY-MM-DD HH:mm:ss")
          : null,
      };
      if (params.startTime && params.endTime) {
        if (moment(params.endTime).isBefore(moment(params.startTime))) {
          this.$message({
            type: "error",
            message: "结束时间不能早于开始时间",
          });
          return;
        }
      }
      download({
        method: "post",
        url: "/backend/point/award/award/export",
        responseType: "blob", // 设置响应类型为blob，以便下载文件
        fileName: "点赞明细-导出.xlsx",
        data: params,
      });
    },
    openVideoDlg(url) {
      this.videoDlg.url = url;
      this.videoDlg.visible = true;
    },
    onVideoDlgClose() {
      this.videoDlg.url = "";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //批量撤销
    showBatchRevokeDlg() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择需要撤销的赞币",
          type: "error",
        });
        return;
      }
      this.batchRevokeDlg.visible = true;
    },
    onBatchRevokeDlgClose() {
      this.batchRevokeDlg.visible = false;
      this.batchRevokeDlg.reason = "点赞行为描述不合规。";
    },
    batchRevoke() {
      if (!this.batchRevokeDlg.reason) {
        this.$message({
          message: "撤销理由不能为空",
          type: "error",
        });
        return;
      }
      this.revokeLoading = true;
      const awardIdList = this.multipleSelection.map((item) => {
        return item.id;
      });
      requestRevoke({ awardIdList, revokeReason: this.batchRevokeDlg.reason })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "撤销成功",
              type: "success",
            });
            this.batchRevokeDlg.visible = false;
            this.getList();
          }
        })
        .finally(() => {
          this.revokeLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.filter-item {
  margin-right: 10px;
}
/deep/ .el-dialog__body {
  padding: 20px 20px 30px 20px;
}
.dlg_content {
  display: flex;
  flex-direction: column;
}
.dlg_content .top {
  display: flex;
  flex-direction: row;
}
.dlg_content .top .top_item {
  width: 400px;
  display: flex;
  flex-direction: column;
}
.dlg_content .top .item {
  height: 40px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 14px;
}
.dlg_content .top .item .name {
  width: 100px;
  text-align: right;
}
.dlg_content .top .item .val {
  width: 300px;
  text-align: left;
}
.dlg_content .middle {
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 14px;
}
.dlg_content .middle .name {
  width: 100px;
  text-align: right;
}
.dlg_content .bottom {
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 26px;
}
.dlg_content .bottom .name {
  width: 100px;
  text-align: right;
}
.play_btn_inline {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 64px;
  color: red;
  width: 100%;
  height: 100%;
  line-height: 128px;
  text-align: center;
}
.play_btn_inline2 {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 64px;
  color: white;
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
<style>
.dlg_video {
  background: none;
  border-radius: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dlg_video .el-dialog__header {
  display: none;
}
.dlg_video .el-dialog__body {
  padding: 0;
}
.span-ellipsis {
  width: 180px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-ellipsis {
  max-width: 500px;
}
</style>
