import request2 from '@/utils/request2'

export function getQuotaList(params) {
  return request2({
    url: '/point/quota/page',
    method: 'post',
    data: params
  })
}

export function saveQuota(params) {
  return request2({
    url: '/point/quota/allocation',
    method: 'post',
    data: params
  })
}

export function getQuotaManagementList(params) {
  return request2({
    url: '/pool/page-pool',
    method: 'post',
    data: params
  })
}

export function exportQuotaManagementList(params) {
  return request2({
    url: '/pool/page-pool/export',
    method: 'post',
    data: params
  })
}


export function getQuotaManagementChildList(params) {
  return request2({
    url: '/pool/page-employee',
    method: 'post',
    data: params
  })
}


export function initQuotaManagement(params) {
  return request2({
    url: '/pool/refresh',
    method: 'post',
    data: params
  })
}

export function getQuotaInfo() {
  return request2({
    url: '/point/quota/pool-info',
    method: 'post',
    data: {}
  })
}


