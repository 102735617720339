<template>
  <el-form-item :label="laeblName" :prop="keyStr" :rules="inputRules">
    <el-input
      v-model="localData"
      style="width: 400px"
      :maxlength="maxlength"
      @input="updateData"
      show-word-limit
      :disabled="disabled"
    />
  </el-form-item>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    laeblName: {
      type: String,
      default: "",
    },
    keyStr: {
      type: String,
      default: "",
    },
    inputData: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 20,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled:{
      type: Boolean,
      default: false, 
    }
  },
  watch: {
    inputData(newVal) {
      this.localData = newVal;
    },
  },
  data() {
    return {
      localData: this.inputData,
      inputRules: [
        {
          required: this.required,
          message: `请输入${this.laeblName}`,
          trigger: "blur",
          trim: true,
        },
      ],
    };
  },
  computed: {},
  methods: {
    updateData(newVal) {
      this.localData = newVal.trim();
      this.$emit("updateInputData", {
        keyStr: this.keyStr,
        value: newVal.trim(),
      }); // 当输入框的值改变时，触发自定义事件来更新props中的data
    },
  },
};
</script>

<style scoped>

::el-input__inner{
  padding-right: 100px;
}
</style>
