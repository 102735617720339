<template>
  <RoleDetails v-if="hasAuth"></RoleDetails>
  <NoAuthPage v-else></NoAuthPage>
</template>

<script>
import RoleDetails from "./components/RoleDetails.vue";
import NoAuthPage from "@/components/AuthCheck/NoAuthPage";
import { checkAuth } from "@/utils/auth";
export default {
  name: "RoleDetailsForm",
  components: { RoleDetails, NoAuthPage },
  data() {
    return {
      hasAuth: false,
    };
  },
  created() {
    const { path } = this.$route;
    if (path) {
      if (path.endsWith("edit")) {
        this.hasAuth = checkAuth("gtmsh-point-server.role.update");
      } else if (path.endsWith("view")) {
        // this.hasAuth = checkAuth("gtmsh-point-server.role.get");
        this.hasAuth = true;
      } else if (path.endsWith("add")) {
        this.hasAuth = checkAuth("gtmsh-point-server.role.save");
      }
    }
  },
};
</script>
